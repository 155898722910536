import { useEffect, useState, useRef } from "react";

import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

import { TiPlus, TiDownload } from 'react-icons/ti';
import Alert from 'react-bootstrap/Alert';

import { Amplify, API } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

import LeftSideBar from "../common/LeftSideBar";
import DataTable from '../common/DataTable';
import EnvDetails from '../../env-details.json';

import { useNavigate, useSearchParams } from 'react-router-dom';

import '../../scss/sub.scss';

import awsExports from '../../aws-exports';
import * as Utils from "../../common/utils";
import * as RBACUtils from '../../common/RBACUtils';
Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true
}

const ENV = Utils.getCurrentEnv(), STAGE = Utils.getCurrentStage();

const ListSub = ({ signOut, user }) => {

  //#region states

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isInitDataLoaded = useRef(false);
  const prevTableParams = useRef({});
  const initData = {
    offers: [],
    bundles: [],
    apps: [],
    countrycodes: []
  };
  const [subscriptions, setSubscriptions] = useState({ results: [], count: 0 });
  const [data, setData] = useState(initData);
  const [subLoadStatus, setSubLoadStatus] = useState({ status: false, message: '', retryCount: 1 });
  const [searchFilter, setSearchFilter] = useState({ hide_ppu: true });
  const [subAlertMessage, setSubAlertMessage] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);

  //#endregion

  //#region local static data

  const INIT_FILTER = Utils.urlParamsToObj(searchParams);
  const offerMap = new Map();
  const bundleOfferMap = new Map();
  const offerBundleMap = new Map();
  data['offers'].forEach(o => {
    offerMap.set(o.offerId, o);
    o.offerBundles.forEach(b => {
      if (bundleOfferMap.get(b.bundleId) === undefined) {
        bundleOfferMap.set(b.bundleId, o.offerId);
      }
    });
  });
  for (var k = 0; k < data['offers'].length; k++) {
    const tArr = [];
    for (var j = 0; j < data['offers'][k].offerBundles.length; j++) {
      tArr.push(data['offers'][k].offerBundles[j].bundleId);
    }
    offerBundleMap.set(data['offers'][k].offerId, tArr);
  }

  const bundleMap = new Map();
  const bundleServiceTypeMap = new Map();
  const applicationMap = new Map();
  const applicationTypeToId = new Map();
  const capabilityMap = new Map();
  data['bundles'].forEach(b => {
    b.bundleCapabilities.forEach(c => {
      if (applicationMap.get(c.applicationId) === undefined) {
        applicationMap.set(c.applicationId, c);
      }
      if (applicationTypeToId.get(c.applicationName) === undefined) {
        applicationTypeToId.set(c.applicationName, c.applicationId);
      }
      if (capabilityMap.get(c.capabilityId) === undefined) {
        capabilityMap.set(c.capabilityId, c);
      }
    });
  });

  data['bundles'].forEach(bundle => {
    bundleMap.set(bundle.bundleId, bundle);
    bundleServiceTypeMap.set(bundle.bundleServiceType, bundle);
  });

  const pcApplicationMap = new Map();
  data['apps'].forEach((a) => {
    if (pcApplicationMap.get(a.applicationType) === undefined) {
      pcApplicationMap.set(a.applicationType, a);
    }
  });

  const countryCodeMap = new Map();
  data['countrycodes'].forEach((c) => {
    if (countryCodeMap.get(c.code) === undefined) {
      countryCodeMap.set(c.code, c);
    }
  });

  //#endregion

  //#region functions

  const invokeFetchSubscriptions = (page = 0, pageSize = 5, sortBy = [{ id: 'updated', desc: true }], filter = INIT_FILTER, forceUpdate = false) => {
    fetchSubscriptions(page, pageSize, sortBy, filter, forceUpdate, false)
      .then(sub => setSubscriptions(sub))
      .catch(err => { });
  };

  const handleSubscriptionAction = async (action, subId, payload) => {
    setSubLoadStatus({ status: true, retryCount: -1, message: `secondary::${action === 'cancel' ? 'Cancelling' : action === 'resume' ? 'Resuming' : 'Suspending'} subscription ${subId}` });
    let params = {
      'stage': STAGE,
      'region': user.pool.userPoolId.split('_')[0],
      'payload': payload,
      'action': action,
      'id': subId
    };

    // trigger subscription using SSP backend subscription API
    await API.post("SspBackendApi", "/subscription", {
      body: params,
      headers: {
        "Content-Type": "application/json"
      }
    }).then((resp) => {
      let statusCode = JSON.parse(resp.output.result).statusCode;
      if (statusCode !== 200) {
        let m = JSON.parse(JSON.parse(resp.output.result).body).message.split('(')[1].split(')')[0];
        setSubLoadStatus({ status: true, retryCount: -1, message: `danger::${m}` });
        console.error(`Subscription ${action} action failed. ${m}.`)
      } else {
        let m = JSON.parse(JSON.parse(resp.output.result).body);
        setSubLoadStatus({ status: true, retryCount: -1, message: `success::Subscription ${subId} ${action === 'cancel' ? 'Cancelled' : action === 'resume' ? 'Resumed' : 'Suspended'} successfully.` });
        console.log(`Subscription ${subId} ${action === 'cancel' ? 'Cancelled' : action === 'resume' ? 'Resumed' : 'Suspended'} successfully. ${JSON.stringify(m)}`)
      }
    }).catch((error) => {
      setSubLoadStatus({ status: true, retryCount: -1, message: `danger::Error occurred while ${subId} ${action === 'cancel' ? 'Cancelling' : action = 'resume' ? 'Resuming' : 'Suspending'} subscription ${subId}.` });
    }).finally(() => {
      setTimeout(() => {
        setSubLoadStatus({ status: false, retryCount: 1, message: `` });
      }, 2000);
    });

  };
  useEffect(() => {
    setTotalRecords(subscriptions.count);
  }, [subscriptions]);

  const parseTotalCount = (data) => {
    if (!data) return null;
    if (data.length > 0 && data[0]['total_count']) return parseInt(data[0]['total_count']);
    return null;
  };

  const sanitizeFilter = (filter) => {
    if ('internal' in filter) delete filter['internal'];
    Object.keys(filter).forEach(k => {
      if (['state', 'app', 'system', 'service'].indexOf(k) !== -1 && !filter[k]) delete filter[k];
      if (['created', 'updated', 'start', 'end'].indexOf(k) !== -1 && filter[k].length === 0) delete filter[k];
    });
    return typeof filter === 'object' && Object.keys(filter).length > 0 ? JSON.stringify(filter) : {};
  };

  const fetchSubscriptions = (page = 0, pageSize = 5, sortBy = [{ id: 'updated', desc: true }], filter = {}, forceUpdate = false, isInit = false) => {
    setSearchFilter(filter);
    return new Promise(async (resolve, reject) => {
      let sanitized_filter = sanitizeFilter(filter);
      const curTableParam = {
        page: page,
        pageSize: pageSize,
        sortBy: sortBy,
        filter: sanitized_filter
      };
      if (Utils.jsonEquals(prevTableParams.current, curTableParam) && !forceUpdate) return reject({ results: [], count: 0 });

      if (!isInit) setSubLoadStatus({ status: true, retryCount: 1, message: 'secondary::Fetching subscriptions...' });

      prevTableParams.current = { ...curTableParam };
      let retries = 1, offset = page * pageSize;
      while (retries <= Utils.RETRY_COUNT) {
        try {
          let params = {
            response: true,
            queryStringParameters: {
              stage: STAGE,
              region: user.pool.userPoolId.split('_')[0],
              offset: offset,
              limit: pageSize,
              sortby: sortBy.length > 0 && sortBy[0].id ? sortBy[0].id : '',
              dir: sortBy.length > 0 ? (sortBy[0].desc === true ? 'desc' : 'asc') : '',
              filter: sanitized_filter
            }
          };
          const response = await API.get("SspBackendApi", "/all-subscriptions", params);
          
          if (response && response.data) {
            let count = parseTotalCount(response.data) || 0;
            if (!isInit) setSubLoadStatus({ ...subLoadStatus, status: false, message: '', retryCount: 0 });
            return resolve({ results: response.data, count: count });
          } else {
            if (!isInit) setSubLoadStatus({ ...subLoadStatus, status: true, message: 'danger::Error occured while fetching data.', retryCount: retries });
          }
          console.log("about to reject");
          return reject({ results: [], count: 0 });
        } catch (error) {
          console.log("e");
          let err = JSON.parse(JSON.stringify(error));
          if (parseInt(err.status) === 504) {
            console.log('API request "GET /all-subscriptions" timed-out. Retrying...', JSON.stringify(error));
            retries++;
            if (retries <= Utils.RETRY_COUNT) {
              if (!isInit) setSubLoadStatus({ ...subLoadStatus, status: true, message: 'danger::Request timed-out, retrying...', retryCount: retries });
              await new Promise((resolve) => setTimeout(resolve, 1000));
            } else {
              if (!isInit) setSubLoadStatus({ ...subLoadStatus, status: true, message: 'danger::Retry limit reached, please reload the page.', retryCount: retries });
              return reject({ results: [], count: 0 });
            }
          } else {
            console.error('API request "GET /all-subscriptions" failed.', JSON.stringify(error));
            if (!isInit) setSubLoadStatus({ ...subLoadStatus, status: true, message: 'danger::Error occured while fetching data.', retryCount: retries });
            return reject({ results: [], count: 0 });
          }
        }
      }

    });
  };


  //#endregion

  //#region download subscriptions
  const downloadSubscriptions = () => {
    if (subscriptions.count <= Utils.DOWNLOAD_RECORDS_MAX_SIZE) {
      const downloadSub = new Promise(async (resolve, reject) => {
        let sanitized_filter = sanitizeFilter(searchFilter);
        let retries = 1;
        while (retries <= Utils.RETRY_COUNT) {
          try {
            let params = {
              response: true,
              responseType: 'blob',
              timeout: 1000 * 60 * 5,
              queryStringParameters: {
                stage: STAGE,
                region: user.pool.userPoolId.split('_')[0],
                sortby: 'created',
                size: subscriptions.count || 0,
                dir: 'asc',
                filter: sanitized_filter
              }
            };

            setSubLoadStatus({ status: true, retryCount: retries, message: 'secondary::Downloading subscriptions...' })
            const response = await API.get("SspBackendApi", "/download-subscriptions", params);

            if (response && response.data) {
              setSubLoadStatus({ status: false, retryCount: 4, message: '' })
              const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `subscriptions-${new Date().toISOString()}.csv`);
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(url);
              return resolve(true);
            } else {
              return reject({ 'msg': 'Error:: Subscription list is not downloaded. Please try again!' });
            }
          } catch (error) {
            retries++;
            if (retries > Utils.RETRY_COUNT) {
              return reject({ 'msg': 'Having error while downloading subscription list. Please try again!' });
            };
          }
        }
      });
      downloadSub.then().catch(err => {
        setSubAlertMessage(err.msg);
      });
    } else {
      setSubAlertMessage(`Maximum of ${Utils.DOWNLOAD_RECORDS_MAX_SIZE} subscriptions can be downloaded. Please select the proper filter to get specific subscriptions.`);
    }
  };
  //#endregion

  //#region load catalog information 

  const loadOffers = () => {
    return new Promise(async (resolve, reject) => {
      let cachedOffers = Utils.fetchFromSessionStorage(user.username, 'offers', [], true);
      if (cachedOffers.length > 0) {
        return resolve(cachedOffers);
      }
      let params = {
        response: false,
        queryStringParameters: {
          'stage': STAGE,
          'path': 'offers',
          'region': user.pool.userPoolId.split('_')[0]
        }
      };
      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const jsonResponse = JSON.parse(response);
        const rOffers = JSON.parse(jsonResponse.body);
        if (rOffers && rOffers.length > 0) {
          Utils.setInSessionStorage(user.username, 'offers', rOffers, true);
          return resolve(rOffers);
        }
      }).catch((error) => {
        console.error(`Error occured while fetching catalog data.`, JSON.stringify(error));
        reject([]);
      });
    });
  };

  const loadBundles = () => {
    return new Promise(async (resolve, reject) => {
      let cachedBundles = Utils.fetchFromSessionStorage(user.username, 'bundles', [], true);
      if (cachedBundles.length > 0) {
        return resolve(cachedBundles);
      }

      let params = {
        response: false,
        queryStringParameters: {
          'stage': STAGE,
          'path': 'bundles',
          'region': user.pool.userPoolId.split('_')[0]
        }
      };

      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const rBundles = JSON.parse(JSON.parse(response).body);
        if (rBundles && rBundles.length > 0) {
          Utils.setInSessionStorage(user.username, 'bundles', rBundles, true);
          return resolve(rBundles);
        }
      }).catch((error) => {
        console.error(`Error occured while fetching catalog data.`, JSON.stringify(error));
        return reject([]);
      });
    });
  };

  const loadApps = () => {
    return new Promise(async (resolve, reject) => {
      let cachedApps = Utils.fetchFromSessionStorage(user.username, 'apps', [], true);
      if (cachedApps.length > 0) {
        return resolve(cachedApps);
      }
      let params = {
        response: false,
        queryStringParameters: {
          'stage': STAGE,
          'path': 'applications',
          'region': user.pool.userPoolId.split('_')[0]
        }
      };
      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const rApps = JSON.parse(JSON.parse(response).body);
        if (rApps && rApps.length > 0) {
          Utils.setInSessionStorage(user.username, 'apps', rApps, true);
          return resolve(rApps);
        }
      }).catch((error) => {
        console.error(`Error occured while fetching catalog data.`, JSON.stringify(error));
        reject([]);
      });
    });
  };

  const loadCountryCodes = () => {
    return new Promise(async (resolve, reject) => {
      let cachedCountryCodes = Utils.fetchFromSessionStorage(user.username, 'countrycodes', [], true);
      if (cachedCountryCodes && cachedCountryCodes.length > 0) {
        return resolve(cachedCountryCodes);
      }
      let params = {
        queryStringParameters: {
          'stage': STAGE,
          'region': user.pool.userPoolId.split('_')[0]
        }
      };
      await API.get("SspBackendApi", "/countrycodes", params).then((response) => {
        if (response && response.length > 0) {
          Utils.setInSessionStorage(user.username, 'countrycodes', response, true);
          return resolve(response);
        }
      }).catch((error) => {
        console.error('Error occurred while fetching country codes data:', error);
        reject([]);
      });
    });
  };


  const loadInitData = async () => {

    setSubLoadStatus({ status: true, retryCount: 1, message: 'secondary::Fetching subscriptions...' });

    const initDataPromises = [
      loadOffers(),
      loadBundles(),
      loadApps(),
      loadCountryCodes(),
      fetchSubscriptions(0, 5, [{ id: 'updated', desc: true }], INIT_FILTER, false, true)
    ];
    Promise.all(initDataPromises).then(res => {
      if (res[0].length > 0 && res[1].length > 0 && res[2].length > 0 && res[3].length && res[4].results) {
        setData({ ...data, offers: res[0], bundles: res[1], apps: res[2], countrycodes: res[3] });
        isInitDataLoaded.current = true;
        setSubscriptions(res[4]);
        setSubLoadStatus({ ...subLoadStatus, status: false, message: '', retryCount: 0 });
      }
    }).catch(error => {
      console.error('An error occurred while fetching initial data: ', JSON.stringify(error));
    });

  };


  useEffect(() => {
    const roleAttribute = user['attributes']['custom:role'];
    let roleValue = roleAttribute ? JSON.parse(roleAttribute) : RBACUtils.getDefaultUserRole();
    Utils.setInSessionStorage(user.username, 'userRole', roleValue);
    if (RBACUtils.hasSubscriptionAccess(user.username, 'NONE')) {
      navigate("/user/profile");
    } else {
      if (Object.keys(data).filter(k => data[k].length === 0).length === 0 && isInitDataLoaded.current) {
        invokeFetchSubscriptions();
      } else {
        loadInitData();
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  //#endregion

  return (
    <Container fluid className="p-2 mb-4" >
      <Row className='m-0'>
        {/* Left sidebar */}
        <Col md={2} className='l-nav'>
          <LeftSideBar active='ls' username={user.username} />
        </Col>

        {/* Content */}
        <Col md={10}>
          <Container fluid data-bs-spy="scroll" data-bs-target='#navbar' data-bs-offset="0" className="scrollspy-example" tabIndex="0">

            {/* top heading bar */}
            <Row className='p-0'>
              <Col className='title-wrap d-flex align-items-center justify-content-between p-0'>
                <div className='d-flex align-items-center justify-content-start title-text'>
                  <div>Dashboard</div>
                </div>
                <div className='d-flex align-items-center justify-content-end title-settings'>
                  <div className='me-2'>{user.attributes.email}</div>
                  <Dropdown size='sm' as={ButtonGroup} className='me-2'>
                    <Button variant="outline-secondary" title={`Environment: ${ENV}, Stage: ${STAGE}`}>{EnvDetails.filter(e => e.envName === ENV)[0].dispName}</Button>
                    <Dropdown.Toggle split variant="outline-secondary" id="dropdown-split-basic" />
                    <Dropdown.Menu variant='outline-secondary' align='end'>
                      {
                        EnvDetails.map(e =>
                          Utils.includeEnvInDropdown(e.envName) &&
                          <Dropdown.Item title={`Environment: ${e.envName}, Stage: ${e.stageName}`} href={e.url}>{e.dispName}</Dropdown.Item>
                        )
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button size="sm" variant="outline-secondary" onClick={signOut}>Signout</Button>
                </div>
              </Col>
            </Row>

            <Row className='p-0'>
              <Col className='p-0'>
                {
                  subAlertMessage !== '' &&
                  <Alert key='sub-download-alert' variant='danger' onClose={() => setSubAlertMessage('')} dismissible>{subAlertMessage}</Alert>
                }
                <div className='d-flex align-items-center justify-content-between my-2'>
                  <div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <div><h4 className='mb-1'>Subscriptions dashboard</h4></div>
                      {
                        subLoadStatus['status'] &&
                        <>
                          {
                            (
                              (parseInt(subLoadStatus['retryCount']) === -1 && subLoadStatus['message'].split('::')[0] === 'secondary') ||
                              (parseInt(subLoadStatus['retryCount']) !== -1 && parseInt(subLoadStatus['retryCount']) <= Utils.RETRY_COUNT)
                            ) &&
                            <div className='ms-2' style={{ fontSize: '14px' }}>
                              <Spinner size='sm' variant='secondary' animation="border" role="status" />
                            </div>
                          }
                          <div className={`fw-500 ms-2 text-${subLoadStatus['message'].split('::')[0]}`} style={{ fontSize: '14px' }}>{subLoadStatus['message'].split('::').length > 0 ? subLoadStatus['message'].split('::')[1] : subLoadStatus['message']}</div>
                          {
                            parseInt(subLoadStatus['retryCount']) <= Utils.RETRY_COUNT &&
                            <div className={`fw-500 ms-2 text-${subLoadStatus['message'].split('::')[0]}`}>
                              {
                                parseInt(subLoadStatus['retryCount']) > 0 &&
                                <i><small>(Attempt {parseInt(subLoadStatus['retryCount'])} of 3)</small></i>
                              }
                            </div>
                          }
                        </>
                      }
                    </div>
                    <small className='text-muted'>A dashboard to manage subscriptions in the environment</small>
                  </div>
                  <div className='d-flex align-items-center justify-content-end gap-2'>
                    {
                      RBACUtils.hasSubscriptionAccess(user.username, 'WRITE') &&
                      <div className='create-sub-btn d-flex align-items-center justify-content-start' title='Create new subscription' onClick={() => navigate('/sub/create')}>
                        <div className='create-sub-btn-icon'><TiPlus /></div>
                        <div className='text'>Create subscription</div>
                      </div>
                    }
                    
                    {subscriptions?.count > 0 && (RBACUtils.hasSubscriptionAccess(user.username, 'WRITE') || RBACUtils.hasSubscriptionAccess(user.username,'READ')) ? (
                      <div className='btn btn-secondary btn-sm d-flex align-items-center justify-content-start rounded-3' title='Download subscriptions data' onClick={() => downloadSubscriptions()}>
                        <div className='download-sub-btn-icon'><TiDownload /></div>
                      </div>) : ""
                    }
                  </div>
                </div>
                <div>
                  {
                    isInitDataLoaded.current &&
                    <DataTable username={user.username} data={subscriptions} onTableUpdated={invokeFetchSubscriptions} isLoading={subLoadStatus['status']} onSubscriptionAction={handleSubscriptionAction} region={user.pool.userPoolId.split('_')[0]} useremail={user.attributes.email} totalRecords={totalRecords} />
                  }
                </div>
              </Col>
            </Row>

          </Container>
        </Col>
      </Row>
    </Container>
  );

};

export default withAuthenticator(ListSub, withAuthenticatorOptions);