import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";
import { Auth } from 'aws-amplify';
import "bootstrap/dist/css/bootstrap.min.css";
import { isSsoEnabledForEnv, isFinOpsEnv } from "../common/utils";

import "bootstrap/dist/css/bootstrap.min.css";
import CreateSub from "./sub/CreateSub";
import ViewSub from "./sub/ViewSub";
import EditSub from "./sub/EditSub";
import ListSub from "./sub/ListSub";
import ListOrder from "./order/ListOrder";
import ProductCatalog from "./pc/ProductCatalog";
import FieldCustomization from "./user/FieldCustomization";
import UserManagement from "./user/UserManagement";
import UserProfile from "./user/UserProfile";
import Workflows from "./bpmn/Workflows";
import LiveWorkFlows from "./liveWorkflows/LiveWorkFlows";
import FederatedLogin from "./FederatedLogin";
import UsageData from "./usageData/usageData";
import Environments from "./finops/Environments";
import Products from "./finops/Products";
import CreateEnv from "./finops/CreateEnv";
import Redirect from "./Redirect";


const queryClient = new QueryClient();

const App = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const isSsoEnabled = isSsoEnabledForEnv();

  const fetchUser = useCallback(async () => {
    await Auth.currentAuthenticatedUser()
    setIsAuthenticated(true);
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <>
      {isSsoEnabled && !isAuthenticated ? (
        <FederatedLogin />
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element={<UserProfile />} />
              <Route path="sub/create" element={<CreateSub />} />
              <Route path="sub/view/" element={<ViewSub />} />
              <Route
                path="sub/list/"
                element={
                  <QueryClientProvider client={queryClient}>
                    {" "}
                    <ListSub />{" "}
                  </QueryClientProvider>
                }
              />
              <Route path="sub/view/:id" element={<ViewSub />} />
              <Route path="sub/edit/" element={<EditSub />} />
              <Route path="sub/edit/:id" element={<EditSub />} />
              <Route
                path="order/list/"
                element={
                  <QueryClientProvider client={queryClient}>
                    {" "}
                    <ListOrder />{" "}
                  </QueryClientProvider>
                }
              />
              <Route path="pc" element={<ProductCatalog />} />
              <Route path="user/fc" element={<FieldCustomization />} />
              <Route path="user/manage" element={<UserManagement />} />
              <Route path="user/profile" element={<UserProfile />} />
              <Route path="bpmn" element={<Workflows />} />
              <Route path="liveworkflow" element={<LiveWorkFlows />} />
              <Route path="usagedata" element={<UsageData />} />
              <Route path="redirect" element={<Redirect />} />
              {isFinOpsEnv() &&
                <>
                  <Route path="environments" element={<Environments />} />
                  <Route path="products" element={<Products />} />
                  <Route path="environments/create" element={<CreateEnv />} />
                </>
              }
            </Route>
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
};
export default App;
