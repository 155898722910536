import Spinner from "react-bootstrap/Spinner";
import { BsFillPatchPlusFill, BsFillPatchMinusFill } from "react-icons/bs";
import "./btn.css";
import { Button } from "react-bootstrap";
const GenerateReportBtn = (props) => {
  const { requestStatus, onClick, disabled } = props;
  return (
    <Button
      disabled={disabled}
      className={`generate-button generate-button-${requestStatus.toLowerCase()}`}
      onClick={() => onClick()}
      size="Large"
    >
      {requestStatus === "INITIAL" && <>Submit</>}
      {requestStatus === "INFLIGHT" && <Spinner />}
      {requestStatus === "COMPLETED" && <>Submit</>}
      {requestStatus === "FAILED" && <>Submit</>}
      {requestStatus === "UNAUTHORIZED" && <>Submit</>}
    </Button>
  );
};
const ExpandBtn = ({ expanded, onClick ,color}) => {
  
  return (
    <Button onClick={onClick} className='expand-btn' style={{ cursor: "pointer", color: color, fontSize: '22px', marginTop: '-4px' }}>
      {expanded ? <BsFillPatchMinusFill /> : <BsFillPatchPlusFill />}
    </Button>
  );
};
const RecentActivityDownloadBtn = ({ status, onClick }) => {
  return(
    <div>
      <Button onClick={onClick} className={"report-download-btn-"+status.toLowerCase()} size="sm">
        {status}
      </Button>
    </div>

  )
};

const ReportDownloadBtn = ({ status, onClick, style }) => {
  if (!status)
    return (
      <Button
        style={style}
        id="downloadBtn"
        title="status not available"
        className="report-download-btn-not-available"
      >
        Not Available
      </Button>
    );

  const formattedStatus = status.replace(/_/g, ' ').toLowerCase().replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
  const classNameStr = `report-download-btn-${status.toLowerCase()}`;
  return (
    <Button
      className={classNameStr}
      size="sm"
      disabled={status !== "COMPLETED"}
      onClick={onClick}
      style={style}
    >
      {formattedStatus}
    </Button>
  );
};

export { GenerateReportBtn, ExpandBtn, ReportDownloadBtn,RecentActivityDownloadBtn };
