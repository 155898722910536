import { useState } from "react";
import { Container, Table, Row, Col } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

const columns = [
  { id: "idx", label: "#", minWidth: 25 },
  { id: "flow_name", label: "Flow name", minWidth: 170 },
  { id: "flow_external_id", label: "Subscription id", minWidth: 170 },
  { id: "flow_type", label: "Flow type", minWidth: 100 },
  { id: "flow_state", label: "State", minWidth: 100 },
  { id: "start_date", label: "Start date", minWidth: 170 },
  { id: "end_date", label: "End date", minWidth: 170 },
];

const STATUS_COLOR_DICT = {
  COMPLETED: { stroke: "#005706" },
  FAILED: { stroke: "#de2134" },
  IN_PROGRESS: { stroke: "#e87b0e" },
  PENDING: { stroke: "#e87b0e" },
};

const WorkflowTable = (props) => {
  const { rows, setDisplayRow, showSubscription } = props;
  const [order] = useState("asc");
  const [orderBy] = useState("start_date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageOptions, setPageOptions] = useState([1]);
  const pageSelectionOptions = [5, 10, 20];

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  const handleChangeRowsPerPage = (rowPerPageInput) => {
    setRowsPerPage(rowPerPageInput);
    setPage(0);

    const optionsPage = Math.ceil(rows.length / rowPerPageInput);
    const optionsArray = Array.from({ length: optionsPage }, (_, i) => i);
    setPageOptions(optionsArray);
  };

  let updatedColumns = columns;
  if (!showSubscription) {
    updatedColumns = columns.filter(
      (column) => column.id !== "flow_external_id"
    );
  }

  const dispCell = (val) => {
    return val ? val : "-";
  };

  const buildFlowStatus = (status) => {
    if (!status || !STATUS_COLOR_DICT[status.toUpperCase()])
      return (
        <span
          className="wf-status"
          title="status not available"
          style={{ backgroundColor: "#666" }}
        >
          -
        </span>
      );
    return (
      <span
        className="wf-status"
        style={{
          backgroundColor: STATUS_COLOR_DICT[status.toUpperCase()]["stroke"],
        }}
      >
        {status}
      </span>
    );
  };

  return (
    <Container fluid className="p-2 mb-4">
      <Row>
        <Col>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div>
              <div className="fw-500">
                <h4 className="mb-0">Workflow Table</h4>
              </div>
              <div className="text-muted">
                <small>
                  List of workflows associated with selected resource
                </small>
              </div>
            </div>
          </div>
          <Table borderless className="workflow-table">
            <thead>
              <tr>
                {updatedColumns.map((column) => (
                  <th key={column.id} className="text-start">
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.length > 0 ? (
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, _idx) => (
                    <tr
                      key={row.table_info_sorter}
                      onClick={() => setDisplayRow(row.flow_id)}
                    >
                      <td>{_idx + 1}</td>
                      <td>{dispCell(row.flow_name)}</td>
                      {showSubscription && <td>{row.flow_external_id}</td>}
                      <td>{dispCell(row.flow_type)}</td>
                      <td>{buildFlowStatus(row.flow_state)}</td>
                      <td>{dispCell(row.start_date)}</td>
                      <td>{dispCell(row.end_date)}</td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={columns.length} className="text-center">
                    No workflows to display, please provide valid Subscription
                    Id/Flow Id/External2 Id.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div className="d-flex align-items-center justify-content-start">
          <ButtonGroup size="sm" className="me-2">
            <Button
              variant="secondary"
              onClick={(_) => setPage(0)}
              disabled={pageOptions.length === 1}
            >
              First
            </Button>
            <Button
              variant="secondary"
              onClick={(_) => setPage(page - 1)}
              disabled={page === 0}
            >
              Previous
            </Button>
            <Button
              variant="secondary"
              onClick={(_) => setPage(page + 1)}
              disabled={page === pageOptions.length - 1}
            >
              Next
            </Button>
            <Button
              variant="secondary"
              onClick={(_) => setPage(pageOptions.length - 1)}
              disabled={pageOptions.length === 1}
            >
              Last
            </Button>
          </ButtonGroup>
          <div className="me-2">
            Page{" "}
            <strong>
              {page + 1} of {pageOptions.length}
            </strong>
          </div>
        </div>
        <div>
          <Dropdown className="pagesize-dropdown">
            <Dropdown.Toggle
              variant="secondary"
              id="page-size-dropdown"
              size="sm"
            >
              Show {rowsPerPage}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {pageSelectionOptions.map((pageSizeOption) => (
                <Dropdown.Item
                  key={pageSizeOption}
                  // active={pageSizeOption === pageSize}
                  onClick={() => handleChangeRowsPerPage(pageSizeOption)}
                >
                  Show {pageSizeOption}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Container>
  );
};

export default WorkflowTable;
