import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import { format } from 'date-fns';
import { MdCheckCircle, MdRadioButtonUnchecked, MdRadioButtonChecked } from 'react-icons/md';
import { IoIosAddCircleOutline } from "react-icons/io";
import { Button, Modal } from 'react-bootstrap';
import '../../scss/milestone-table.scss';

const MilestoneTable = ({ milestones, milestones_state }) => {
  const [show, setShow] = useState(false);
  const [currentLabels, setCurrentLabels] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = (labels) => {
    setCurrentLabels(labels);
    setShow(true);
  };

  const parsedMilestones = JSON.parse(milestones || '[]');
  const parsedMilestonesState = milestones_state ? milestones_state.split(',') : [];

  // Define the order for milestone types
  const milestoneOrder = ['Activation', 'SNOW', 'Mavenlink', 'Gainsight'];
  const sortedMilestones = parsedMilestones.sort((a, b) => {
    return milestoneOrder.indexOf(a.milestone_type) - milestoneOrder.indexOf(b.milestone_type);
  });

  const formatDate = (dateStr) => {
    return dateStr ? format(new Date(dateStr), 'yyyy-MM-dd') : '--';
  };

  const renderMilestoneState = (states) => {
    const activationStates = [];
    const snowStates = [];
    const mavenlinkStates = [];
    const gainsightStates = [];

    states.forEach((state, index) => {
      const [type, value] = state.split(':');
      const stateValue = value.replace(/_/g, ' ');

      if (type === 'CBS') {
        activationStates.push(
          <div key={index}>
            <div>{`${stateValue}`}</div>
          </div>
        );
      } else if (type === 'BYOC_Ticket' || type === 'Verint_Ticket' || type === 'BVR_Ticket' || type === 'AEC_Ticket') {
        snowStates.push(
          <div className="sub-label" key={index}>
            <div>{`${type.replace('_Ticket', '')}: ${stateValue}`}</div>
          </div>
        );
      } else if (type === 'Cloud_Delivery') {
        mavenlinkStates.push(
          <div key={index}>
            <div>{`${stateValue}`}</div>
          </div>
        );
      } else if (type === 'Gainsight') {
        gainsightStates.push(
          <div key={index}>
            <div>{`${stateValue}`}</div>
          </div>
        );
      }
    });

    return (
      <div className="milestone-states-container">
        {activationStates.length > 0 && (
          <Badge pill className="me-2 activation">
            <div><strong>CBSP:</strong></div>
            {activationStates}
          </Badge>
        )}
        {snowStates.length > 0 && (
          <Badge pill className="me-2 snow">
            <div><strong>Cloudops Configure:</strong></div>
            {snowStates}
          </Badge>
        )}
        {mavenlinkStates.length > 0 && (
          <Badge pill className="me-2 mavenlink">
            <div><strong>Cloud Delivery:</strong></div>
            {mavenlinkStates}
          </Badge>
        )}
        {gainsightStates.length > 0 && (
          <Badge pill className="me-2 gainsight">
            <div><strong>Customer Success:</strong></div>
            {gainsightStates}
          </Badge>
        )}
      </div>
    );
  };

  const renderTableRows = (milestones) => {
    return milestones.map((milestone, index) => (
      <tr key={index} className={`category-${milestone.milestone_type.toLowerCase()}`}>
        <td className="milestone-name" style={{ fontWeight: 'bold' }} title={milestone.summary}>
          {milestone.state === 'Resolved' ? (
            <MdCheckCircle className="me-2 text-success" title="Resolved" />
          ) : milestone.state === 'In Progress' ? (
            <MdRadioButtonChecked className="me-2 text-primary" title="In Progress" />
          ) : (
            <MdRadioButtonUnchecked className="me-2 text-warning" title="Open" />
          )}
          {milestone.summary}
        </td>
        <td className="time-column">{formatDate(milestone.created_date)}</td>
        <td className="time-column">{formatDate(milestone.due_date)}</td>
        <td className="time-column">{formatDate(milestone.timestamp)}</td>
        <td className="description-column" title={milestone.description}>{milestone.description}</td>
        <td className="remaining-column"
          style={{
            color: milestone.remaining_days.startsWith('Overdue') ? '#d35c58' : 'black',
            fontWeight: milestone.remaining_days.startsWith('Overdue') ? 'bold' : 'normal'
          }}>{milestone.remaining_days}
        </td>
        <td className="labels-column">
          <Button variant="link" onClick={() => handleShow(milestone.labels)} style={{ padding: '0' }}>
            <IoIosAddCircleOutline />
          </Button>
        </td>
      </tr>
    ));
  };

  const renderModalLabels = () => {
    return currentLabels.replace(/[\[\]"]+/g, '').split(',').map((label, index) => (
      <Badge key={index} className="badge-label" bg="secondary">
        {label.trim()}
      </Badge>
    ));
  };

  return (
    <div>
      {renderMilestoneState(parsedMilestonesState)}
      <Table striped bordered hover className="milestone-table">
        <thead className="thead-dark">
          <tr>
            <th className="milestone-name-column">Milestone Name</th>
            <th className="time-column">Created On</th>
            <th className="time-column">Due Date</th>
            <th className="time-column">Updated</th>
            <th>Description</th>
            <th className="remaining-column">Remaining</th>
            <th className="labels-column"></th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows(sortedMilestones)}
        </tbody>
      </Table>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Labels</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderModalLabels()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MilestoneTable;
