import { useState, useEffect } from "react";

import Container from 'react-bootstrap/Container'
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Multiselect from 'multiselect-react-dropdown';

import 'json-diff-kit/dist/viewer.css';

import { Amplify, API, Auth } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import * as Utils from '../../common/utils';

import LeftSideBar from "../common/LeftSideBar";

import EnvDetails from '../../env-details.json';
import * as RBACUtils from '../../common/RBACUtils';

import '../../scss/sub.scss';
import '../../scss/user_profile.scss';
import '../../scss/user_role_management.scss'

import awsExports from '../../aws-exports';

Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true
}

const curEnv = Utils.getCurrentEnv();
const curStage = Utils.getCurrentStage();

const UserProfile = ({ signOut, user }) => {

  const userDataFields = [
    ['Username', 'User ID (Sub)', false],
    ['UserStatus', 'Confirmation status', false],
    ['UserCreateDate', 'Created time', false],
    ['UserLastModifiedDate', 'Last updated time', false],
    ['Enabled', 'Account status', false],
    ['email', 'Email', true],
    ['email_verified', 'Email verified', true],
    ['custom:role', 'User role', true],
    ['RequestButton', 'Request permissions', true]

  ];
  const [userData, setUserData] = useState({});
  const [showRoleModal, setShowRoleModal] = useState(false);

  const userRole = Object.keys(userData).length > 0 && userData['UserAttributes'].filter(a => a.Name === 'custom:role').length > 0 ?
    JSON.parse(userData['UserAttributes'].filter(a => a.Name === 'custom:role')[0]['Value']) :
    RBACUtils.getDefaultUserRole();

  const offers = Utils.fetchFromSessionStorage(user.username, 'offers', [], true);
  const bundles = Utils.fetchFromSessionStorage(user.username, 'bundles', [], true);

  const bundleMap = new Map();
  bundles.forEach(b => bundleMap.set(b.bundleId, b));

  const getUserData = async () => {
    let init = {
      queryStringParameters: {
        "username": user.username
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    };
    let res = await API.get('AdminQueries', '/getUser', init);
    if (Object.keys(res).length > 0) setUserData(res);
  };

  const parseUserAttribute = (attrName, isAttr) => {
    if (isAttr) {
      if (userData && userData['UserAttributes']) {
        let arr = userData['UserAttributes'].filter(a => a.Name === attrName);
        if (arr.length > 0 && arr[0]['Value']) return attrName === 'custom:role' ? JSON.parse(arr[0]['Value']) : arr[0]['Value'];
      }
    } else {
      if (userData) {
        if (attrName === 'Enabled') return userData[attrName] ? 'Enabled' : 'Disabled';
        else if (userData[attrName]) return userData[attrName];
      }
    }
    return 'NA';
  };

  const parseModule = (moduleName) => {
    return userRole['modules'].filter(m => m.name === moduleName)[0];
  };

  const parseUserPrimaryRole = () => {
    return userRole['role'];
  };

  const parseSubAccess = () => {
    return parseModule('SUBSCRIPTION')['access'];
  };

  const parseSubOffers = () => {
    return parseModule('SUBSCRIPTION')['offers'];
  };

  const parseSubBundles = () => {
    return parseModule('SUBSCRIPTION')['bundles'];
  };

  const parseSubFieldCustomizations = () => {
    return parseModule('SUBSCRIPTION')['fieldCustomization'];
  };

  const parseSubNotesAdministrator = (customRole = null) => {
    return parseModule('SUBSCRIPTION', customRole)['notesAdministrator'];
  };

  const parseCatAccess = () => {
    return parseModule('CATALOG')['access'];
  };

  const requestPermissions = () => {
    const userEmail = user.attributes.email;

    const mailTo = 'cbspcloudops@avaya.com; patil296@avaya.com';
    const subject = `Request additional permissions for user: ${userEmail}`;
    const body = `Hey, I would like to request permission for the self-service portal. Below are details:\r\rUser e-mail: ${userEmail}\r\rEnvinronment: ${Utils.getCurrentEnv()}\r\rPurpose: <Add some details about your team and purpose of additional access request>`;
    const mailtoLink = `mailto:${mailTo}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  }

  let displayBundles = [];
  offers.forEach(o => {
    o.offerBundles.forEach(b => {
      if (parseSubOffers() === 'ALL' || (Array.isArray(parseSubOffers()) && parseSubOffers().indexOf(o.offerId) !== -1))
        displayBundles.push({ name: bundleMap.get(b.bundleId).bundleCommercialName, id: b.bundleId, offer: o.offerCommercialName })
    });
  });

  useEffect(() => {
    const roleAttribute = user['attributes']['custom:role'];
    let roleValue = roleAttribute ? JSON.parse(roleAttribute) : RBACUtils.getDefaultUserRole();
    Utils.setInSessionStorage(user.username, 'userRole', roleValue);
    getUserData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Container fluid className="p-2 mb-4">
      <Row className='m-0'>
        {/* Left sidebar */}
        <Col md={2} className='l-nav'>
          <LeftSideBar active='up' username={user.username} />
        </Col>

        <Col md={8}>
          <Container fluid className='p-0'>

            {/* top heading bar */}
            <Row className='m-0'>
              <Col className='title-wrap px-0 d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center justify-content-start title-text'>
                  <div>User profile</div>
                </div>
                <div className='d-flex align-items-center justify-content-end title-settings'>
                  <div className='me-2'>{user.attributes.email}</div>
                  <Dropdown size='sm' as={ButtonGroup} className='me-2'>
                    <Button variant="outline-secondary" title={`Environment: ${curEnv}, Stage: ${curStage}`}>{EnvDetails.filter(e => e.envName === curEnv)[0].dispName}</Button>
                    <Dropdown.Toggle split variant="outline-secondary" id="dropdown-split-basic" />
                    <Dropdown.Menu variant='outline-secondary' align='end'>
                      {
                        EnvDetails.map(e =>
                          Utils.includeEnvInDropdown(e.envName) &&
                          <Dropdown.Item title={`Environment: ${e.envName}, Stage: ${e.stageName}`} href={e.url}>{e.dispName}</Dropdown.Item>
                        )
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button size="sm" variant="outline-secondary" onClick={signOut}>Signout</Button>
                </div>
              </Col>
            </Row>

            <div className='d-flex align-items-center justify-content-start row-title'>
              <p className='mb-0 me-2 text'>User details</p>
              {
                Object.keys(userData).length === 0 &&
                <Spinner size='sm' variant='secondary' className='spinner' animation="border" role="status" />
              }
            </div>
            <Row className="g-0 m-0 ud-row" md={3}>
              {
                userDataFields.map((k, _idx) =>
                  k[0] === 'custom:role' ?
                    <Col key={_idx} className='ud-col'>
                      <div className='key'>{k[1]}</div>
                      <div className={parseUserPrimaryRole() === 'USER' ? 'val role u' : 'val role a'} onClick={() => setShowRoleModal(true)}>
                        {
                          parseUserPrimaryRole()
                        }
                      </div>
                    </Col>
                    :
                    k[0] === 'RequestButton' ?
                      <Col key={_idx} className='ud-col'>
                        <div className='key'>{k[1]}</div>
                        <div className='val'>
                          <Button size='sm' variant="secondary" onClick={() => requestPermissions()}>Request</Button>
                        </div>
                      </Col>
                      :
                      <Col key={_idx} className='ud-col'>
                        <div className='key'>{k[1]}</div>
                        <div className='val'>{parseUserAttribute(k[0], k[2])}</div>
                      </Col>
                )
              }
              <Modal size='lg' show={showRoleModal} onHide={() => setShowRoleModal(false)} keyboard={true} backdrop>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <div>Role details</div>
                    <div className='text-muted' style={{ fontSize: '14px', fontWeight: 'normal' }}>Roles and permissions details for current user</div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container fluid>
                    <Row className='m-0'>
                      <Col className='px-0 py-1'>
                        <Form.Group className="mb-0 w-100 py-2" controlId="primary-role">
                          <div className='d-flex align-items-center justify-content-start'>
                            <Form.Label className='me-2 mb-1'>Role:</Form.Label>
                            <Form.Check inline type="radio" disabled checked={parseUserPrimaryRole() === 'USER'} className='me-1' label='User' name='primary-role-u' id='primary-role-user' />
                            <Form.Check inline type="radio" disabled checked={parseUserPrimaryRole() === 'ADMINISTRATOR'} className='mx-1' label='Administrator' name='primary-role-a' id='primary-role-admin' />
                          </div>
                          <Form.Text className="text-muted d-block">Primary role for the user</Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='m-0'>
                      <Col className='bg-light my-2 px-0 py-1 text-center fw-bold' style={{ letterSpacing: '1.5px', borderWidth: '1px 0px 1px 0px', borderStyle: 'solid', borderColor: '#ccc' }}>SUBSCRIPTION</Col>
                    </Row>
                    <Row className='m-0'>
                      <Col className='px-0 py-1'>
                        <Form.Group className="mb-0 w-100 py-2" controlId="sub-access">
                          <div className='d-flex align-items-center justify-content-start'>
                            <Form.Label className='me-2 mb-1'>Access:</Form.Label>
                            <Form.Check inline disabled checked={parseSubAccess() === 'READ'} type="radio" className='me-1' label='Read' name='sub-access' id='sub-access-r' />
                            <Form.Check inline disabled checked={parseSubAccess() === 'WRITE'} type="radio" className='mx-1' label='Write' name='sub-access' id='sub-access-w' />
                            <Form.Check inline disabled checked={parseSubAccess() === 'NONE'} type="radio" className='mx-1' label='None' name='sub-access' id='sub-access-n' />
                          </div>
                          <Form.Text className="text-muted d-block">Access level for subscription module</Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='m-0'>
                      <Col className='px-0 py-1'>
                        <Form.Group className="mb-0 w-100 py-2" controlId="offers">
                          <div className='d-flex align-items-center justify-content-start'>
                            <Form.Label className='me-2 mb-1'>Offers:</Form.Label>
                            {
                              parseSubOffers() === 'ALL' ?
                                <Form.Check inline type="checkbox" disabled checked label='All offers' name='offers' id='offers-all' />
                                :
                                <div className='flex-grow-1'>
                                  <Multiselect
                                    options={offers.map(o => { return { name: o.offerCommercialName, id: o.offerId } })}
                                    selectedValues={offers.filter(o => parseSubOffers() !== 'ALL' && parseSubOffers().indexOf(o.offerId) !== -1).map(o => { return { name: o.offerCommercialName, id: o.offerId } })}
                                    displayValue='name'
                                    hidePlaceholder={true}
                                    placeholder=""
                                    disable={true}
                                  />
                                </div>
                            }
                          </div>
                          <Form.Text className="text-muted d-block">Offers for which user can create subscriptions.</Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='m-0'>
                      <Col className='px-0 py-1'>
                        <Form.Group className="mb-0 w-100 py-2" controlId="bundles">
                          <div className='d-flex align-items-center justify-content-start'>
                            <Form.Label className='me-2 mb-1'>Bundles:</Form.Label>
                            {
                              parseSubBundles() === 'ALL' ?
                                <Form.Check inline disabled type="checkbox" checked label='All bundles' name='bundles' id='bundles-all' />
                                :
                                <div className='flex-grow-1'>
                                  <Multiselect
                                    options={displayBundles}
                                    selectedValues={displayBundles.filter(b => parseSubBundles() !== 'ALL' && parseSubBundles().indexOf(b.id) !== -1)}
                                    displayValue='name'
                                    groupBy='offer'
                                    hidePlaceholder={true}
                                    placeholder=''
                                    disable={true}
                                  />
                                </div>
                            }
                          </div>
                          <Form.Text className="text-muted d-block">Bundles for which user can create subscriptions.</Form.Text>
                        </Form.Group>
                      </Col>

                    </Row>
                    <Row className='m-0'>
                      <Col className='px-0 py-1'>
                        <Form.Group className="mb-0 w-100 py-2" controlId="fieldCustomization">
                          <div className='d-flex align-items-center justify-content-start'>
                            <Form.Label className='me-2 mb-1'>FieldCustomization:</Form.Label>
                            <Form.Check inline disabled type="checkbox" checked={parseSubFieldCustomizations()} className='mx-1' name='fieldCustomization' id='field-customization' />
                          </div>
                          <Form.Text className="text-muted d-block">Access for customization of fields for subscription.</Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='m-0'>
                      <Col className='px-0 py-1'>
                        <Form.Group className="mb-0 w-100 py-2" controlId="notedAdministrator">
                          <div className='d-flex align-items-center justify-content-start'>
                            <Form.Label className='me-2 mb-1'>Subscription Notes Administrator:</Form.Label>
                            <Form.Check inline disabled type="checkbox" checked={parseSubNotesAdministrator()} className='mx-1' name='subNotesAdministrator' id='sub-notes-admin' />
                          </div>
                          <Form.Text className="text-muted d-block">Marks users as administrator for Subscription notes available in Subsscription Dashboard.</Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='m-0'>
                      <Col className='bg-light my-2 px-0 py-1 text-center fw-bold' style={{ letterSpacing: '1.5px', borderWidth: '1px 0px 1px 0px', borderStyle: 'solid', borderColor: '#ccc' }}>CATALOG</Col>
                    </Row>
                    <Row className='m-0'>
                      <Col className='px-0 py-1'>
                        <Form.Group className="mb-0 w-100 py-2" controlId="cat-access">
                          <div className='d-flex align-items-center justify-content-start'>
                            <Form.Label className='me-2 mb-1'>Access:</Form.Label>
                            <Form.Check inline type="radio" checked={parseCatAccess() === 'READ'} className='me-1' disabled label='READ' name='cat-access' id='cat-access-r' />
                          </div>
                          <Form.Text className="text-muted d-block">Access level for subscription module. For now only READ access is available.</Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" size='sm' onClick={() => setShowRoleModal(false)}>Close</Button>
                </Modal.Footer>
              </Modal>
            </Row>

          </Container>
        </Col>

      </Row>
    </Container>
  );
}

export default withAuthenticator(UserProfile, withAuthenticatorOptions);