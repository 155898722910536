//over ride style

import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import "./overrideBpmnCssStyles/diagram-js.css";
import "./overrideBpmnCssStyles/diagram-js-minimap.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css";

import React, { useEffect, useState } from "react";
import {
  addEventListeners,
  addLayersView,
  getChangedStep,
  getElementId,
  getNewLabel,
  getNewState,
} from "./functions/addEventListenerForBpmnTool";
import { getStreamArn } from "./functions/dynamoDbFunctions";
import { getRegion } from "./functions/awsFunctions";
import { Auth } from "aws-amplify";
import BpmnDrawer from "./bpmnDrawer/BpmnDrawer";
import { SlArrowLeft } from "react-icons/sl";
import { BpmnViewerProps } from "./BpmnViewerStyles";
import DynamoDBStream from "dynamodb-stream";
import { DynamoDBStreams } from "@aws-sdk/client-dynamodb-streams";
import Modeler from "bpmn-js/lib/Modeler";
// import blueRibbon from "../../img/openDrawerBlue.svg";
import { unmarshall } from "@aws-sdk/util-dynamodb";

//bpmn tool

//Props decleration
// BpmnjsTool.propTypes = {
//   data: PropTypes.object.isRequired,
// };

export default function BpmnjsTool({
  data,
  divName,
  liveFlowInterval,
  jsonInfo,
  liveViewKey,
  design,
}) {
  const [showDrawer, setShowDrawer] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  let watchStreamTimeOut;
  let intervalId;
  let viewer; // viewer for bpmn modeler
  let zoomScroll;
  async function loadBpmn(xml) {
    try {
      viewer = new Modeler(BpmnViewerProps);
      viewer.attachTo(`#${divName}`);
      zoomScroll = viewer.get('zoomScroll');

      await viewer.importXML(xml);
      let bpmnDivID = viewer.get("canvas");
      bpmnDivID.zoom("fit-viewport");
    } catch (e) {
      console.error(e);
    }
  }

  async function addLiveViewForBpmnTool(viewer, design) {
    let elements = viewer.get("elementRegistry")._elements;
    let graphModeler = viewer.get("modeling");
    let region = await getRegion();

    const STREAM_ARN = await getStreamArn();
    const ddbStream = new DynamoDBStream(
      new DynamoDBStreams({
        region: region,
        credentials: Auth.currentUserCredentials,
      }),
      STREAM_ARN,
      unmarshall
    );
    await ddbStream.fetchStreamState();
    const watchStream = () => {
      watchStreamTimeOut = setTimeout(
        () =>
          ddbStream
            .fetchStreamState()
            .then(watchStream)
            .catch((err) => {
              console.log(err);

            }),
        10 * 1000
      );
    };

    watchStream();
    ddbStream.on("modify record", (newData, oldData, keys) => {
      let changedStateDict = getChangedStep(newData, oldData);
      if (newData.flow_id === liveViewKey) {
        for (const [key, value] of Object.entries(changedStateDict)) {
          const elementId = getElementId(elements, key);
          let newState = getNewState(value).toUpperCase();
          let newLabel = getNewLabel(value, newState, design);

          graphModeler.setColor([elementId], design[newState]);
          graphModeler.updateLabel(elementId, newLabel);
        }
      }
      intervalId = setInterval(() => {
        ddbStream.fetchStreamState();
      }, liveFlowInterval * 1000);
    });
  }
  useEffect(() => {
    if (data) {
      (async function () {
        await loadBpmn(data);
      })();
    }
    addEventListeners(viewer, {
      setDrawer: setShowDrawer,
      idListJson: jsonInfo,
      setEventDetails: setEventDetails,
    });
    addLayersView(viewer, data);
    // if (json.flow_state !== "FAILED" && json.flow_state !== "COMPLETED")
    addLiveViewForBpmnTool(viewer, design);
    return () => {
      //destructor
      clearTimeout(watchStreamTimeOut);
      clearInterval(intervalId);
    };
  }, []); // Added 'data' as a dependency

  return (
    <>
      {
        showDrawer &&
        <BpmnDrawer
          // steps JSON
          changeDiv={(stat) => setShowDrawer(stat)}
          content={eventDetails}
          offSetTop={1}
          JsonHeadline="Flow"
          toolTipHeadline="Flow ID JSON"
        />
      }
    </>
  );
}
