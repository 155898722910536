import { useState, useEffect } from 'react';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container'
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Collapse from 'react-bootstrap/Collapse';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { useNavigate } from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';

import LeftSideBar from "../common/LeftSideBar";
import EnvDetails from '../../env-details.json';

import sFields from '../../data/FieldData.json';
import fieldRestrictions from '../../data/field_customization_restrictions.json';
import * as Utils from '../../common/utils';
import * as RBACUtils from '../../common/RBACUtils';
import { MdEdit, MdCancel } from "react-icons/md";

import awsExports from '../../aws-exports';

import { Amplify, API } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import '../../scss/field_customization.scss';

Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true
}

const sFields1 = JSON.parse(JSON.stringify(sFields));
const supportedTypes = ['text', 'datetime-local'];
const categories = ['subscription', 'customer', 'bundle'];

const ENV = Utils.getCurrentEnv(), STAGE = Utils.getCurrentStage();

const defaultNewField = { id: '', type: 'text', value: '', category: 'subscription', use: true, mutable: true, isSsd: true, offers: 'ALL' };
const defaultFilter = { type: '', use: '', mutable: '', isSsd: '', category: '' };
const PID1_LINKED_FIELDS = ['orderNumber', 'provisioningId1', 'provisioningId2', 'subscriptionName', 'customerAccountNumber', 'bpLinkId', 'contractNumber', 'opportunityNumber', 'purchaseOrder'];

const FieldCustomization = ({ signOut, user }) => {

  const offers = Utils.fetchFromSessionStorage(user.username, 'offers', [], true).filter(o => RBACUtils.hasAccessToOffer(user.username, o.offerId));

  //#region states

  const [fields, setFields] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);
  const [addFieldModal, setAddFieldModal] = useState({ show: false, edit: false, field: defaultNewField });
  const [removeFieldModal, setRemoveFieldModal] = useState({ show: false, id: '' });
  const [showFilter, setShowFilter] = useState(true);
  const [isProcessing, setProcessing] = useState({ fetching: false, saving: false, message: '' });

  const navigate = useNavigate();

  //#endregion

  const filteredFields = fields.filter(field => {
    let result = true;
    Object.keys(filter).forEach(f => {
      if (filter[f] === '') result = result && true;
      else result = result && filter[f] === field[f].toString()
    });
    return result;
  });

  const categoryOrder = ['subscription', 'customer', 'bundle'];
  const sortedFields = filteredFields.sort((f1, f2) => {
    return categoryOrder.indexOf(f1['category']) - categoryOrder.indexOf(f2['category']);
  });

  //#region functions 

  const findByID = (id, arr) => {
    let idx = arr.findIndex(f => f.id === id);
    if (idx !== -1) return arr[idx];
    else return {};
  };

  const mergeFields = (orig, user) => {
    let fields = JSON.parse(JSON.stringify(orig));
    user.forEach(f => {
      let field = findByID(f.id, fields);
      if (Object.keys(field).length > 0) {
        Object.keys(f).forEach(k => {
          if (k !== 'id') field[k] = f[k];
        });
      } else {
        fields.push(f);
      }
    });
    return fields;
  };

  const handleSaveField = (field) => {
    let fu = false;
    if (field.id !== 'provisioningId1') {
      let idx = fields.findIndex(f => f.id === field.id);
      if (idx !== -1) { fields[idx] = { ...field }; fu = true; }
    } else {
      PID1_LINKED_FIELDS.forEach(k => {
        let idx = fields.findIndex(f => f.id === k);
        if (idx !== -1) { fields[idx]['value'] = field['value']; fu = true; }
      });
    }
    if (fu) setFields(fields.slice());
  };

  const fieldDiff = (f1, f2) => {
    return Object.keys(f2).filter(k => !Utils.jsonEquals(f2[k], f1[k])).reduce((o, i) => Object.assign(o, { [i]: f2[i] }), {});
  };

  const calculateDiff = (orig, curr) => {
    let diff = [];
    orig.forEach(f1 => {
      let f2 = findByID(f1.id, curr);
      let dobj = fieldDiff(f1, f2);
      if (Object.keys(dobj).length !== 0) {
        diff.push({ id: f1['id'], ...dobj });
      }
    });
    let user_fields = curr.filter(f => orig.map(k => k.id).indexOf(f.id) === -1);
    if (user_fields.length > 0) diff.push(...user_fields);

    return diff;
  };

  const addField = (field) => {
    let idx = fields.findIndex(f => f.id === field.id);
    if (idx === -1) fields.push(field);
    else fields[idx] = { ...field };
    setFields(fields.slice());
  };

  const showRemoveFieldModal = (id) => {
    if (id) setRemoveFieldModal({ ...removeFieldModal, show: true, id: id });
  };

  const removeField = (id) => {
    let idx = fields.findIndex(f => f.id === id);
    if (idx && idx !== -1) {
      fields.splice(idx, 1);
      setFields(fields.slice());
    }
  };

  let prevSysCategory = '', prevUserCategory = '';
  const calculateIdForRow = (field, isSystem = true) => {
    let result = '';
    if (isSystem) {
      if (field.category !== prevSysCategory) result = field.category.slice(0, 2);
      prevSysCategory = field.category;
    } else {
      if (field.category !== prevUserCategory) result = field.category.slice(0, 2);
      prevUserCategory = field.category;
    }
    return result;
  };

  const saveUserFieldCustomization = async () => {
    setProcessing({ fetching: false, saving: true, message: '' });
    return new Promise(async () => {
      // setTimeout(() => {
      //   setProcessing({ fetching: false, saving: false, message: 'User information updated successfully!' });
      //   setFields(fields.slice());
      // }, 2000);
      let subTemplate = calculateDiff(sFields1, fields);
      let params = {
        'stage': STAGE,
        'region': user.pool.userPoolId.split('_')[0],
        'id': user.username,
        'template': subTemplate
      };

      await API.post("SspBackendApi", "/userinfo", {
        body: params,
        headers: {
          "Content-Type": "application/json"
        }
      }).then((response) => {
        setProcessing({ fetching: false, saving: false, message: 'success::Field customizations updated for user successfully.' });
        Utils.setInSessionStorage(user.username, 'fc', subTemplate);
        setFields(fields.slice());
      }).catch((error) => {
        setProcessing({ fetching: false, saving: false, message: 'error::' + error.message });
      });
    });
  }

  const fetchUserFieldCustomization = async () => {

    return new Promise(async (resolve, reject) => {

      setProcessing({ fetching: true, saving: false, message: '' });

      // setTimeout(() => {
      //   setProcessing({ fetching: false, saving: false, message: '' });
      //   Utils.setInSessionStorage(user.username, 'fc', []);
      //   resolve([]);
      // }, 2000);

      let params = {
        response: false,
        queryStringParameters: {
          'stage': STAGE,
          'id': user.username,
          'region': user.pool.userPoolId.split('_')[0]
        }
      };

      await API.get("SspBackendApi", "/userinfo", params)
        .then(response => {
          if (response.status === 'success') {
            setProcessing({ fetching: false, saving: false, message: '' });
            let res = response.data && response.data.subTemplate ? response.data.subTemplate : [];
            Utils.setInSessionStorage(user.username, 'fc', res);
            return resolve(res);
          } else {
            setProcessing({ fetching: false, saving: false, message: 'success::' + response.message });
            Utils.setInSessionStorage(user.username, 'fc', []);
            return reject([]);
          }
        }).catch(error => {
          console.log(`Error occurred while fetching field customizations for user: ${error.message}`);
          setProcessing({ fetching: false, saving: false, message: 'error::' + error.message });
          Utils.setInSessionStorage(user.username, 'fc', []);
          return reject([]);
        });

    });
    //return Utils.fetchFromLocalStorage(user.username, 'fc', []);
  }

  //#endregion

  useEffect(() => {
    const roleAttribute = user['attributes']['custom:role'];
    let roleValue = roleAttribute ? JSON.parse(roleAttribute) : RBACUtils.getDefaultUserRole();
    Utils.setInSessionStorage(user.username, 'userRole', roleValue);
    initActivities();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const initActivities = async () => {
    if (RBACUtils.hasSubscriptionAccess(user.username, 'NONE') || !RBACUtils.hasFieldCustomizationAccess(user.username)) {
      navigate("/user/profile");
    } else {
      window.history.pushState("", "Title", window.location.pathname);
      let uFields = await fetchUserFieldCustomization();
      let cFields = mergeFields(sFields1, uFields);
      setFields(cFields.slice());
    }
  };

  const editFiled = (field) => {
    setAddFieldModal({ ...addFieldModal, show: true, edit: true, field: { ...field } });
  };

  let userFC = Utils.fetchFromSessionStorage(user.username, 'fc', []);
  const unSavedChanges = calculateDiff(mergeFields(sFields1, userFC), fields).length > 0 || calculateDiff(fields, mergeFields(sFields1, userFC)).length > 0;

  return (

    <Container fluid className="p-2 mb-4">
      <Row className='m-0'>

        {/* Left sidebar */}
        <Col md={2} className='l-nav'>
          <LeftSideBar active='fc' username={user.username} />
        </Col>

        {/* Content */}
        <Col md={8}>
          <Container fluid data-bs-spy="scroll" data-bs-target='#navbar' data-bs-offset="0" tabIndex="0">
            {/* top heading bar */}
            <Row>
              <Col className='title-wrap d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center justify-content-start title-text'>
                  <div>Fields customizations</div>
                </div>
                <div className='d-flex align-items-center justify-content-end title-settings'>
                  <div className='me-2'>{user.attributes.email}</div>
                  <Dropdown size='sm' as={ButtonGroup} className='me-2'>
                    <Button variant="outline-secondary" title={`Environment: ${ENV}, Stage: ${STAGE}`}>{EnvDetails.filter(e => e.envName === ENV)[0].dispName}</Button>
                    <Dropdown.Toggle split variant="outline-secondary" id="dropdown-split-basic" />
                    <Dropdown.Menu variant='outline-secondary' align='end'>
                      {
                        EnvDetails.map(e =>
                          Utils.includeEnvInDropdown(e.envName) &&
                          <Dropdown.Item key={`${e.envName}-env-dropdown`} title={`Environment: ${e.envName}, Stage: ${e.stageName}`} href={e.url}>{e.dispName}</Dropdown.Item>
                        )
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button size="sm" variant="outline-secondary" onClick={() => signOut()}>Signout</Button>
                </div>
              </Col>
            </Row>
            {/* Content */}
            <Row>
              <Col>
                <div className='d-flex justify-content-between align-items-center'>
                  <div id='sf' className='d-flex justify-content-start align-items-center'>
                    <div><h4 className='mb-0'>System fields</h4></div>
                    {
                      isProcessing['fetching'] &&
                      <div className='ms-2 pt-1'><Spinner size='sm' variant='secondary' className='spinner' style={{ marginTop: '-2px' }} animation="border" role="status" /></div>
                    }
                  </div>
                  <div>
                    <div className='d-flex justify-content-end align-items-center me-0 pe-0'>
                      <Button className='me-2' size='sm' title={!isProcessing['fetching'] && unSavedChanges ? 'Unsaved changes found, click to save changes' : 'Click to save changes'} variant={!isProcessing['fetching'] && unSavedChanges ? 'danger' : 'secondary'} onClick={() => saveUserFieldCustomization()}>
                        Save
                        {
                          isProcessing['saving'] &&
                          <Spinner size='sm' variant='light' className='spinner ms-2' animation="grow" role="status" />
                        }
                      </Button>
                      <Button className='ms-2' size='sm' variant='secondary' onClick={() => setAddFieldModal({ ...addFieldModal, show: true, edit: false, field: { ...defaultNewField } })} title='Add new custom field'>Add field</Button>
                    </div>
                  </div>
                </div>

                {/* {
                  calculateDiff(sFields1, fields).length > 0 &&
                  <>
                    <h6 className='mt-3 mb-1'>User customizations <small className='ms-1' style={{ color: '#00f', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setShowUserCustomizations(!showUserCustomizations)} >{showUserCustomizations ? 'Hide' : 'Show'}</small></h6>
                    <Collapse in={showUserCustomizations}>
                      <div className='bg-light p-2 mb-2'>
                        {
                          <ReactJson src={calculateDiff(sFields1, fields)} name='Customizations' iconStyle="triangle" indentWidth={2} collapsed={false} enableClipboard={false} displayObjectSize={false} displayDataTypes={false} quotesOnKeys={false} groupArraysAfterLength={1000} displayArrayKey={false} />
                        }
                      </div>
                    </Collapse>
                  </>
                } */}
                <div className='my-3'>
                  <div>
                    <h6 className='mb-2'>Filter <small className='ms-1' style={{ color: '#00f', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setShowFilter(!showFilter)} >{showFilter ? 'Hide' : 'Show'}</small></h6>
                  </div>
                  <Collapse in={showFilter}>
                    <div>
                      <div className='d-flex align-items-center justify-content-start bg-light py-2 px-2 w-auto' style={{ borderRadius: '0px 6px 6px 6px' }}>
                        <div className='me-3'>
                          <div className='mb-0'><small>Type:</small></div>
                          <Form.Select size='sm' className='w-auto' value={filter['type']} onChange={(e) => setFilter({ ...filter, type: e.target.value })}>
                            <option value="">All</option>
                            <option value="text">Text</option>
                            <option value="datetime-local">Date</option>
                          </Form.Select>
                        </div>

                        <div className='me-3'>
                          <div className='mb-0'><small>Use:</small></div>
                          <Form.Select size='sm' className='w-auto' value={filter['use']} onChange={(e) => setFilter({ ...filter, use: e.target.value })}>
                            <option value="">All</option>
                            <option value="true">Used</option>
                            <option value="false">Skipped</option>
                          </Form.Select>
                        </div>

                        <div className='me-3'>
                          <div className='mb-0'><small>Mutable:</small></div>
                          <Form.Select size='sm' className='w-auto' value={filter['mutable']} onChange={(e) => setFilter({ ...filter, mutable: e.target.value })}>
                            <option value="">All</option>
                            <option value="true">Mutable</option>
                            <option value="false">Immutable</option>
                          </Form.Select>
                        </div>

                        <div className='me-3'>
                          <div className='mb-0'><small>isSsd:</small></div>
                          <Form.Select size='sm' className='w-auto' value={filter['isSsd']} onChange={(e) => setFilter({ ...filter, isSsd: e.target.value })}>
                            <option value="">All</option>
                            <option value="true">SSD</option>
                            <option value="false">Non-SSD</option>
                          </Form.Select>
                        </div>

                        <div className='me-3'>
                          <div className='mb-0'><small>Category:</small></div>
                          <Form.Select size='sm' className='w-auto' value={filter['category']} onChange={(e) => setFilter({ ...filter, category: e.target.value })}>
                            <option value="">All</option>
                            <option value="subscription">Subscription</option>
                            <option value="customer">Customer</option>
                            <option value="bundle">Bundle</option>
                          </Form.Select>
                        </div>

                        <div>
                          <div className='mb-0'><small>&nbsp;</small></div>
                          <Button size='sm' variant='secondary' onClick={() => setFilter({ ...defaultFilter })} title='Reset filter'>Reset</Button>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>

                <Table>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Type</th>
                      <th>Value</th>
                      <th>Use</th>
                      <th>Mutable</th>
                      <th>isSsd</th>
                      <th>Category</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      sortedFields.filter(field => sFields1.map(k => k.id).indexOf(field.id) !== -1).map(f =>
                        <EditableField key={f.id} field={f} onSave={handleSaveField} custom={false} removeField={removeField} rowId={calculateIdForRow(f)} offers={offers} editFieldFn={editFiled} />
                      )
                    }
                  </tbody>
                </Table>
                {
                  sortedFields.filter(f => sFields1.map(k => k.id).indexOf(f.id) === -1).length > 0 &&
                  <>
                    <h4 className='mt-4 mb-2' id='uf'>User fields</h4>
                    <Table>
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Type</th>
                          <th>Value</th>
                          <th>Use</th>
                          <th>Mutable</th>
                          <th>isSsd</th>
                          <th>Category</th>
                          <th>Offers</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          sortedFields.filter(f => sFields1.map(k => k.id).indexOf(f.id) === -1).map(f => (
                            <EditableField key={f.id} field={f} onSave={handleSaveField} custom={true} removeField={showRemoveFieldModal} rowId={calculateIdForRow(f, false)} offers={offers} editFieldFn={editFiled} />
                          ))
                        }
                      </tbody>
                    </Table>
                  </>
                }
                <div className='d-flex justify-content-end align-items-center me-0 pe-0'>
                  <Button className='me-2' size='sm' title={!isProcessing['fetching'] && unSavedChanges ? 'Unsaved changes found, click to save changes' : 'Click to save changes'} variant={!isProcessing['fetching'] && unSavedChanges ? 'danger' : 'secondary'} onClick={() => saveUserFieldCustomization()}>
                    Save
                    {
                      isProcessing['saving'] &&
                      <Spinner size='sm' variant='light' className='spinner ms-2' animation="grow" role="status" />
                    }
                  </Button>
                  <Button className='ms-2' size='sm' variant='secondary' onClick={() => setAddFieldModal({ ...addFieldModal, show: true, edit: false, field: { ...defaultNewField } })} title='Add new custom field'>Add field</Button>
                </div>

                {/* add new field modal */}
                <Modal size='lg' show={addFieldModal['show']} onHide={() => setAddFieldModal({ ...addFieldModal, show: false })} backdrop="static" keyboard={false}>
                  <Modal.Header closeButton>
                    <Modal.Title>Add new field</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Container fluid>
                      <Row style={{ margin: '0px' }}>
                        <Col style={{ padding: '5px 0px' }}>This will add new field under 'User fields', and it can be removed later.</Col>
                      </Row>
                      <Row style={{ margin: '0px' }}>
                        <Col style={{ padding: '5px 0px' }} className='d-flex justify-content-start'>
                          <Form.Group className="mb-0 w-50 p-2" controlId="fieldId">
                            <Form.Label>Id</Form.Label>
                            <Form.Control type="text" size='sm' value={addFieldModal['field']['id']} disabled={addFieldModal['edit']} onChange={(e) => setAddFieldModal({ ...addFieldModal, field: { ...addFieldModal['field'], id: e.target.value } })} placeholder="Enter field id" />
                            <Form.Text className="text-muted">Unique and JSON like key format e.g. myCustomKey</Form.Text>
                          </Form.Group>
                          <Form.Group className="mb-0 w-50 p-2" controlId="fieldType">
                            <Form.Label>Type</Form.Label>
                            <Form.Select size='sm' value={addFieldModal['field']['type']} onChange={(e) => setAddFieldModal({ ...addFieldModal, field: { ...addFieldModal['field'], type: e.target.value } })}>
                              {
                                supportedTypes.map(t =>
                                  <option key={t} value={t}>{t}</option>
                                )
                              }
                            </Form.Select>
                            <Form.Text className="text-muted">Choose one of the type for new field</Form.Text>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row style={{ margin: '0px' }}>
                        <Col style={{ padding: '5px 0px' }} className='d-flex justify-content-start'>
                          <Form.Group className="mb-0 w-50 p-2" controlId="fieldValue">
                            <Form.Label>Default value</Form.Label>
                            <Form.Control type="text" size='sm' value={addFieldModal['field']['value']} onChange={(e) => setAddFieldModal({ ...addFieldModal, field: { ...addFieldModal['field'], value: e.target.value } })} placeholder="Enter field default value" />
                            <Form.Text className="text-muted">Default value for new field</Form.Text>
                          </Form.Group>
                          <Form.Group className="mb-0 w-50 p-2" controlId="fieldCategory">
                            <Form.Label>Category</Form.Label>
                            <Form.Select size='sm' value={addFieldModal['field']['category']} onChange={(e) => setAddFieldModal({ ...addFieldModal, field: { ...addFieldModal['field'], category: e.target.value } })}>
                              {
                                categories.map(c =>
                                  <option key={c} value={c}>{c}</option>
                                )
                              }
                            </Form.Select>
                            <Form.Text className="text-muted">Select one of the category for new field</Form.Text>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row style={{ margin: '0px' }}>
                        <Col style={{ padding: '5px 0px' }} className='d-flex justify-content-start'>
                          <Form.Group className="mb-0 w-50 p-2" controlId="fieldDisplayTitle">
                            <Form.Label>Display title (Optional)</Form.Label>
                            <Form.Control type="text" size='sm' value={addFieldModal['field']['title']} onChange={(e) => setAddFieldModal({ ...addFieldModal, field: { ...addFieldModal['field'], title: e.target.value } })} placeholder="Enter field display title" />
                            <Form.Text className="text-muted">Display title for the field</Form.Text>
                          </Form.Group>
                          <Form.Group className="mb-0 w-50 p-2" controlId="fieldDescription">
                            <Form.Label>Field description (Optional)</Form.Label>
                            <Form.Control type="text" size='sm' value={addFieldModal['field']['description']} onChange={(e) => setAddFieldModal({ ...addFieldModal, field: { ...addFieldModal['field'], description: e.target.value } })} placeholder="Enter field description" />
                            <Form.Text className="text-muted">Description for the  new field</Form.Text>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row style={{ margin: '0px' }}>
                        <Col style={{ padding: '5px 0px' }} className='d-flex justify-content-start'>
                          <Form.Group className="mb-0 w-100 p-2" controlId="fieldOffers">
                            <Form.Label>Applicable Offers</Form.Label>
                            <div className='d-flex align-items-center justify-content-start'>
                              <div style={{ width: '85%' }}>
                                <Multiselect
                                  onSelect={(selectedList, selectedItem) => setAddFieldModal({ ...addFieldModal, field: { ...addFieldModal['field'], offers: selectedList.map(e => e.id) } })}
                                  onRemove={(selectedList, removedItem) => setAddFieldModal({ ...addFieldModal, field: { ...addFieldModal['field'], offers: selectedList.map(e => e.id) } })}
                                  options={[...offers.map(o => { return { name: o.offerCommercialName, id: o.offerId } })]}
                                  selectedValues={offers.filter(o => addFieldModal['field']['offers'] !== 'ALL' && addFieldModal['field']['offers'].indexOf(o.offerId) !== -1).map(o => { return { name: o.offerCommercialName, id: o.offerId } })}
                                  displayValue='name'
                                  placeholder='Choose one/more offers'
                                  showCheckbox
                                  closeOnSelect={false}
                                  disable={addFieldModal['field']['offers'] === 'ALL'}
                                />
                              </div>
                              <div style={{ width: '15%' }}>
                                <Form.Check type="checkbox" onChange={e => setAddFieldModal({ ...addFieldModal, field: { ...addFieldModal['field'], offers: e.target.checked ? 'ALL' : [] } })} checked={addFieldModal['field']['offers'] === 'ALL'} className='ms-3' label='All offers' name='offers' id='offers-all' />
                              </div>
                            </div>
                            <Form.Text className="text-muted">Select one or more offers for which this field will be applicable.</Form.Text>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row style={{ margin: '0px' }}>
                        <Col style={{ padding: '5px 0px' }} className='d-flex justify-content-start'>
                          <Form.Group className="mb-0 w-50 p-2" controlId="fieldValue">
                            <Form.Label>Use:</Form.Label>
                            <Form.Check type="checkbox" className='d-inline-block ps-1' checked={addFieldModal['field']['use']} onChange={(e) => setAddFieldModal({ ...addFieldModal, field: { ...addFieldModal['field'], use: e.target.checked } })} />
                            <Form.Text className="text-muted d-block">New field to use or skip</Form.Text>
                          </Form.Group>
                          <Form.Group className="mb-0 w-50 p-2" controlId="fieldMutable">
                            <Form.Label>Mutable:</Form.Label>
                            <Form.Check type="checkbox" className='d-inline-block ps-1' checked={addFieldModal['field']['mutable']} onChange={(e) => setAddFieldModal({ ...addFieldModal, field: { ...addFieldModal['field'], mutable: e.target.checked } })} />
                            <Form.Text className="text-muted d-block">New field can be modified or not</Form.Text>
                          </Form.Group>
                          <Form.Group className="mb-0 w-50 p-2" controlId="fieldIsSsd">
                            <Form.Label>isSsd:</Form.Label>
                            <Form.Check disabled checked={addFieldModal['field']['isSsd']} type="checkbox" className='d-inline-block ps-1' />
                            <Form.Text className="text-muted d-block">New field is always part of SSD</Form.Text>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" size='sm' onClick={() => { setAddFieldModal({ ...addFieldModal, show: false }); addField({ ...addFieldModal['field'] }); }}>Ok</Button>
                    <Button variant="secondary" size='sm' onClick={() => setAddFieldModal({ ...addFieldModal, show: false })}>Cancel</Button>
                  </Modal.Footer>
                </Modal>

                <Modal size='md' show={removeFieldModal['show']} onHide={() => setRemoveFieldModal({ ...removeFieldModal, show: false })} backdrop="static" keyboard={false}>
                  <Modal.Header closeButton>
                    <Modal.Title>Remove field</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Are you sure you want to remote this field '{removeFieldModal['id']}' ?</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" size='sm' onClick={() => { setRemoveFieldModal(false); removeField(removeFieldModal['id']); }}>Ok</Button>
                    <Button variant="secondary" size='sm' onClick={() => setRemoveFieldModal({ ...removeFieldModal, show: false })}>Cancel</Button>
                  </Modal.Footer>
                </Modal>

              </Col>
            </Row>
          </Container>
        </Col>

        {/* Right sidebar */}
        <Col md={2}>
          {
            isProcessing['message'] !== '' &&
            <ToastContainer className={isProcessing['message'].split('::')[0] === 'error' ? 'p-3 tc error' : 'p-3 tc'} position='top-center'>
              <Toast onClose={() => setProcessing({ fetching: false, saving: false, message: '' })} size='sm' show={isProcessing['message'] !== ''} autohide={true}>
                <Toast.Header closeButton={true}>
                  <strong className="me-auto">{isProcessing['message'].split('::')[0] === 'error' ? 'Error' : 'Notification'}</strong>
                </Toast.Header>
                <Toast.Body>{isProcessing['message'].split('::')[1]}</Toast.Body>
              </Toast>
            </ToastContainer>
          }
          {
            <Nav id='navbar' className="flex-column r-nav">
              <p className='mb-1'>On this page</p>
              <Nav.Link href="#sf" eventKey='sf'>System fields</Nav.Link>
              <Nav.Link className='ps-4' style={{ fontSize: '13px' }} href='#sf-su' eventKey='sf-su'>Subscription fields</Nav.Link>
              <Nav.Link className='ps-4' style={{ fontSize: '13px' }} href='#sf-cu' eventKey='sf-cu'>Customer fields</Nav.Link>
              <Nav.Link className='ps-4' style={{ fontSize: '13px' }} href='#sf-bu' eventKey='sf-bu'>Bundle fields</Nav.Link>
              {
                sortedFields.filter(f => sFields1.map(k => k.id).indexOf(f.id) === -1).length > 0 &&
                <>
                  <Nav.Link href="#uf" eventKey='uf'>User fields</Nav.Link>
                  <Nav.Link className='ps-4' style={{ fontSize: '13px' }} href='#uf-su' eventKey='uf-su'>Subscription fields</Nav.Link>
                  <Nav.Link className='ps-4' style={{ fontSize: '13px' }} href='#uf-cu' eventKey='uf-cu'>Customer fields</Nav.Link>
                  <Nav.Link className='ps-4' style={{ fontSize: '13px' }} href='#uf-bu' eventKey='uf-bu'>Bundle fields</Nav.Link>
                </>
              }
            </Nav>
          }
        </Col>

      </Row>
    </Container>
  );
}

const EditableField = ({ field, onSave, custom, removeField, rowId, offers, editFieldFn }) => {

  const [toggleChip, setToggleChip] = useState({});
  const col_widths = [[25, 20], [15, 10], [30, 18], [5, 5], [5, 5], [5, 5], [15, 12], [0, 25]];

  const handleSave = (name, value) => {
    onSave({ ...field, [name]: value });
  };

  const MAX_CHIPS = 4;

  const isFieldPropertyDisabled = (fid, prop) => {
    return fieldRestrictions.filter(f => f.id === fid && f.restrictions.indexOf(prop) !== -1).length > 0;
  };

  let rId = Utils.isEmpty(rowId) ? '' : custom ? 'uf-' + rowId : 'sf-' + rowId;
  let colIdx = custom ? 1 : 0;

  return (
    <>
      <tr className='align-middle' id={rId} style={{ width: '100%' }}>
        <td style={{ width: col_widths[0][colIdx] + '%' }}>
          <div className='d-flex align-items-center justify-content-start'>
            <div>{field.id}</div>
            {
              custom &&
              <>
                <div className='ms-2' style={{ cursor: 'pointer' }} onClick={() => editFieldFn({ ...field })}><MdEdit /></div>
                <div className='ms-1' style={{ cursor: 'pointer' }} onClick={() => removeField(field.id)}><MdCancel /></div>
              </>
            }
          </div>
        </td>
        <td style={{ width: col_widths[1][colIdx] + '%' }}>
          <Form.Select size='sm' className='w-100' disabled={isFieldPropertyDisabled(field.id, 'type')} onChange={(e) => handleSave('type', e.target.value)} defaultValue={field.type}>
            {
              supportedTypes.map(t =>
                <option key={t} value={t}>{t}</option>
              )
            }
          </Form.Select>
        </td>
        <td style={{ width: col_widths[2][colIdx] + '%' }}>
          <Form.Control size='sm' className='w-100' disabled={isFieldPropertyDisabled(field.id, 'value')} type={field.type} value={field.type === 'datetime-local' ? Utils.toDefaultFormat(field.value) : field.value} onChange={(e) => handleSave('value', field.type === 'datetime-local' ? Utils.toCBSPFormat(e.target.value) : e.target.value)} />
        </td>
        <td style={{ width: col_widths[3][colIdx] + '%' }}>
          <Form.Check type="checkbox" disabled={isFieldPropertyDisabled(field.id, 'use')} checked={field.use} onChange={(e) => handleSave('use', e.target.checked)} />
        </td>
        <td style={{ width: col_widths[4][colIdx] + '%' }}>
          <Form.Check type="checkbox" disabled={isFieldPropertyDisabled(field.id, 'mutable')} checked={field.mutable} onChange={(e) => handleSave('mutable', e.target.checked)} />
        </td>
        <td style={{ width: col_widths[5][colIdx] + '%' }}>
          <Form.Check type="checkbox" disabled={custom || isFieldPropertyDisabled(field.id, 'isSsd')} checked={field.isSsd} onChange={(e) => handleSave('isSsd', e.target.checked)} />
        </td>
        <td style={{ width: col_widths[6][colIdx] + '%' }}>
          <Form.Select size='sm' className='w-100' disabled={isFieldPropertyDisabled(field.id, 'category')} onChange={(e) => handleSave('category', e.target.value)} value={field.category}>
            {
              categories.map(c =>
                <option key={c} value={c}>{c}</option>
              )
            }
          </Form.Select>
        </td>
        {
          custom &&
          <td style={{ width: col_widths[7][1] + '%' }}>
            {
              field.offers === 'ALL' ?
                <small className='px-2 py-1 me-1 mb-1' style={{ backgroundColor: '#faecf3', color: '#8b2257', fontSize: '12px', borderRadius: '6px' }}>All offers</small>
                :
                <div className='d-flex align-items-center justify-content-start flex-wrap'>
                  {
                    offers.filter(o => field.offers.indexOf(o.offerId) !== -1).map((o, _idx) =>
                      (_idx < MAX_CHIPS || toggleChip[field.id]) &&
                      <span key={'u-a-oid' + o.offerId} className='px-2 py-1 me-1 mb-1' style={{ backgroundColor: '#ecfaec', color: '#228b22', fontSize: '12px', borderRadius: '6px' }}>{o.offerCommercialName}</span>
                    )
                  }
                  {
                    offers.filter(o => field.offers.indexOf(o.offerId) !== -1).length > MAX_CHIPS &&
                    <>
                      {
                        !toggleChip[field.id] ?
                          <span className='p-1 pe-2 mx-1 mb-1' style={{ backgroundColor: '#faecfa', color: '#a72ca7', fontSize: '12px', borderRadius: '30px', cursor: 'pointer' }} onClick={() => setToggleChip({ ...toggleChip, [field.id]: true })} title={`Show ${offers.filter(o => field.offers.indexOf(o.offerId) !== -1).length - MAX_CHIPS} more offers`}>
                            {`+${offers.filter(o => field.offers.indexOf(o.offerId) !== -1).length - MAX_CHIPS}`}
                          </span>
                          :
                          <span className='px-1 py-1 mx-1 mb-1' style={{ backgroundColor: '#faecfa', color: '#a72ca7', fontSize: '12px', borderRadius: '30px', cursor: 'pointer' }} onClick={() => setToggleChip({ ...toggleChip, [field.id]: false })} title='Collapse offers'>Hide</span>
                      }
                    </>
                  }
                </div>
            }
          </td>
        }
      </tr>
    </>
  );
}

export default withAuthenticator(FieldCustomization, withAuthenticatorOptions);