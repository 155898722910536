import React, { useState, useEffect } from "react";

import { Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import { getComparator } from "../bpmn/WorkflowTable/SearchFlowFunctions";
import Table from "react-bootstrap/Table";

import { useNavigate } from "react-router-dom";
import "json-diff-kit/dist/viewer.css";

import { Amplify, Auth } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import LeftSideBar from "../common/LeftSideBar";
import TopHeadingBar from "../common/TopHeadingBar";

import * as Utils from "../../common/utils";
import * as RBACUtils from "../../common/RBACUtils";

import "../../scss/user_role_management.scss";
import '../../scss/common.scss';
import '../../scss/workflow.scss';

import { getRegion } from "../bpmn/functions/awsFunctions";
import { getStreamArn } from "../bpmn/functions/dynamoDbFunctions";
import DynamoDBStream from "dynamodb-stream";
import { DynamoDBStreams } from "@aws-sdk/client-dynamodb-streams";
import { unmarshall } from "@aws-sdk/util-dynamodb";
import Spinner from "react-bootstrap/Spinner";
import awsExports from "../../aws-exports";

Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true,
};

const LiveWorkFlows = ({ signOut, user }) => {

  const navigate = useNavigate();

  const [flows, setFlows] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageOptions, setPageOptions] = useState([1]);
  const [page, setPage] = useState(0);
  const [orderBy] = useState("start_date");
  const [order] = useState("asc");

  
  const pageSelectionOptions = [5, 10, 20];
  let stopStreamingTimeOut;
  const clearAllTimeOuts = () => {
    window.clearTimeout(this.watchStreamTimeOut);
    window.clearTimeout(this.stopStreamingTimeOut);
    console.log("cleaned all listeners");
    this.setState({ liveFlowIndicator: false });
  };
  const secoundsToWaitUntilIdle = 1800;

  const liveView = async () => {

    const resetTimeOut = () => {
      window.clearTimeout(stopStreamingTimeOut);
      stopStreamingTimeOut = setTimeout(() => {
        console.log("cleared due to being idle");
        clearAllTimeOuts();
      }, 1000 * secoundsToWaitUntilIdle);
    };
    let region = await getRegion();
    const STREAM_ARN = await getStreamArn();
    const ddbStream = new DynamoDBStream(
      new DynamoDBStreams({
        region: region,
        credentials: Auth.currentUserCredentials,
      }),
      STREAM_ARN,
      unmarshall
    );

    await ddbStream.fetchStreamState();

    // parse results and store in local state
    const watchStream = () => {
      setTimeout(
        () =>
          ddbStream
            .fetchStreamState()
            .then(watchStream)
            .catch((err) => {
              // Continue watching the stream even after an error
              watchStream();
            }),
        10 * 1000
      );
    };
    watchStream();
    ddbStream.on("insert record", (data, keys) => {
      resetTimeOut();
      createTableRow(data);
    });

    ddbStream.on("remove record", (data, keys) => { });

    ddbStream.on("modify record", (newData, oldData, keys) => {
      resetTimeOut();
      createTableRow(newData);
    });
  };

  const createTableRow = (Data) => {
    const createItem = (id) => ({
      id,
      flowId: Data.flow_id,
      flowName: Data.flow_name,
      subscription:Data.flow_external_id,
      linkId: Data.link_id,
      flowType: Data.flow_type,
      flowState: Data.flow_state,
      lastUpdated: Utils.getCurrentTime(),
    });
    let itemId;
    if (Data.flow_id in flows) {
      itemId = flows[Data.flow_id].id;
    } else {
      itemId = Object.keys(flows).length + 1;
    }
    const item = createItem(itemId);
    setFlows((flows) => ({ ...flows, [Data.flow_id]: item }));

  };

  const columns = [
    { label: "#", id: "idx", width: '5%' },
    { label: "Flow Name", id: "flowName", width: "25%", },
    { label: "Subscription", id: "subscription", width: "15%" },
    { label: "LinkId", id: "linkId", width: "15%" },
    { label: "Flow Type", id: "flowType", width: "20%", },
    { label: "state", id: "flowState", width: "15%", },
    { label: "LastUpdated", id: "lastUpdated", width: "20%", }
  ];
  const STATUS_COLOR_DICT = {
    COMPLETED: { stroke: "#005706" },
    FAILED: { stroke: "#de2134" },
    IN_PROGRESS: { stroke: "#e87b0e" },
    PENDING: { stroke: "#e87b0e" },
  };
  const buildFlowStatus = (status) => {
    if (!status || !STATUS_COLOR_DICT[status.toUpperCase()]) return <span className='wf-status' title='status not available' style={{ backgroundColor: '#666' }}>-</span>;
    return <span className='wf-status' style={{ backgroundColor: STATUS_COLOR_DICT[status.toUpperCase()]['stroke'] }}>{status}</span>;
  };  
  const handleChangeRowsPerPage = (rowPerPageInput) => {
    setRowsPerPage(rowPerPageInput);
    setPage(0);
    const optionsPage = Math.ceil(Object.keys(flows).length / rowPerPageInput);
    const optionsArray = Array.from({ length: optionsPage }, (_, i) => i);
    setPageOptions(optionsArray);
  };

  useEffect(() => {
    const roleAttribute = user["attributes"]["custom:role"];
    let roleValue = roleAttribute ? JSON.parse(roleAttribute) : RBACUtils.getDefaultUserRole();
    Utils.setInSessionStorage(user.username, "userRole", roleValue);
    if (RBACUtils.hasSubscriptionAccess(user.username, "NONE")) {
      navigate("/user/profile");
    } else {
      liveView();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="p-2 mb-4">
      <Row className="m-0">

        {/* Left sidebar */}
        <Col md={2} className="l-nav">
          <LeftSideBar active="lw" username={user.username} />
        </Col>

        <Col md={10}>

          <Container fluid data-bs-spy="scroll" data-bs-target='#navbar' data-bs-offset="0" tabIndex="0">

            <TopHeadingBar title='Live workflows' user={user} signOut={signOut} />

            <Row>
              <Col>

                {/* table area */}
                <div>
                  <div className='d-flex align-items-center justify-content-between mb-3'>
                    <div>
                      <div className='fw-500 d-flex align-items-center justify-content-start'>
                        <h4 className='mb-0'>Workflows Table</h4>
                        <div className='ms-2'><Spinner size="sm" variant="secondary" animation="border" role="status" /></div>
                        <div className='ms-2'>Listening to workflows...</div>
                      </div>
                      <div className='text-muted'><small>List of live workflows for the selected environment</small></div>
                    </div>
                  </div>
                  <Table borderless className='workflow-table'>
                    <thead>
                      <tr>
                        {
                          columns.map(column =>
                            <th key={column.id} className="text-start" width={column.width}>{column.label}</th>
                          )
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        Object.values(flows).length > 0 ?
                          Object.values(flows)
                            .sort(getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, _idx) =>
                              <tr key={_idx}  onClick={()=>window.open(`../bpmn?flow_id=${row.flowId}`)}>
                                <td>{_idx + 1}</td>
                                <td>{row.flowName}</td>
                                <td>{row.subscription}</td>
                                <td>{row.linkId}</td>
                                <td>{row.flowType}</td>
                                <td>{buildFlowStatus(row.flowState)}</td>
                                <td>{row.lastUpdated}</td>
                              </tr>
                            )
                          :
                          <tr>
                            <td colSpan={columns.length} className='text-center'>No active workflows to display</td>
                          </tr>
                      }
                    </tbody>
                  </Table>
                </div>

                {/* pagination */}
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="d-flex align-items-center justify-content-start">
                    <ButtonGroup size="sm" className="me-2">
                      <Button variant="secondary" onClick={(_) => setPage(0)} disabled={pageOptions.length === 1}>First</Button>
                      <Button variant="secondary" onClick={(_) => setPage(page - 1)} disabled={page === 0} > Previous </Button>
                      <Button variant="secondary" onClick={(_) => setPage(page + 1)} disabled={page === pageOptions.length - 1}>Next</Button>
                      <Button variant="secondary" onClick={(_) => setPage(pageOptions.length - 1)} disabled={pageOptions.length === 1}>Last</Button>
                    </ButtonGroup>
                    <div className="me-2">Page{" "} <strong> {page + 1} of {pageOptions.length} </strong></div>
                  </div>
                  <div>
                    <Dropdown className="pagesize-dropdown">
                      <Dropdown.Toggle variant="secondary" id="page-size-dropdown" size="sm">Show {rowsPerPage}</Dropdown.Toggle>
                      <Dropdown.Menu>
                        {
                          pageSelectionOptions.map(pageSizeOption => (
                            <Dropdown.Item key={pageSizeOption} onClick={() => handleChangeRowsPerPage(pageSizeOption)}>
                              Show {pageSizeOption}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </Col>
            </Row>

          </Container>
        </Col>

      </Row>
    </Container>
  );
};

export default withAuthenticator(LiveWorkFlows, withAuthenticatorOptions);