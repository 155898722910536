import { useState, useEffect } from "react";

import { Row, Col, Form } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import ToggleButton from "react-bootstrap/ToggleButton";
import { UsageDataModal } from "./common/usageDataModal";
import { Amplify, API } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import LeftSideBar from "../common/LeftSideBar";
import EnvDetails from "../../env-details.json";
import * as Utils from "../../common/utils";
import { MdSearch } from "react-icons/md";
import "../../scss/common.scss";
import "../../scss/pc.scss";

import awsExports from "../../aws-exports";
import { AppMetricsView } from "./views/AppMetricsView";
import { ReportsHistoryView } from "./views/ReportsHistoryView";
import { OfferBundlesView } from "./views/OfferBundlesView";
import { Spinner } from "./common/Spinner";
import RecentActivity from "./common/RecentActivity";
import * as RBACUtils from '../../common/RBACUtils';
import { useNavigate } from "react-router-dom";

Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true,
};

const curEnv = Utils.getCurrentEnv();
const curStage = Utils.getCurrentStage();
const DEFAULT_VIEW = "Offer"; // "Apps" or "Offer" or "Reports"

const UsageData = ({ signOut, user }) => {
  const [subscription, setSubscription] = useState("");
  const [view, setView] = useState(DEFAULT_VIEW);
  const [disableSubBox, setDisableSubBox] = useState(false);
  const [spinner, setSpinner] = useState({ status: false, message: "" });
  const [modal, setModal] = useState({ show: false, content: {} });
  const [linkIdAppTypeMap, setLinkIdAppTypeMap] = useState([]);
  const [systemTypes, setSystemTypes] = useState([]);
  const [recentActivity, setRecentActivity] = useState([]);
  const [recentActivityList, setRecentActivityList] = useState([]);
  const [productCat, setProductCat] = useState({});
  const [customerName,setCustomerName]=useState('');
  const navigate = useNavigate();
  let params = new URLSearchParams(window.location.search);


  useEffect(() => {
    let querySubscription = params.get("subscription");
     if (RBACUtils.hasUsageDataAccess(user.username,querySubscription)&&querySubscription) {
        setSubscription(querySubscription);
        setDisableSubBox(true);
        setSpinner({ status: true, message: "Loading subscription..." });
        loadSubscription(querySubscription);
        setSpinner({ status: false, message: "" });
      }
      else navigate('/user/profile');
    
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const recentObj = {
      linkId: recentActivity.linkId,
      metricName: recentActivity.metricName,
      startDate: recentActivity.startDate,
      endDate: recentActivity.endDate,
      includeNonCdr: recentActivity.includeNonCdr,
      subscriptionId: recentActivity.subscriptionId,
      requestId: recentActivity.requestId,
    };
    setRecentActivityList([recentObj, ...recentActivityList]);
  }, [recentActivity]); // eslint-disable-line react-hooks/exhaustive-deps
  // UseEffect init
  const loadSubscription = async (subId) => {
    let params = {
      response: false,
      queryStringParameters: {
        stage: curStage,
        id: subId,
        region: user.pool.userPoolId.split("_")[0],
      },
    };
    const linkIdAppType = {},
      systemTypeList = [];
    const subRes = await API.get("SspBackendApi", "/subscription", params);
    for (const item of subRes.entitlement.Items) {
      if (item.applicationInstanceId in linkIdAppType) {
        linkIdAppType[item.applicationInstanceId].push(item.applicationType);
      } else {
        linkIdAppType[item.applicationInstanceId] = [item.applicationType];
      }
    }
    
    for (const item of subRes.sub.Items) {
      const {systemType,serviceType} = item;
      systemTypeList.push({systemType,serviceType});
    }
    getCustomerName(subRes);
  let paramsPC = {
    response: false,
    queryStringParameters: {
      stage: Utils.getCurrentStage(),
      path: "offermetrics",
      countryCode: null,
      region: user.pool.userPoolId.split("_")[0],
    },
  };
  const pcResponse = JSON.parse(
    await API.get("SspBackendApi", "/catalog", paramsPC)
  );
    setProductCat(JSON.parse(pcResponse.body));
    setSystemTypes(systemTypeList);
    console.log(linkIdAppType);
    setLinkIdAppTypeMap(linkIdAppType);
  };

  const getCustomerName=(apiRes)=>{
    const systemSpecificData = JSON.parse(apiRes.sub.Items[0].systemSpecificData);
    const customerNameParsed = systemSpecificData.customerName;
    setCustomerName(customerNameParsed);
  }
  // JSX Section
  return (
    <Container fluid className="p-2 mb-4">
      <Row className="m-0">
        {/* Left sidebar */}
        <Col md={2} className="l-nav">
          <LeftSideBar active="ud" username={user.username} />
        </Col>

        {/* Content */}
        <Col>
          <Container
            fluid
            data-bs-spy="scroll"
            data-bs-target="#navbar"
            data-bs-offset="0"
            tabIndex="0"
          >
            <Row className="d-row">
              {/* top heading bar */}
              <Row className="g-0 m-0 p-0">
                <Col className="title-wrap d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-start title-text">
                    <div>Usage Data</div>
                  </div>
                  <div className="d-flex align-items-center justify-content-end title-settings">
                    <div className="me-2">{user.attributes.email}</div>
                    <Dropdown size="sm" as={ButtonGroup} className="me-2">
                      <Button
                        variant="outline-secondary"
                        title={`Environment: ${curEnv}, Stage: ${curStage}`}
                      >
                        {
                          EnvDetails.filter((e) => e.envName === curEnv)[0]
                            .dispName
                        }
                      </Button>
                      <Dropdown.Toggle
                        split
                        variant="outline-secondary"
                        id={"dropdown-split-basic"}
                      />
                      <Dropdown.Menu variant="outline-secondary" align="end">
                        {EnvDetails.map(
                          (e) =>
                            Utils.includeEnvInDropdown(e.envName) && (
                              <Dropdown.Item
                                title={`Environment: ${e.envName}, Stage: ${e.stageName}`}
                                href={e.url}
                              >
                                {e.dispName}
                              </Dropdown.Item>
                            )
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      onClick={signOut}
                    >
                      Signout
                    </Button>
                  </div>
                </Col>
              </Row>

              <Spinner spinner={spinner} />

              {/* user options */}

              <Row
                className="g-0 px-0 pt-0 pb-0 mb-20 uo-area pc"
              >
                <Col>
                  <Row className="g-0 m-0 p-0 " style={{display:'flex' ,justifyContent:'space-between',width:'100%'}} >
                    <Col
                      className="border-0 rounded px-0 py-0 mx-1 bg-white" 
                    >
                      <div className="search-bar w-75 d-flex align-items-center justify-content-start">
                        <div className="text w-100">
                          <FloatingLabel label="Subscription">
                            <Form.Control
                              type="text"
                              size="md"
                              id="subId"
                              disabled={disableSubBox}
                              placeholder="Subscription Id"
                              value={subscription}
                            />
                          </FloatingLabel>
                        </div>
                        <div className="sbtn">
                          <Button
                            variant="secondary"
                            size="md"
                            disabled={true}
                            
                          >
                            <span className="i">
                              <MdSearch />
                            </span>
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col style={{display:'flex',justifyContent:'end'}}>
                      <ButtonGroup  style={{maxHeight:'30px'}}>
                        <ToggleButton
                         
                          size="sm"
                          id="details-view-switch-2"
                          type="checkbox"
                          variant="secondary"
                          checked={view === "Offer"}
                          onChange={(e) => {
                            setView("Offer");
                          }}
                        >
                          Billing Reports
                        </ToggleButton>
                        {/* disabled for now by eserero request VENUS-19342*/}
                        {/* <ToggleButton
                          className="max-h-16"
                          size="sm"
                          id="details-view-switch-1"
                          type="checkbox"
                          variant="secondary"
                          checked={view === "Apps"}
                          onChange={(e) => {
                            setView("Apps");
                          }}
                        >
                          All Metrics
                        </ToggleButton> */}

                        <ToggleButton
                          className="max-h-10"
                          size="sm"
                          id="details-view-switch-3"
                          type="checkbox"
                          variant="secondary"
                          checked={view === "Reports"}
                          onChange={(e) => {
                            setView("Reports");
                          }}
                        >
                          Reports History
                        </ToggleButton>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  <Row className="top-content"> <div className='cname d-flex align-items-center justify-content-start' title='Customer name'>
                      <div>{customerName}</div>
                    </div></Row>
                </Col>
              </Row>

              {/* views */}
              {view === "Apps" && (
                <AppMetricsView
                productCat={productCat}
                  user={user}
                  subscription={subscription}
                  setRecentActivity={setRecentActivity}
                  setModal={setModal}
                  linkIdAppTypeMap={linkIdAppTypeMap}
                />
              )}
              {view === "Offer" && (
                <OfferBundlesView
                  user={user}
                  productCat={productCat}
                  linkIdAppTypeMap={linkIdAppTypeMap}
                  setRecentActivity={setRecentActivity}
                  subId={subscription}
                  systemTypes={systemTypes}
                />
              )}
              {view === "Reports" && (
                <ReportsHistoryView user={user} setModal={setModal} />
              )}
            </Row>
          </Container>
        </Col>
        {/* <Col>
        {view !== "Reports" && 
          <RecentActivity recentActivity={recentActivityList} user={user}/>
          }
        </Col> */}

        <UsageDataModal
          removeFields={["expanded", "checked", "showDescription","show","isCdr","forBilling"]}
          title={modal.title}
          type={modal.type}
          content={modal.content}
          show={modal.show}
          setModal={setModal}
        />
      </Row>
    </Container>
  );
};

export default withAuthenticator(UsageData, withAuthenticatorOptions);