import { useState, useEffect, useMemo, useRef } from 'react';
import { useTable, usePagination, useSortBy, useExpanded, useGlobalFilter, useFilters } from 'react-table';
import { Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Collapse from 'react-bootstrap/Collapse';

import { GrPowerReset } from 'react-icons/gr';
import { MdTimelapse, MdOutlineCheckCircle, MdErrorOutline, MdOutlineCancel, MdOutlineEdit } from 'react-icons/md';
import { TiArrowUnsorted, TiArrowSortedUp, TiArrowSortedDown, TiPlus, TiFilter } from 'react-icons/ti';
import { BiImport } from 'react-icons/bi';
import { BsFillCloudFill } from 'react-icons/bs';
import Aiven from '../../common/ico/Aiven'
import Aws from '../../common/ico/Aws'
import Azure from '../../common/ico/Azure'
import Gcp from '../../common/ico/Gcp'
import MongoDb from '../../common/ico/MongoDb'
import { SiDatadog } from "react-icons/si";

import { Amplify, API } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import LeftSideBar from "../common/LeftSideBar";
import * as Utils from "../../common/utils";
import awsExports from '../../aws-exports';

import Multiselect from 'multiselect-react-dropdown';
import { useNavigate } from 'react-router-dom';
import Prods from "./data/prods.json";

import FinOpsModal from './common/FinOpsModal';

import '../../scss/envs.scss';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true
}

const STAGE = Utils.getCurrentStage();
const EMPTY_ARRAY = [];
const DEFAULT_STATUS = { show: false, type: '', progress: false, message: '' };
const DEFAULT_FILTER_DATA = {
  env_type: [],
  acc_vendor: [],
  acc_status: [],
  env_owner: [],
  env_budget: { min: 0, max: 0 },
  prod_name: [],
  expense_type: []
};
const COMMON_MULTISELECT_OPTIONS = {
  displayValue: 'name',
  showCheckbox: true,
  caseSensitiveSearch: true,
  className: 'filter-multiselect',
  avoidHighlightFirstOption: true,
  hidePlaceholder: true,
  showArrow: true
};

const Environments = ({ signOut, user }) => {

  const navigate = useNavigate();

  //#region states

  const [tableData, setTableData] = useState([]);
  const [status, setStatus] = useState(DEFAULT_STATUS);
  const [globalFilter, setGlobalFilterLocal] = useState('');
  const [showFilter, setShowFilter] = useState(true);
  const [adminMode, setAdminMode] = useState(false);
  const [showModal, setShowModal] = useState({ type: '', data: null });
  const selProd = useRef();
  const columns = useMemo(() => [
    {
      Header: () => (
        <div>
          <div title='Environment name'>Name</div>
          <div title='Environment type' className='fw-light text-muted'><small>Subscription name</small></div>
        </div>
      ),
      accessor: 'env_name',
      Cell: ({ value, row }) => {
        return <div>
          <div style={{ width: '95%' }} title={String(value)} className='text-truncate me-1'>{value ? value : '--'}</div>
          <div className=' d-flex align-items-center justify-content-start' title={`Expense type: ${row.original['expense_type']}`}>
            {/* <div className='expense-type me-1'>
              {row.original['expense_type']}
            </div> */}
            <div title={`Environment type:. ${String(row.original['env_type'] ? row.original['env_type'] : '--')}`} className='fw-light text-muted text-truncate'>
              <small>{row.original['env_type'] ? row.original['env_type'] : '--'}</small>
            </div>
          </div>
        </div>
      },
      width: '25%'
    },
    {
      Header: () => (
        <div>
          <div title='Environment accounts'>Accounts</div>
          <div title='Environment type' className='fw-light text-muted'><small>Account status/Acc vendor</small></div>
        </div>
      ),
      accessor: 'acc_name',
      Cell: ({ value, row }) => {
        return <div>
          <div className={`d-inline-flex align-items-center justify-content-start cl-acc-status mw-100 ${getAccountStatusClass(row.original['acc_status'])}`}>
            <div className='acc-status' title={String(row.original['acc_status'])}>{getAccountStatusIcon(row.original['acc_status'])}</div>
            <div className='acc-name text-truncate' title={`${String(value)}`} >{value ? value : '--'}</div>
            <div className='acc-vendor' title={row.original['acc_vendor']}>{getAccountVendorIcon(row.original['acc_vendor'])}</div>
          </div>
          {
            row.original['deployment_id'] &&
            <div className='text-break mt-1 fst-italic' title='Deployment Id'><small>{row.original['deployment_id']}</small></div>
          }
        </div>
      },
      width: '30%'
    },
    {
      Header: () => (
        <div>
          <div title='Environment owner'>Owner</div>
        </div>
      ),
      accessor: 'env_owner',
      Cell: ({ value, row }) => {
        return <div>
          <div className='d-flex align-items-center justify-content-start'>
            <div title={String(value)} className='text-truncate me-1'>{value ? value : '--'}</div>
          </div>
        </div>
      },
      filter: (rows, columnIds, filterValues) => {
        if (!filterValues || filterValues.length === 0) {
          return rows;
        }
        return rows.filter(row => filterValues.includes(row.values[columnIds[0]]));
      },
      width: '15%'
    },
    {
      Header: () => (
        <div>
          <div title='Environment budget'>Budget <small className='fw-normal fst-italic'>(Monthly)</small></div>
        </div>
      ),
      accessor: 'env_budget',
      Cell: ({ value, row }) => {
        return <div className='d-flex justify-content-start align-items-center'>
          {prepEnvBudget(row.original['expense_type'], value)}
        </div>
      },
      filter: (rows, columnIds, filterValue) => {
        return rows.filter(row =>
          parseInt(row.values[columnIds[0]]) >= parseInt(filterValue.min) &&
          parseInt(row.values[columnIds[0]]) <= parseInt(filterValue.max)
        );
      },
      width: '10%'
    },
    {
      Header: () => (
        <div>
          <div title='Product name'>Product</div>
        </div>
      ),
      accessor: 'prod_name',
      Cell: ({ value, row }) => {
        return <div>
          <div className='d-flex justify-content-between align-content-between'>
            <div className='d-flex align-items-center justify-content-start'>
              <div title={String(value)} className='text-truncate me-1'>{value ? value : '--'}</div>
            </div>
            <div className='d-flex justify-content-end align-items-center fs-5'>              
              <div className='me-1 remove-icon' onClick={() => {
                setShowModal({
                  type: 'remove',
                  data: {
                    env_id: row.original['env_id'],
                    env_name: row.original['env_name']
                  }
                })
              }} title='Remove environment'><MdOutlineCancel /></div>
            </div>
          </div>
        </div>
      },
      filter: (rows, columnIds, filterValues) => {
        if (!filterValues || filterValues.length === 0) {
          return rows;
        }
        return rows.filter(row => filterValues.includes(row.values[columnIds[0]]));
      },
      width: '15%'
    },
    {
      Header: 'Account vendor',
      accessor: 'acc_vendor',
      filter: (rows, columnIds, filterValues) => {
        if (!filterValues || filterValues.length === 0) {
          return rows;
        }
        return rows.filter(row => filterValues.includes(row.values[columnIds[0]]));
      }
    },
    {
      Header: 'Expense type',
      accessor: 'expense_type',
      filter: (rows, columnIds, filterValues) => {
        if (!filterValues || filterValues.length === 0) {
          return rows;
        }
        return rows.filter(row => filterValues.includes(row.values[columnIds[0]]));
      }
    },
    {
      Header: 'Account status',
      accessor: 'acc_status',
      filter: (rows, columnIds, filterValues) => {
        if (!filterValues || filterValues.length === 0) {
          return rows;
        }
        return rows.filter(row => filterValues.includes(row.values[columnIds[0]]));
      }
    },
    {
      Header: 'Environment type',
      accessor: 'env_type',
      filter: (rows, columnIds, filterValues) => {
        if (!filterValues || filterValues.length === 0) {
          return rows;
        }
        return rows.filter(row => filterValues.includes(row.values[columnIds[0]]));
      }
    }
  ], [tableData]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const filterData = useMemo(() => {
    if (tableData.length === 0) return DEFAULT_FILTER_DATA;
    const types = new Set(), vendors = new Set(), statuses = new Set(), owners = new Set(), products = new Set(), expenses = new Set();
    let min, max = 0;
    tableData.forEach(row => {
      if (!Utils.caseInsensitiveHas(types, row['env_type'])) types.add(row['env_type']);
      if (!Utils.caseInsensitiveHas(vendors, row['acc_vendor'])) vendors.add(row['acc_vendor']);
      if (!Utils.caseInsensitiveHas(statuses, row['acc_status'])) statuses.add(row['acc_status']);
      if (!Utils.caseInsensitiveHas(owners, row['env_owner'])) owners.add(row['env_owner']);
      if (!products.has(row['prod_name'])) products.add(row['prod_name']);
      if (!Utils.caseInsensitiveHas(expenses, row['expense_type'])) expenses.add(row['expense_type']);
      min = Math.min(row['env_budget'], min);
      max = Math.max(row['env_budget'], max);
    });
    if (min === 0 || max === 0 || min === max) {
      min = 0;
      max = 10000;
    }
    const filter_data = {
      ...DEFAULT_FILTER_DATA,
      env_type: [...types.values()].sort(),
      acc_vendor: [...vendors.values()].sort(),
      acc_status: [...statuses.values()].sort(),
      env_owner: [...owners.values()].sort(),
      prod_name: [...products.values()].sort(),
      env_budget: { min: min, max: max },
      expense_type: [...expenses.values()].sort(),
    };
    return filter_data;
  }, [tableData]); // eslint-disable-line react-hooks/exhaustive-deps

  const multiSelRefs = useRef({});

  //#endregion

  //#region util functions 

  const formatBudget = (val) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(val);
  };

  const prepEnvBudget = (etype, budget) => {
    switch (etype) {
      case 'COGS':
      case 'ics':
        return <div className='pbc' title={`COGS budget: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(budget)}`}>
          <div><small>{etype}</small></div>
          <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(budget)}</div>
        </div>
      case 'Expense':
        return <div className='pbe' title={`Expense budget: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(budget)}`}>
          <div><small>{etype}</small></div>
          <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(budget)}</div>
        </div>
      default:
        return <div className='pbo' title={`Other budget: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(budget)}`}>
          <div><small>{etype}</small></div>
          <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(budget)}</div>
        </div>
    }
  };

  //#endregion 

  //#region data handling

  // const fetchEnvironments = async (adminMode = false) => {
  //   let retries = 1;
  //   setStatus({ show: true, type: 'progress', progress: true, message: 'Fetching cloud environments ...' });
  //   let params = {
  //     queryStringParameters: {
  //       stage: STAGE,
  //       region: user.pool.userPoolId.split('_')[0],
  //       owner: adminMode ? '' : user.attributes.email
  //     }
  //   };
  //   while (retries <= Utils.RETRY_COUNT) {
  //     try {
  //       const response = await API.get('SspBackendApi', '/environments', params);
  //       if (response?.data && response.status === 'success') {
  //         setTableData(response.data);
  //         setStatus(DEFAULT_STATUS);
  //       } else {
  //         setStatus({ show: true, type: 'error', progress: false, message: 'Error occured while fetching the Cloud environments.' });
  //       }
  //       return;
  //     } catch (err) {
  //       let error = JSON.parse(JSON.stringify(err));
  //       if (parseInt(error.status) === 504) {
  //         retries++;
  //         if (retries <= Utils.RETRY_COUNT) {
  //           setStatus({ show: true, type: 'error', progress: true, message: 'Timed out fetching cloud environments, retrying...' });
  //           await new Promise((resolve) => setTimeout(resolve, 1000));
  //         } else {
  //           setStatus({ show: true, type: 'error', progress: false, message: 'Retry limit reached, please reload the page.' });
  //           return;
  //         }
  //       } else {
  //         console.error('Error occured while fetching the cloud environments.', err);
  //         setStatus({ show: true, type: 'error', progress: false, message: 'Error occured while fetching the Cloud environments.' });
  //         return;
  //       }
  //     }
  //   }

  // };

  const baseApiFn = async (amplifyAPI, httpMethod, url, params, initFn, successFn, errorFn) => {
    if (!amplifyAPI || !httpMethod || !url || !params || !initFn || !successFn || !errorFn) {
      console.error(`Missing one or more required parameters to the function. amplifyAPI = ${amplifyAPI}, httpMethod = ${httpMethod}, url = ${url}, params = ${params}, initFn = ${initFn ? 'prenset' : 'absent'}, successFn = ${successFn ? 'prenset' : 'absent'}, errorFn = ${errorFn ? 'prenset' : 'absent'}`)
      return;
    }
    let retries = 1, response = null;
    await initFn();
    while (retries <= Utils.RETRY_COUNT) {
      try {
        switch (httpMethod) {
          case 'GET':
            response = await API.get(amplifyAPI, url, params);
            break;
          case 'POST':
            response = await API.post(amplifyAPI, url, params);
            break;
          case 'PUT':
            response = await API.put(amplifyAPI, url, params);
            break;
          case 'DELETE':
            response = await API.del(amplifyAPI, url, params);
            break;
          default:
            console.error(`Unsupported http method`);
            return;
        }
        await successFn(response);
        return;
      } catch (err) {
        let error = JSON.parse(JSON.stringify(err));
        if (parseInt(error.status) === 504) {
          retries++;
          if (retries <= Utils.RETRY_COUNT) {
            await errorFn(`Request timed-out, retrying...`, true)
            await new Promise((resolve) => setTimeout(resolve, 1000));
          } else {
            errorFn(`Retry limit reached, please reload the page.`);
            return;
          }
        } else {
          await errorFn(`Error occurred while performing operation: ${httpMethod} ${url}`);
          return;
        }
      }
    }
  };

  const getRecords = async (adminMode = false) => {
    const params = {
      queryStringParameters: {
        stage: STAGE,
        region: user.pool.userPoolId.split('_')[0],
        owner: adminMode ? '' : user.attributes.email
      }
    };
    const initFn = () => setStatus({ show: true, type: 'progress', progress: true, message: 'Fetching environment records...' });
    const successFn = (res) => {
      if (res?.data && res.status === 'success') {
        setTableData(res.data);
        setStatus(DEFAULT_STATUS);
      } else {
        setStatus({ show: true, type: 'error', progress: false, message: 'Error occured while fetching the environments.' });
      }
    };
    const errorFn = (msg, progress = false) => setStatus({ show: true, type: 'error', progress: progress, message: msg });
    await baseApiFn('SspBackendApi', 'GET', '/environments', params, initFn, successFn, errorFn);
  };

  const addRecord = async (payload) => {
    const params = {
      body: {
        ...payload,
        stage: STAGE,
        region: user.pool.userPoolId.split('_')[0]
      },
      headers: {
        "Content-Type": "application/json"
      }
    };
    const initFn = () => setStatus({ show: true, type: 'progress', progress: true, message: 'Adding product record...' });
    const successFn = (res) => setStatus({ show: true, type: res.message ? 'success' : 'error', progress: false, message: res.message });
    const errorFn = (msg, progress = false) => setStatus({ show: true, type: 'error', progress: progress, message: msg });
    await baseApiFn('SspBackendApi', 'POST', '/environments', params, initFn, successFn, errorFn);
  }

  const updateRecord = async (payload) => {
    const params = {
      body: {
        ...payload,
        stage: STAGE,
        region: user.pool.userPoolId.split('_')[0]
      },
      headers: {
        "Content-Type": "application/json"
      }
    };
    const initFn = () => setStatus({ show: true, type: 'progress', progress: true, message: 'Updating product record...' });
    const successFn = (res) => setStatus({ show: true, type: res.message ? 'success' : 'error', progress: false, message: res.message });
    const errorFn = (msg, progress = false) => setStatus({ show: true, type: 'error', progress: progress, message: msg });
    await baseApiFn('SspBackendApi', 'PUT', `/environments/${showModal['data'].env_id}`, params, initFn, successFn, errorFn);
  }

  const removeRecord = async () => {
    const params = {
      queryStringParameters: {
        stage: STAGE,
        region: user.pool.userPoolId.split('_')[0]
      }
    };
    const initFn = () => setStatus({ show: true, type: 'progress', progress: true, message: 'Removing product record...' });
    const successFn = async (res) => {
      setStatus({ show: true, type: res.message ? 'success' : 'error', progress: false, message: res.message });
      await getRecords();
    };
    const errorFn = (msg, progress = false) => setStatus({ show: true, type: 'error', progress: progress, message: msg });
    await baseApiFn('SspBackendApi', 'DELETE', `/products/${showModal['data'].env_id}`, params, initFn, successFn, errorFn);
  };

  //#endregion

  //#region react-table

  const handleSortIconClick = (column) => {
    column.toggleSortBy();
  };

  const getAccountStatusIcon = (status) => {
    if (!status) return '--';
    switch (status.toUpperCase()) {
      case 'INITIAL':
        return <MdTimelapse className='text-warning' />;
      case 'EXISTING':
        return <MdOutlineCheckCircle className='text-success' />;
      case 'ERROR':
        return <MdErrorOutline className='text-danger' />;
      default:
        return <MdOutlineCheckCircle className='text-success' />;
    }
  }

  const getAccountVendorIcon = (vendor) => {
    if (!vendor) return '--';
    switch (vendor.toUpperCase()) {
      case 'AWS':
        return <Aws title={vendor} />;
      case 'AZURE':
        return <Azure title={vendor} />;
      case 'GCP - AVAYA':
        return <Gcp title={vendor} />;
      case 'GCP - CANADA':
        return <Gcp title={vendor} />;
      case 'AIVEN':
        return <Aiven title={vendor} />;
      case 'DATADOG':
        return <SiDatadog title={vendor} style={{ color: 'purple', width: '20', height: '20' }} />;
      case 'MONGODB':
        return <MongoDb title={vendor} />;
      default:
        return <BsFillCloudFill title={vendor} />;
    }
  }

  const getAccountStatusClass = (status) => {
    if (!status) return '';
    switch (status.toUpperCase()) {
      case 'INITIAL':
        return 'warn';
      case 'EXISTING':
        return 'success';
      case 'ERROR':
        return 'danger';
      default:
        return 'success';
    }
  }

  const handleAdminModeUpdate = async (value) => {
    setAdminMode(value);
    await getRecords(value);
  };

  const handleGlobalFilterChange = (value) => {
    setGlobalFilterLocal(value);
    setGlobalFilter(value); // Set the global filter value
  };

  const handleFilterChange = (k, v) => {
    switch (k) {
      case 'env_budget':
        if (v) setFilter(k, v);
        break;
      default:
        setFilter(k, v ? v.split(',') : undefined);
    }
  };

  const handleFilterReset = () => {
    filters.forEach(k => setFilter(k.id, undefined));
    Object.keys(multiSelRefs.current).forEach(k => multiSelRefs.current[`${k}`]?.resetSelectedValues());
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, filters },
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
    setGlobalFilter,
    setFilter,
    flatRows
  } = useTable(
    {
      columns,
      data: tableData || EMPTY_ARRAY,
      initialState: {
        hiddenColumns: adminMode ? ['acc_vendor', 'acc_status', 'expense_type', 'env_type'] : ['acc_vendor', 'acc_status', 'expense_type', 'env_type', 'env_owner'],
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  //#endregion

  useEffect(() => {
    getRecords();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (

    <Container fluid className="p-2 mb-4" >
      <Row className='m-0'>

        {/* Left sidebar */}
        <Col md={2} className='l-nav'>
          <LeftSideBar active='en' username={user.username} />
        </Col>

        {/* Content */}
        <Col md={10}>
          <Container fluid data-bs-spy="scroll" data-bs-target='#navbar' data-bs-offset="0" tabIndex="0" className='scrollspy-example'>

            <Row>
              <Col className='title-wrap d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center justify-content-start title-text'>
                  <div>Cloud Environments</div>
                </div>
                <div className='d-flex align-items-center justify-content-end title-settings'>
                  <div className='me-2'>{user.attributes.email}</div>
                  <Button size="sm" variant="outline-secondary" onClick={signOut}>Signout</Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className='d-flex align-items-center justify-content-between'>
                  <div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <div className='fs-4 fw-bold'>Environments</div>
                      {
                        status['show'] && status['progress'] &&
                        <Spinner animation="border" size="sm" className='ms-2 pt-1' />
                      }
                      {
                        status['show'] && status['message'] &&
                        <div className={`ms-2 pt-1 ${status['type'] === 'success' ? 'text-success' : status['type'] === 'error' ? 'text-danger' : 'text-secondary'}`}>{status['message']}</div>
                      }
                    </div>
                    <small>Cloud environment is an entity which holds cloud vendor account and budget information</small>
                  </div>
                  <div className='d-flex align-items-center justify-content-end'>
                    <div className='me-2'>
                      <Form.Group className='d-flex justify-content-end align-items-center admin-mode-check'>
                        <Form.Check type="checkbox" onChange={(e) => handleAdminModeUpdate(e.target.checked)} checked={adminMode} className="internal-app-checkbox" name='hide-ppu-offers' id='admin-mode' />
                        <Form.Label className='m-0 ms-1'><small>Admin Mode</small></Form.Label>
                      </Form.Group>
                    </div>
                    <div className='d-flex justify-content-start align-items-center'>
                      <Form.Control id='gfi-id' size='sm' type="text" value={globalFilter} className='search-field' placeholder="Enter text to search environments" onChange={(e) => handleGlobalFilterChange(e.target.value)} />
                      <Button size='sm' variant='secondary' className='search-reset' disabled={!globalFilter} title='Reset search' onClick={() => handleGlobalFilterChange('')}><GrPowerReset /></Button>
                    </div>
                    <div className='d-flex justify-content-end align-items-center px-2 py-1 rounded text-light ms-2 create-env' style={{ cursor: 'pointer' }} onClick={() => setShowModal({ type: 'add' })}>
                      <div><BiImport /></div>
                      <div className='text ms-1'>Import Environment</div>
                    </div>
                    <div className='d-flex justify-content-end align-items-center px-2 py-1 rounded text-light ms-2 create-env' style={{ cursor: 'pointer' }} onClick={() => navigate('/environments/create')}>
                      <div><TiPlus /></div>
                      <div className='text ms-1'>Create Environment</div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <div className='mt-2'>
              {
                JSON.stringify(filterData)
              }
            </div>
            <div className='mt-2'>
              {
                JSON.stringify(filters)
              }
            </div>
            <div className='mt-2'>
              {
                JSON.stringify(flatRows.length)
              }
            </div> */}
            <Row className='mt-1'>
              <Col>
                {/* table filters */}
                <div className='filter-section-title'>
                  <div>
                    <span className='filter-icon pe-1'><TiFilter /></span><span>Filter</span> <small className='ms-1 toggle' onClick={() => setShowFilter(!showFilter)} >{showFilter ? 'Hide' : 'Show'}</small>
                  </div>
                  <Collapse in={showFilter}>
                    <div>
                      <div className="filter-section" id="filter-section">
                        <div className='d-flex align-items-top justify-content-start'>
                          <div className='me-1' style={{ width: adminMode ? '20%' : '25%' }}>
                            <Form.Group>
                              <Form.Label>Expense type</Form.Label>
                              <div>
                                <Multiselect
                                  {...COMMON_MULTISELECT_OPTIONS}
                                  onSelect={(selectedList, selectedItem) => { handleFilterChange('expense_type', selectedList.map(e => e.id).join(',')) }}
                                  onRemove={(selectedList, removedItem) => { handleFilterChange('expense_type', selectedList.map(e => e.id).join(',')) }}
                                  options={filterData['expense_type'].map(s => { return { name: s, id: s } })}
                                  selectedValues={filters.filter(f => f.id === 'expense_type')?.['value']?.map(s => { return { name: s, id: s } }) || null}
                                  placeholder={`Choose from ${filterData['expense_type'].length} expense types`}
                                  ref={ref => multiSelRefs.current['expense_type'] = ref}
                                />
                              </div>
                            </Form.Group>
                          </div>
                          <div className='me-1' style={{ width: adminMode ? '20%' : '25%' }}>
                            <Form.Group>
                              <Form.Label>Type</Form.Label>
                              <div>
                                <Multiselect
                                  {...COMMON_MULTISELECT_OPTIONS}
                                  onSelect={(selectedList, selectedItem) => { handleFilterChange('env_type', selectedList.map(e => e.id).join(',')) }}
                                  onRemove={(selectedList, removedItem) => { handleFilterChange('env_type', selectedList.map(e => e.id).join(',')) }}
                                  options={filterData['env_type'].map(s => { return { name: s, id: s } })}
                                  selectedValues={filters.filter(f => f.id === 'env_type')?.['value']?.map(s => { return { name: s, id: s } }) || null}
                                  placeholder={`Choose from ${filterData['env_type'].length} types`}
                                  ref={ref => multiSelRefs.current['env_type'] = ref}
                                />
                              </div>
                            </Form.Group>
                          </div>
                          <div className='me-1' style={{ width: adminMode ? '20%' : '25%' }}>
                            <Form.Group>
                              <Form.Label>Vendors</Form.Label>
                              <div>
                                <Multiselect
                                  {...COMMON_MULTISELECT_OPTIONS}
                                  onSelect={(selectedList, selectedItem) => { handleFilterChange('acc_vendor', selectedList.map(e => e.id).join(',')) }}
                                  onRemove={(selectedList, removedItem) => { handleFilterChange('acc_vendor', selectedList.map(e => e.id).join(',')) }}
                                  options={filterData['acc_vendor'].map(s => { return { name: s, id: s } })}
                                  selectedValues={filters.filter(f => f.id === 'acc_vendor')?.['value']?.map(s => { return { name: s, id: s } }) || null}
                                  placeholder={`Choose from ${filterData['acc_vendor'].length} vendors`}
                                  ref={ref => multiSelRefs.current['acc_vendor'] = ref}
                                />
                              </div>
                            </Form.Group>
                          </div>
                          <div className='me-1' style={{ width: adminMode ? '20%' : '25%' }}>
                            <Form.Group>
                              <Form.Label>Status</Form.Label>
                              <div>
                                <Multiselect
                                  {...COMMON_MULTISELECT_OPTIONS}
                                  onSelect={(selectedList, selectedItem) => { handleFilterChange('acc_status', selectedList.map(e => e.id).join(',')) }}
                                  onRemove={(selectedList, removedItem) => { handleFilterChange('acc_status', selectedList.map(e => e.id).join(',')) }}
                                  options={filterData['acc_status'].map(s => { return { name: s, id: s } })}
                                  selectedValues={null}
                                  placeholder={`Choose from ${filterData['acc_status'].length} statuses`}
                                  ref={ref => multiSelRefs.current['acc_status'] = ref}
                                />
                              </div>
                            </Form.Group>
                          </div>
                          {
                            adminMode &&
                            <div className='me-1' style={{ width: '20%' }}>
                              <Form.Group>
                                <Form.Label>Owners</Form.Label>
                                <div>
                                  <Multiselect
                                    {...COMMON_MULTISELECT_OPTIONS}
                                    onSelect={(selectedList, selectedItem) => { handleFilterChange('env_owner', selectedList.map(e => e.id).join(',')) }}
                                    onRemove={(selectedList, removedItem) => { handleFilterChange('env_owner', selectedList.map(e => e.id).join(',')) }}
                                    options={filterData['env_owner'].map(s => { return { name: s, id: s } })}
                                    selectedValues={filters.filter(f => f.id === 'env_owner')?.['value']?.map(s => { return { name: s, id: s } }) || null}
                                    placeholder={`Choose from ${filterData['env_owner'].length} owners`}
                                    ref={ref => multiSelRefs.current['env_owner'] = ref}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          }
                        </div>
                        <div className='d-flex align-items-top justify-content-between mt-3'>
                          <div className='d-flex align-items-top justify-content-start w-100'>
                            <div className='me-1' style={{ width: adminMode ? '20%' : '25%' }}>
                              <Form.Group>
                                <Form.Label>Product</Form.Label>
                                <div>
                                  <Multiselect
                                    {...COMMON_MULTISELECT_OPTIONS}
                                    onSelect={(selectedList, selectedItem) => { handleFilterChange('prod_name', selectedList.map(e => e.id).join(',')) }}
                                    onRemove={(selectedList, removedItem) => { handleFilterChange('prod_name', selectedList.map(e => e.id).join(',')) }}
                                    options={filterData['prod_name'].map(s => { return { name: s, id: s } })}
                                    selectedValues={filters.filter(f => f.id === 'prod_name')?.['value']?.map(s => { return { name: s, id: s } }) || null}
                                    placeholder={`Choose from ${filterData['prod_name'].length} products`}
                                    ref={ref => multiSelRefs.current['prod_name'] = ref}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                            <div className='me-1' style={{ width: adminMode ? '20%' : '25%' }}>
                              <Form.Group>
                                <Form.Label>Budget (<small className='fst-italic'>{formatBudget(document.getElementById('env-budget-min')?.value || 0)} - {formatBudget(document.getElementById('env-budget-max')?.value || 0)}</small>)</Form.Label>
                                <div className='d-flex align-items-center justify-content-start'>
                                  <div style={{ width: '27%' }}>
                                    <Form.Control type='number' id='env-budget-min' value={filters.filter(f => f.id === 'env_budget')?.[0]?.['value']?.['min'] || 0} size='sm' onChange={e => handleFilterChange('env_budget', { min: e.target.value, max: document.getElementById('env-budget-max').value })} min={filterData['env_budget']?.['min'] || 0} max={filterData['env_budget']?.['max'] || 10000} step={10} />
                                  </div>
                                  <div className='mx-1'>-</div>
                                  <div style={{ width: '32%' }}>
                                    <Form.Control type='number' id='env-budget-max' value={filters.filter(f => f.id === 'env_budget')?.[0]?.['value']?.['max'] || 10000} size='sm' onChange={e => handleFilterChange('env_budget', { min: document.getElementById('env-budget-min').value, max: e.target.value })} min={filterData['env_budget']?.['min'] || 0} max={filterData['env_budget']?.['max'] || 10000} step={10} />
                                  </div>
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                          <div className='d-flex align-items-center justify-content-end mt-3'>
                            <div>
                              <Button size='sm' className='pt-1' variant="secondary" onClick={() => handleFilterReset()}>Reset</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
                {/* table */}
                <div className={status['progress'] ? 'loading' : ''}>
                  <Table {...getTableProps()} className='env-table'>
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className='align-middle'>
                          {
                            headerGroup.headers.map((column, index) => (
                              <th key={index} {...column.getHeaderProps({ className: column.collapse ? 'collapse px-1' : 'px-1', style: { width: column.width } })}>
                                <div className="d-flex justify-content-between" onClick={() => { if (!column.disableSortBy) handleSortIconClick(column) }} style={{ cursor: 'pointer' }}>
                                  <div>{column.render('Header')}</div>
                                  <div>
                                    {
                                      !column.disableSortBy &&
                                      <Button variant="link" className="p-0" title={`Sort by ${column.id}`} style={{ color: '#666' }}>
                                        <span>
                                          {column.isSorted ? (
                                            column.isSortedDesc ? (
                                              <TiArrowSortedDown />
                                            ) : (
                                              <TiArrowSortedUp />
                                            )
                                          ) : (
                                            <>
                                              <TiArrowUnsorted className="text-muted" />
                                            </>
                                          )}
                                        </span>
                                      </Button>
                                    }
                                  </div>
                                </div>
                              </th>
                            ))
                          }
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {
                        page.map(row => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} className='align-middle'>
                              {
                                row.cells.map(cell => (
                                  <td {...cell.getCellProps({ className: cell.column.collapse ? 'collapse px-1' : 'px-1', style: { width: cell.column.width } })} > {cell.render('Cell')} </td>
                                ))
                              }
                            </tr>
                          );
                        })
                      }
                      {
                        page.length === 0 &&
                        <tr>
                          <td colSpan={adminMode ? 5 : 4} className='text-center'>
                            No records found. By default only environments owned by you are shown. To view all environments, enable admin mode.
                          </td>
                        </tr>
                      }
                    </tbody>
                  </Table>

                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="d-flex align-items-center justify-content-start">
                      <ButtonGroup size="sm" className='me-2'>
                        <Button variant="secondary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>First</Button>
                        <Button variant="secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Button>
                        <Button variant="secondary" onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
                        <Button variant="secondary" onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>Last</Button>
                      </ButtonGroup>
                      <div className='me-2'> Page{' '} <strong> {pageIndex + 1} of {pageOptions.length} </strong> <small>(Total rows: {flatRows.length})</small></div>
                    </div>
                    <div>
                      <Dropdown className='pagesize-dropdown'>
                        <Dropdown.Toggle variant="secondary" id="page-size-dropdown" size="sm">Show {pageSize}</Dropdown.Toggle>
                        <Dropdown.Menu>
                          {[10, 25, 50].map(pageSizeOption => (
                            <Dropdown.Item key={pageSizeOption} active={pageSizeOption === pageSize} onClick={() => setPageSize(pageSizeOption)}>
                              Show {pageSizeOption}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>

                </div>
              </Col>
            </Row>

            <div>
              <FinOpsModal
                show={showModal['type'] === 'add'}
                size='lg'
                type='form'
                data={
                  [
                    {
                      type: 'header',
                      title: 'Budget information',
                      row: 0,
                      col: 12
                    },
                    {
                      id: 'prod_id',
                      title: 'Choose Product',
                      mutable: true,
                      type: 'select',
                      options: Prods.sort((p1, p2) => p1.prod_name.localeCompare(p2.prod_name)).map(p => ({ d: p.prod_name, v: p.prod_id })),
                      placeholder: 'Choose Product',
                      userMessageFn: (val) => {
                        selProd.current = val;
                        return `Availabe budget: ${formatBudget(parseFloat(Prods.filter(p => p.prod_id === val)[0]['prod_budget']) - parseFloat(Prods.filter(p => p.prod_id === val)[0]['budget_utilized']))}`
                      },
                      row: 1,
                      col: 6
                    },
                    {
                      id: 'env_budget',
                      title: 'Enter budget (Monthly)',
                      mutable: true,
                      type: 'number',
                      placeholder: 'Enter budget for Environment',
                      validationFn: (val) => {
                        if (!val || parseFloat(val) < 0 || parseFloat(val) > (parseFloat(Prods.filter(p => p.prod_id === selProd.current)[0]['prod_budget']) - parseFloat(Prods.filter(p => p.prod_id === selProd.current)[0]['budget_utilized']))) return 'Enter valid budget value';
                      },
                      userMessageFn: (val) => {
                        return `Desired budget: ${formatBudget(val)}`
                      },
                      row: 1,
                      col: 6
                    },
                    {
                      type: 'header',
                      title: 'Environment information',
                      row: 2,
                      col: 12
                    },
                    {
                      id: 'env_name',
                      title: 'Enter name',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter name of Environment',
                      validationFn: (val) => {
                        if (!val || val.length === 0) return 'Environment name can not be empty'
                      },
                      row: 3,
                      col: 6
                    },
                    {
                      id: 'env_type',
                      title: 'Type',
                      mutable: true,
                      type: 'select',
                      options: [
                        { d: "Development", v: "Development" },
                        { d: "Staging", v: "Staging" },
                        { d: "Performance", v: "Performance" },
                        { d: "Testing", v: "Testing" },
                        { d: "Production", v: "Production" },

                      ],
                      placeholder: 'Choose Environment type',
                      row: 3,
                      col: 6
                    },
                    {
                      id: 'env_owner',
                      title: 'Owner email',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter owner email',
                      validationFn: (val) => {
                        if (!val || val.length === 0) return `Owner email can't be empty`
                      },
                      row: 4,
                      col: 6
                    },
                    {
                      id: 'expense_type',
                      title: 'Expense type',
                      mutable: true,
                      type: 'select',
                      options: [
                        { d: "Expense", v: "Expense" },
                        { d: "COGS", v: "COGS" },
                        { d: "Credit", v: "Credit" },
                        { d: "ICS", v: "ics" },
                      ],
                      placeholder: 'Choose Expense type',
                      row: 4,
                      col: 6
                    },
                    {
                      id: 'description',
                      title: 'Description',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter Environment description',
                      row: 5,
                      col: 8
                    },
                    {
                      id: 'tags',
                      title: 'Tags',
                      mutable: false,
                      type: 'text',
                      placeholder: 'Enter Environment Tags',
                      initValue: '{}',
                      row: 5,
                      col: 4
                    },
                    {
                      type: 'header',
                      title: 'Account information',
                      row: 6,
                      col: 12
                    },
                    {
                      id: 'acc_name',
                      title: 'Account name',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter cloud account name',
                      validationFn: (val) => {
                        if (!val) return 'Cloud account name can not be empty'
                      },
                      row: 7,
                      col: 6
                    },
                    {
                      id: 'acc_vendor',
                      title: 'Account vendor',
                      mutable: true,
                      type: 'select',
                      options: [
                        { d: "AIVEN", v: "AIVEN" },
                        { d: "AWS", v: "AWS" },
                        { d: "Azure", v: "Azure" },
                        { d: "GCP - Avaya", v: "GCP - Avaya" },
                        { d: "GCP - Canada", v: "GCP - Canada" },
                        { d: "DATADOG", v: "DATADOG" },
                      ],
                      placeholder: 'Seelct cloud account vendor',
                      row: 7,
                      col: 6
                    },
                    {
                      id: 'acc_region',
                      title: 'Account region',
                      mutable: true,
                      type: 'text',
                      placeholder: 'Enter cloud account region',
                      row: 8,
                      col: 6
                    },
                  ]
                }
                onExit={() => setShowModal('')}
                onSubmit={async (payload) => {
                  setShowModal('');
                  await addRecord(payload);
                  await getRecords(adminMode);
                }}
                title='Import Environment/Account'
                titleDescription='Import existing Environment/Cloud Account into the system'
              />
            </div>
            <div>
              <FinOpsModal
                show={showModal['type'] === 'edit'}
                size='lg'
                type='form'
                data={
                  [
                    {
                      type: 'header',
                      title: 'Budget information',
                      row: 1,
                      col: 12
                    },
                    {
                      id: 'prod_id',
                      title: 'Choose Product',
                      mutable: true,
                      type: 'select',
                      options: Prods.sort((p1, p2) => p1.prod_name.localeCompare(p2.prod_name)).map(p => ({ d: p.prod_name, v: p.prod_id })),
                      placeholder: 'Choose Product',
                      initValue: showModal['data']?.prod_id || '',
                      userMessageFn: (val) => {
                        return `Selected Product Id: ${val}`
                      },
                      row: 2,
                      col: 6
                    },
                    {
                      id: 'env_budget',
                      title: 'Enter budget (Monthly)',
                      mutable: true,
                      type: 'number',
                      placeholder: 'Enter budget for Envvironment',
                      initValue: showModal['data']?.env_budget || '',
                      validationFn: (val) => {
                        if (!val || parseFloat(val) < 0) return 'Enter valid budget value'
                      },
                      row: 2,
                      col: 6
                    },
                    {
                      type: 'header',
                      title: 'Environment information',
                      row: 3,
                      col: 12
                    },
                    {
                      id: 'env_name',
                      title: 'Enter name',
                      mutable: false,
                      type: 'text',
                      placeholder: 'Enter name of Environment',
                      initValue: showModal['data']?.env_name || '',
                      validationFn: (val) => {
                        if (!val || val.length === 0) return 'Environment name can not be empty'
                      },
                      row: 4,
                      col: 6
                    },
                    {
                      id: 'env_type',
                      title: 'Type',
                      mutable: true,
                      type: 'select',
                      initValue: showModal['data']?.env_type || '',
                      options: [
                        { d: "Development", v: "Development" },
                        { d: "Staging", v: "Staging" },
                        { d: "Performance", v: "Performance" },
                        { d: "Testing", v: "Testing" },
                        { d: "Production", v: "Production" },

                      ],
                      placeholder: 'Choose Environment type',
                      row: 4,
                      col: 6
                    },
                    {
                      id: 'env_owner',
                      title: 'Owner email',
                      mutable: true,
                      type: 'text',
                      initValue: showModal['data']?.env_owner || '',
                      placeholder: 'Enter owner email',
                      validationFn: (val) => {
                        if (!val || val.length === 0) return `Owner email can't be empty`
                      },
                      row: 5,
                      col: 6
                    },
                    {
                      id: 'expense_type',
                      title: 'Expense type',
                      mutable: true,
                      type: 'select',
                      initValue: showModal['data']?.expense_type || '',
                      options: [
                        { d: "Expense", v: "Expense" },
                        { d: "COGS", v: "COGS" },
                        { d: "Credit", v: "Credit" },
                        { d: "ICS", v: "ics" },
                      ],
                      placeholder: 'Choose Expense type',
                      row: 5,
                      col: 6
                    },
                    {
                      id: 'description',
                      title: 'Description',
                      mutable: true,
                      type: 'text',
                      initValue: showModal['data']?.env_desc || '',
                      placeholder: 'Enter Environment description',
                      row: 6,
                      col: 8
                    },
                    {
                      id: 'tags',
                      title: 'Tags',
                      mutable: false,
                      type: 'text',
                      placeholder: 'Enter Environment Tags',
                      initValue: '{}',
                      row: 6,
                      col: 4
                    },
                    {
                      type: 'header',
                      title: 'Account information',
                      row: 7,
                      col: 12
                    },
                    {
                      id: 'acc_name',
                      title: 'Account name',
                      mutable: false,
                      type: 'text',
                      placeholder: 'Enter cloud account name',
                      initValue: showModal['data']?.acc_name || '',
                      validationFn: (val) => {
                        if (!val) return 'Cloud account name can not be empty'
                      },
                      row: 8,
                      col: 6
                    },
                    {
                      id: 'acc_vendor',
                      title: 'Account vendor',
                      mutable: true,
                      initValue: showModal['data']?.acc_vendor || '',
                      type: 'select',
                      options: [
                        { d: "AIVEN", v: "AIVEN" },
                        { d: "AWS", v: "AWS" },
                        { d: "Azure", v: "Azure" },
                        { d: "GCP - Avaya", v: "GCP - Avaya" },
                        { d: "GCP - Canada", v: "GCP - Canada" },
                        { d: "DATADOG", v: "DATADOG" },
                      ],
                      placeholder: 'Seelct cloud account vendor',
                      row: 8,
                      col: 6
                    },
                    {
                      id: 'acc_region',
                      title: 'Account region',
                      mutable: true,
                      initValue: showModal['data']?.acc_region || '',
                      type: 'text',
                      placeholder: 'Enter cloud account region',
                      row: 9,
                      col: 6
                    },
                  ]
                }
                onExit={() => setShowModal('')}
                onSubmit={async (payload) => {
                  setShowModal('');
                  await updateRecord(payload);
                  await getRecords(adminMode);
                }}
                title='Update Environment/Account'
                titleDescription='Update existing Environment/Cloud Account into the system'
              />
            </div>
            <div>
              <FinOpsModal
                show={showModal['type'] === 'remove'}
                size='md'
                type='text'
                data={
                  <div>
                    <div>Are you sure you want to continue removing Environment <b>{showModal['data']?.env_name || ''}</b> ?</div>
                    <div className='mt-3 fst-italic' style={{ fontSize: '12px', color: '#999' }}><small>This will remove details for Environment and Cloud account.</small></div>
                  </div>
                }
                onExit={() => setShowModal('')}
                onSubmit={() => {
                  setShowModal('');
                  removeRecord();
                }}
                title='Remove Environment'
                titleDescription='Removes Environment record and associated Cloud account'
              />
            </div>

          </Container>
        </Col>

      </Row>
    </Container>
  );

};

export default withAuthenticator(Environments, withAuthenticatorOptions);