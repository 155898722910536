import React, { useState, useMemo, useReducer, useEffect } from "react";
import { API } from "aws-amplify";
import { useTable, usePagination, useSortBy, useExpanded } from 'react-table';
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import { Modal } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse';
import { MdUpdate, MdOutlineCheckCircle, MdTimelapse, MdCheckCircle, MdRadioButtonUnchecked, MdEdit, MdSave} from 'react-icons/md';
import { TiArrowUnsorted, TiArrowSortedUp, TiArrowSortedDown, TiFilter } from 'react-icons/ti';
import { useSearchParams } from "react-router-dom";
import { TruncatedFormLabel } from "./BootStrapCom";
import "flatpickr/dist/themes/material_blue.css";

import Flatpickr from "react-flatpickr";
import Multiselect from 'multiselect-react-dropdown';
import * as Utils from '../../common/utils';

import '../../scss/data_table.scss';

import OrderDropDown from "./OrderDropDown";
import NavigateButton from "./NavigateButton"
import CompareOrdersModal from './CompareOrdersModal';
import MilestoneTable from './MilestoneTable';

const DEFAULT_FILTER = { };
const PAGE_CHANGED = 'PAGE_CHANGED',
  PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED',
  PAGE_SORT_CHANGED = 'PAGE_SORT_CHANGED',
  PAGE_FILTER_CHANGED = 'PAGE_FILTER_CHANGED',
  TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';
const ALLOWED_COMPARE_ORDER_STATES = ['new','change','suspend','cancel'];
const INIT_FILTER_STATE = {
  queryPageIndex: 0,
  queryPageSize: 5,
  totalCount: null,
  queryPageFilter: {},
  queryPageSortBy: [{ id: 'create_timestamp', desc: true }],
};

const DataTable = ({ username, data, onTableUpdated, region, isLoading ,totalRecords,stage}) => {

  //#region states
  const [gotoPageInput, setGotoPageInput] = useState("");
  const [showFilter, setShowFilter] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState(Utils.urlParamsToObj(searchParams));
  const [compareOrderModal,setCompareOrderModal] = useState({show:false,sub:'',id:''})
  const [milestoneTable, setMilestoneTable] = useState({ show: false, milestones: '', milestones_state: '', serviceActivationDueDate: '', serviceActivationCompletionDate: '', flowId: '' });
  const [selectedActivationDueDate, setSelectedActivationDueDate] = useState(milestoneTable.serviceActivationDueDate || '');
  const [selectedActivationCompletionDate, setSelectedActivationCompletionDate] = useState(milestoneTable.serviceActivationCompletionDate || '');
  const [editActivationDueDate, setEditActivationDueDate] = useState(false);
  const [editActivationCompletionDate, setEditActivationCompletionDate] = useState(false);

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case PAGE_CHANGED:
        return {
          ...state,
          queryPageIndex: payload,
        };
      case PAGE_SIZE_CHANGED:
        return {
          ...state,
          queryPageSize: payload,
        };
      case PAGE_SORT_CHANGED:
        return {
          ...state,
          queryPageSortBy: payload,
        };
      case PAGE_FILTER_CHANGED:
        return {
          ...state,
          queryPageFilter: payload,
        };
      case TOTAL_COUNT_CHANGED:
        return {
          ...state,
          totalCount: payload,
        };
      default:
        throw new Error(`Unhandled action type: ${type}`)
    }
  };
  const [{ queryPageIndex, queryPageSize, totalCount, queryPageSortBy, queryPageFilter }, dispatch] = useReducer(reducer, INIT_FILTER_STATE);
  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div>
            <div title='Status'></div>
          </div>
        ),
        accessor: 'status',
        Cell: ({ value, row }) => {
          return (
            <div className='app-desc-wrap'>
              <div className='app-title text-truncate' title={toTitleCase(String(value))}>
                {statusIcon(value)}
              </div>
            </div>
          );
        },
        width: '3%',
        filter: 'text',
        disableSortBy: true
      },
      {
        Header: () => (
          <div>
            <div title='order_number'>Order No.</div>
            <div title='flow_id' className='fw-light text-muted'><small>Flow Id</small></div>
          </div>
        ),
        accessor: 'order_num',
        Cell: ({ value, row }) => {
          return (
            <div>
              <div className='d-flex align-items-center justify-content-start sub-id'>
                <div title={String(value)} className='text-truncate sub-id-text me-1'>{value ? value : '--'}</div>
              </div>
              <div title={`Flow Id:. ${String(row.original['id'] ? row.original['id'] : '--')}`} className='fw-light text-muted'>
                {row.original['id'] ? row.original['id'] : '--'}
              </div>
              {
                row.original['order_state'] !== 'NA' && (
                  <div 
                    title={buildOrderStateStatusTitle(row.original['order_state'])} 
                    className={`d-inline-block me-1 order-status ${buildOrderStateStatusClass(row.original['order_state'])}`}
                  >
                    {ALLOWED_COMPARE_ORDER_STATES.includes(row.original.order_state.toLowerCase()) ? (
                      <span 
                        onClick={() => setCompareOrderModal({ show: true, sub: row.original.sub_id, type: row.original.order_state, id: row.original.id })} 
                        style={{ cursor: 'pointer' }}
                      >
                        {row.original['order_state']}
                      </span>
                    ) : (
                      <span>{row.original['order_state']}</span>
                    )}
                  </div>
                )
              }
              {
                row.original['order_trial'] !== 'NA' && row.original['order_trial'] !== 'No Trial' && (
                <div title={buildOrderTrialStatusTitle(row.original['order_trial'])} className={`d-inline-block me-1 order-status ${buildOrderTrialStatusClass(row.original['order_trial'])}`}>
                  {row.original['order_trial']}
                </div>
              )}
              {
                row.original['order_sender'] !== 'NA' && (
                <div title={buildOrderSenderStatusTitle(row.original['order_sender'])} className={`d-inline-block me-1 order-status ${buildOrderSenderStatusClass(row.original['order_sender'])}`}>
                  {row.original['order_sender']}
                </div>
               )}
              {
                row.original['cloud_delivery_status'] && row.original['cloud_delivery_status'] !== '-' && (
                  <div className="mt-2">
                    <div 
                      title={buildCloudDeliveryStatusTitle(row.original['cloud_delivery_status'])} 
                      className={`d-inline-block me-1 order-status ${buildCloudDeliveryStatusClass(row.original['cloud_delivery_status'])}`} 
                    >
                      {"Cloud Delivery Status: "}
                      <span style={{ fontStyle: 'italic', fontWeight: 'bold'}}> 
                        {row.original['cloud_delivery_status']}
                      </span>
                    </div>
                  </div>
              )}
            </div>
          );
        },
        width: '31%',
        filter: 'startsWithText'
      },
      {
        Header: () => (
          <div>
            <div title='Provisioning Id 1 (pid1)'>Subscription Id</div>
            <div title='contract' className='fw-light text-muted'><small>Contracts</small></div>
          </div>
        ),
        accessor: 'sub_id',
        Cell: ({ value, row }) => {
          return <div>
            <div className='d-flex align-items-center justify-content-start sub-id'>
              <div title={String(value)} className='text-truncate sub-id-text me-1'>{value ? value : '--'}</div>
              <NavigateButton id={row.original.sub_id} navigateTo="subscription" />
            </div>
            <div title={`Contracts:. ${String(row.original['contract'] ? row.original['contract'] : '--')}`} className='acc-num fw-light text-muted text-truncate'>{row.original['contract'] ? row.original['contract'] : '--'}</div>
          </div>
        },
        width: '15%',
        filter: 'text'
      },
      {
        Header: () => (
          <div>
            <div title='Customer name'>Customer</div>
            <div title='Customer account number' className='fw-light text-muted'><small>Account No.</small></div>
            <div title='Business partner id/ Mea Id/ Project Id' className='fw-light text-muted'><small>BpLink Id / Mea Id / Project Id</small></div>
            <div title='Customer account number' className='fw-light text-muted'><small>Account Manager</small></div>
            <div title='Customer account number' className='fw-light text-muted'><small>D2 Contact</small></div>
          </div>

        ),
        accessor: 'name',
        Cell: ({ value, row }) => {
          return  <div className=''>  
          <div title={String(value)} className='text-truncate'>{value ? value : '--'}</div>
          <div title={`Account No: ${String(row.original['account'] ? row.original['account'] : '--')}, Enterprise Id: ${String(row.original['eid'] ? row.original['eid'] : '--')}`} className='acc-num text-truncate'>{row.original['account'] ? row.original['account'] : '--'} {row.original['eid'] ? ` / ${row.original['eid']}` : ''}</div>
          <div  title={`Business partner Id : ${String(row.original.bp_link_id?row.original.bp_link_id:'--')},Mea Id:${String(row.original.mea_id?row.original.mea_id:'--')},Project Id:${String(row.original.project_id?row.original.project_id:'--')}`} className='acc-num text-truncate'>{String(row.original.bp_link_id?row.original.bp_link_id:'--')} / {String(row.original.mea_id?row.original.mea_id:'--')} / {String(row.original.project_id?row.original.project_id:'--')}</div>
          <div title={`Account Manager: ${String(row.original['account_manager'] ? row.original['account_manager'] : '--')}`} className='acc-num text-truncate'>{row.original['account_manager'] ? row.original['account_manager'] : '--'}</div>
          <div title={`D2 contact: ${String(row.original['d2_contact'] ? row.original['d2_contact'] : '--')}`} className='acc-num text-truncate'>{row.original['d2_contact'] ? row.original['d2_contact'] : '--'}</div>
          </div> 
        },
        width: '22%',
        filter: 'text'
      },
      {
        Header: () => (
          <div>
            <div title='Labels'>Milestones Status</div>
          </div>
        ),
        accessor: 'milestones_state',
        Cell: ({ value, row }) => {
          if (!value) {
            return '--';
          }
          const statuses = value.split(',');
      
          return (
            <div className='onboarding-status-container'>
              {statuses.map((status, index) => {
                const [viewStatus, actualStatus] = parseOnboardingText(status);
                if (!actualStatus) {
                  return '--';
                }
                return (
                  <div key={index} className='onboarding-status-item'>
                    <div className={`tooltip-app rounded ${parseOnboardingStatus(viewStatus)}`} onClick={() => setMilestoneTable({ show: true, milestones: row.original['milestones'], milestones_state: row.original['milestones_state'], serviceActivationDueDate: row.original['sa_due_date'], serviceActivationCompletionDate: row.original['sa_completion_date'], flowId: row.original['id'] })} style={{ cursor: 'pointer' }}>
                      <div className='app-status-wrap'>
                        <div className='app-status-icon' title={status}>{parseOnboardingStatusIcon(actualStatus, viewStatus)}</div>
                        <div>&nbsp;</div>
                      </div>
                      <div className='app-desc-wrap'>
                        <div className='app-title text-truncate' title={viewStatus}>{viewStatus}</div>
                        <div className='app-instance text-truncate' title={actualStatus}><strong>{actualStatus}</strong></div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        },
        width: '28%',
        filter: 'multiselect',
        disableSortBy: true
      },   
      {
        Header: 'Offers',
        accessor: 'offers',
        Cell: ({ value, row }) => {
          return (
            <div className='d-flex align-items-center justify-content-start flex-wrap'>
              {value.split(',').map((v, index) =>
                <div key={index} className='rounded tooltip-offer text-wrap'>
                  {v.trim()}
                </div>
              )}
            </div>
          );
        },        
        width: '15%',
        filter: 'multiselect',
        disableSortBy: true
      },
      {
        Header: 'Created On',
        accessor: 'create_timestamp',
        Cell: ({ value }) => {
          const formattedDate = value ? value.split(' ')[0] : '--';
          return <div className="d-flex align-items-stretch justify-content-between">
            <div className="">
              <div title={String(value)} className='text-truncate'>{formattedDate}</div>
              <div><MdUpdate /> <small>{describeDate(formattedDate)}</small></div>
            </div>
          </div>;
        },
        width: '11%',
        filter: 'date'
      },
      {
        Header: 'Due date',
        accessor: 'due_date',
        Cell: ({ value }) => {
          const formattedDate = value ? value.split(' ')[0] : '--';
          return <div className="d-flex align-items-stretch justify-content-between">
            <div className="">
              <div title={String(value)} className='text-truncate'>{formattedDate}</div>
              <div><MdUpdate /> <small>{describeDate(formattedDate)}</small></div>
            </div>
          </div>;
        },
        width: '11%',
        filter: 'date'
      },
      {
        Header: 'Last updated',
        accessor: 'update_timestamp',
        Cell: ({ value }) => {
          const formattedDate = value ? value.split(' ')[0] : '--';
          return <div className="d-flex align-items-stretch justify-content-between">
            <div className="">
              <div title={String(value)} className='text-truncate'>{formattedDate}</div>
              <div><MdUpdate /> <small>{describeDate(formattedDate)}</small></div>
            </div>
          </div>;
        },
        width: '12%',
        filter: 'date'
      },
      {
        Header: '',
        accessor: 'drop',
        Cell: ({ value, row }) => {
          return <OrderDropDown row ={row}  id={row.original['sub_id']} region={region} username={username} stage={stage}/>
        },
        width: '3%',
        disableSortBy: true
      },
    ], // eslint-disable-next-line
    []
  ); // eslint-disable-line react-hooks/exhaustive-deps

  //#endregion

  //#region local static data

  const offers = Utils.fetchFromSessionStorage(username, 'offers', [], true);

  //#endregion

  //#region functions

  const parseOnboardingStatus = (statusType) => {
    if (statusType.includes('Cloudops Configure')) {
      return 'Snow';
    } else if (statusType.includes('CBSP')) {
      return 'Activation';
    } else if (statusType.includes('Customer Success')) {
      return 'Gainsight';
    } else if (statusType.includes('Cloud Delivery')) {
      return 'Mavenlink';
    } else {
      return 'other';
    }
  };

  const parseOnboardingText = (status) => {
    const [type, action] = status.split(':').map(s => s.replace(/_/g, ' '));
  
    let parsedType;
    let parsedAction = action;
    if (type === 'Gainsight') {
      parsedType = 'Customer Success';
    } else if (type === 'CBS') {
      parsedType = 'CBSP';
    } else if (type.includes('Ticket')) {
      parsedType = 'Cloudops Configure';
      parsedAction = type.split(' ')[0] + ': ' + action;
    } else {
      parsedType = type;
    }
  
    return [parsedType, parsedAction];
  };

  const parseOnboardingStatusIcon = (status, statusType) => {
    const iconClass = status.includes('Completed') ? 'MdOutlineCheckCircle' : 'MdTimelapse';
    return iconClass === 'MdOutlineCheckCircle' ?
      <MdOutlineCheckCircle className={`text-${statusType}`} /> :
      <MdTimelapse className={`text-${statusType}`} />;
  };  

  const buildOrderTrialStatusClass = (orderStatus) => {
    return orderStatus === 'Trial' ? 'trial' : '';
  };

  const buildOrderTrialStatusTitle = (orderStatus) => {
    return orderStatus === 'Trial' ? 'This is Trial Order.' : 'Not Trial Order';
  };

  const buildOrderSenderStatusClass = (orderStatus) => {
    return orderStatus === 'Internal' ? 'internal' : orderStatus === 'Commercial' ? 'commercial' : '';
  };

  const buildOrderSenderStatusTitle = (orderStatus) => {
    return orderStatus === 'Internal' ? 'This is Internal Order.' : orderStatus === 'Commercial' ? 'This is Commercial Order' : 'Order Status not available';
  };

  const buildOrderStateStatusClass = (orderStatus) => {
    return orderStatus === 'Cancel' ? 'cancel' : orderStatus === 'Suspend' ? 'suspend' : orderStatus === 'Trial Extension' ? 'trialExtension' : orderStatus === 'Trial To Paid' ? 'trialToPaid' : orderStatus === 'New' ? 'new' : orderStatus === 'Change' ? 'change' : '';
  };

  const buildOrderStateStatusTitle = (orderStatus) => {
    return orderStatus === 'Cancel' ? 'This is Cancel Order.' : orderStatus === 'Suspend' ? 'This is Suspend Order.' : orderStatus === 'Trial Extension' ? 'This is Trial Extension Order.' : orderStatus === 'Trial To Paid' ? 'This is Trial To Paid Order.' : orderStatus === 'New' ? 'This is New Order.' : orderStatus === 'Change' ? 'This is Change Order.' : 'Order Status not available';
  };

  const buildCloudDeliveryStatusClass = () => {
    return 'mavenlink';
  };

  const buildCloudDeliveryStatusTitle = (status) => {
    return 'The Cloud Delivery status is ' + status;
  };

  const toTitleCase = (str) => {
    return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const statusIcon = (status) => {
    if (status.toLowerCase() === 'resolved') {
      return <MdCheckCircle style={{ color: 'green' }} />;
    } else {
      return <MdRadioButtonUnchecked />;
    }
  };

  const handleGotoPageSubmit = (e) => {
    e.preventDefault();
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= pageOptions.length) {
      gotoPage(pageNumber - 1);
      setGotoPageInput('');
    }
  };

  const handleFilterChange = (columnId, value) => {
    let o = { ...filter, [`${columnId}`]: value };
    setFilter(o);
  };

  const handleClearFilters = () => {
    setSearchParams(DEFAULT_FILTER);
    setFilter({ ...DEFAULT_FILTER });
    handleTableChange(pageIndex, pageSize, sortBy, DEFAULT_FILTER);
    gotoPage(0);
  };

  const handleFilterSubmit = () => {
    setSearchParams(filter);
    handleTableChange(pageIndex, pageSize, sortBy, filter);
    gotoPage(0);
  };

  const handleSortIconClick = (column) => {
    column.toggleSortBy();
  };

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      return date;
    }
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const describeDate = (date) => {
    const currentDate = new Date();
    const providedDate = new Date(date);

    if (providedDate > currentDate) {
      return 'In the future';
    }

    const timeDifference = currentDate.getTime() - providedDate.getTime();
    const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

    if (daysDifference < 30) {
      return `${daysDifference} days ago`;
    } else if (daysDifference < 365) {
      const monthsDifference = Math.floor(daysDifference / 30);
      return `${monthsDifference} months ago`;
    } else {
      const yearsDifference = Math.floor(daysDifference / 365);
      return `${yearsDifference} years ago`;
    }
  };

  const handleSingleSelectChange = (columnId, value) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      [columnId]: value
    }));
  };
  
  const handleMultiItemSelect = (columnId, value) => {
    if (!value) setFilter({ ...filter, [`${columnId}`]: '' });
    else setFilter({ ...filter, [`${columnId}`]: value });
  };

  const handleTableChange = (pageIndex, pageSize, sortBy, filter) => {
    onTableUpdated(pageIndex, pageSize, sortBy, filter, false);
  };

  const saveDateChange = async (dateType, date) => {
    const formattedDate = formatDate(date);

    try {
        await API.put("SspBackendApi", '/edit-service-activation-date', {
            queryStringParameters: {
                id: milestoneTable.flowId,
                date: formattedDate,
                region: region,
                stage: stage,
                type: dateType === 'due-date' ? 'service_activation_due_date' : 'service_activation_completion_date'
            }
        });

        setMilestoneTable(prev => ({
            ...prev,
            serviceActivationDueDate: dateType === 'due-date' ? formattedDate : prev.serviceActivationDueDate,
            serviceActivationCompletionDate: dateType === 'completion-date' ? formattedDate : prev.serviceActivationCompletionDate
        }));

        data.results = data.results.map(item => {
            if (item.id === milestoneTable.flowId) {
                return {
                    ...item,
                    sa_due_date: dateType === 'due-date' ? formattedDate : item.sa_due_date,
                    sa_completion_date: dateType === 'completion-date' ? formattedDate : item.sa_completion_date,
                };
            }
            return item;
        });

        if (dateType === 'due-date') {
            setEditActivationDueDate(false);
            setSelectedActivationDueDate(formattedDate);
        } else if (dateType === 'completion-date') {
            setEditActivationCompletionDate(false);
            setSelectedActivationCompletionDate(formattedDate);
        }
    } catch (error) {
        console.error("Error saving date:", error);
    }
  };

  //#endregion

  //#region data processing

  const tableData = data.results ?
    data.results.map(item => ({
      ...item
    })) : [];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, sortBy },
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
  } = useTable(
    {
      columns,
      data: tableData || [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
        filter: queryPageFilter
      },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / queryPageSize),
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  //#endregion

  //#region react effects 

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize, gotoPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
    gotoPage(0);
  }, [sortBy, gotoPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.count,
      });
    }
  }, [data?.count]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleTableChange(pageIndex, pageSize, sortBy, filter);
  }, [pageIndex, pageSize, gotoPage, sortBy]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedActivationDueDate(milestoneTable.serviceActivationDueDate || '');
    setSelectedActivationCompletionDate(milestoneTable.serviceActivationCompletionDate || '');
  }, [milestoneTable]);

  //#endregion

  return (
    <div className='table-container'>

      <div className='filter-section-title'>
        <div>
          <span className='filter-icon pe-1'><TiFilter /></span><span>Filter</span> <small className='ms-1 toggle' onClick={() => setShowFilter(!showFilter)} >{showFilter ? 'Hide' : 'Show'}</small>
        </div>
      </div>

      <Collapse in={showFilter}>
        <div>
          <div className="filter-section" id="filter-section">
            {/* First Row */}
            <div className='d-flex align-items-top justify-content-start'>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="orderId">
                  <TruncatedFormLabel>Order No.</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['order_num'] || ''} onChange={event => handleFilterChange('order_num', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="flowId">
                  <TruncatedFormLabel>Flow Id</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['id'] || ''} onChange={event => handleFilterChange('id', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="subscriptionId">
                  <TruncatedFormLabel>Subscription Id</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['sub_id'] || ''} onChange={event => handleFilterChange('sub_id', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="meaId">
                  <TruncatedFormLabel>Mea Id</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['mea_id'] || ''} onChange={event => handleFilterChange('mea_id', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="app-account">
                  <TruncatedFormLabel>Business Partner</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['bp_link_id'] || ''} onChange={event => handleFilterChange('bp_link_id', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="Contract">
                  <TruncatedFormLabel>Contract</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['contract'] || ''} onChange={event => handleFilterChange('contract', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} style={{ width: '14%', paddingRight: '5px' }}>
                <Form.Group controlId="customer">
                  <TruncatedFormLabel>Customer</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['name'] || ''} onChange={event => handleFilterChange('name', event.target.value)} />
                </Form.Group>
              </Form>
            </div>

            {/* Second Row */}
            <div className='d-flex align-items-top justify-content-start mt-3'>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="projectId">
                  <TruncatedFormLabel>Project Id</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['project_id'] || ''} onChange={event => handleFilterChange('project_id', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="d2_contact">
                  <TruncatedFormLabel>D2 Contact</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['d2_contact'] || ''} onChange={event => handleFilterChange('d2_contact', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="accountId">
                  <TruncatedFormLabel>Account Manager</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['account_manager'] || ''} onChange={event => handleFilterChange('account_manager', event.target.value)} />
                </Form.Group>
              </Form>
              <Form onSubmit={(e) => { e.preventDefault(); handleFilterSubmit(); }} style={{ width: '14%', paddingRight: '5px' }}>
                <Form.Group controlId="account">
                  <TruncatedFormLabel>Account No.</TruncatedFormLabel>
                  <Form.Control size='sm' type="text" value={filter['account'] || ''} onChange={event => handleFilterChange('account', event.target.value)} />
                </Form.Group>
              </Form>
              <div className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="orderTrial">
                  <TruncatedFormLabel>Trial</TruncatedFormLabel>
                  <Form.Select
                    size='sm'
                    value={filter['order_trial'] || ''}
                    onChange={(e) => handleSingleSelectChange('order_trial', e.target.value)}
                  >
                    <option value='' disabled hidden></option>
                    <option value='Trial'>Trial</option>
                    <option value='NA'>No Trial</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="orderSender">
                  <TruncatedFormLabel>Subscription Type</TruncatedFormLabel>
                  <Form.Select
                    size='sm'
                    value={filter['order_sender'] || ''}
                    onChange={(e) => handleSingleSelectChange('order_sender', e.target.value)}
                  >
                    <option value='' disabled hidden></option>
                    <option value='Commercial'>Commercial</option>
                    <option value='Internal'>Internal</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="orderState">
                  <TruncatedFormLabel>Order State</TruncatedFormLabel>
                  <Form.Select
                    size='sm'
                    value={filter['order_state'] || ''}
                    onChange={(e) => handleSingleSelectChange('order_state', e.target.value)}
                  >
                    <option value='' disabled hidden></option>
                    <option value='New'>New</option>
                    <option value='Change'>Change</option>
                    <option value='Trial Extension'>Trial Extension</option>
                    <option value='Trial To Paid'>Trial To Paid</option>
                    <option value='Suspend'>Suspend</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="ServiceActivationDate">
                  <TruncatedFormLabel>Service Activation</TruncatedFormLabel>
                  <Form.Select
                    size='sm'
                    value={filter['sa_status'] || ''}
                    onChange={(e) => handleSingleSelectChange('sa_status', e.target.value)}
                  >
                    <option value='' disabled hidden></option>
                    <option value='all'>All</option>
                    <option value='completed'>Completed Timely</option>
                    <option value='completed_late'>Completed Overdue</option>
                    <option value='in_progress_late'>In-Progress Overdue</option>
                    <option value='in_progress'>In-Progress Timely</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="status">
                  <TruncatedFormLabel>Onboarding</TruncatedFormLabel>
                  <Form.Select
                    size='sm'
                    value={filter['status'] || ''}
                    onChange={(e) => handleSingleSelectChange('status', e.target.value)}
                  >
                    <option value='' disabled hidden></option>
                    <option value='Open'>Open</option>
                    <option value='Resolved'>Resolved</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>

            {/* Third Row */}
            <div className='d-flex align-items-top justify-content-start mt-3'>
            <div className='me-1' style={{ width: '26%' }}>
                <Form.Group controlId="offers">
                  <TruncatedFormLabel>Offers</TruncatedFormLabel>
                  <div>
                    {<Multiselect
                      onSelect={(selectedList, selectedItem) => handleMultiItemSelect('offers', selectedList.map(e => e.id).join(','))}
                      onRemove={(selectedList, removedItem) => handleMultiItemSelect('offers', selectedList.map(e => e.id).join(','))}
                      options={offers.map(o => { return { name: `${o.offerCommercialName} (${o.offerSystemType})`, id: o.offerCommercialName } })}
                      selectedValues={filter['offers'] ? filter['offers'].split(',').map(o => { return { name: `${offers.filter(offer => offer.offerCommercialName === o)[0]['offerCommercialName']} (${o})`, id: o } }) : null}
                      displayValue='name'
                      placeholder=''
                      showCheckbox={true}
                      caseSensitiveSearch={false}
                      className='filter-multiselect'
                      avoidHighlightFirstOption={true}
                      hidePlaceholder={true}
                      showArrow={true}
                    />}
                  </div>
                </Form.Group>
              </div>
              <div className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="cloudDeliveryStatus">
                  <TruncatedFormLabel>Cloud Delivery Status</TruncatedFormLabel>
                  <div>
                    {<Multiselect
                      onSelect={(selectedList, selectedItem) => handleMultiItemSelect('cloud_delivery_status', selectedList.map(e => e.id).join(','))}
                      onRemove={(selectedList, removedItem) => handleMultiItemSelect('cloud_delivery_status', selectedList.map(e => e.id).join(','))}
                      options={['Active', 'On Hold','Cancel', 'Complete', 'Decline', 'Not Started'].map(s => { return { name: s, id: s } })}
                      selectedValues={filter['cloud_delivery_status'] ? filter['cloud_delivery_status'].split(',').map(s => { return { name: s.toUpperCase(), id: s.toUpperCase() } }) : null}
                      displayValue='name'
                      placeholder=''
                      showCheckbox={true}
                      caseSensitiveSearch={false}
                      className='filter-multiselect'
                      avoidHighlightFirstOption={true}
                      hidePlaceholder={true}
                      showArrow={true}
                    />}
                  </div>
                </Form.Group>
              </div>
              <div className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="onboardingStatus">
                  <TruncatedFormLabel>Milestones Status</TruncatedFormLabel>
                  <div>
                    {<Multiselect
                      onSelect={(selectedList, selectedItem) => handleMultiItemSelect('milestones_state', selectedList.map(e => e.id).join(','))}
                      onRemove={(selectedList, removedItem) => handleMultiItemSelect('milestones_state', selectedList.map(e => e.id).join(','))}
                      options={['Completed', 'Started','Created', 'TBA'].map(s => { return { name: s, id: s } })}
                      selectedValues={filter['milestones_state'] ? filter['milestones_state'].split(',').map(s => { return { name: s.toUpperCase(), id: s.toUpperCase() } }) : null}
                      displayValue='name'
                      placeholder=''
                      showCheckbox={true}
                      caseSensitiveSearch={false}
                      className='filter-multiselect'
                      avoidHighlightFirstOption={true}
                      hidePlaceholder={true}
                      showArrow={true}
                    />}
                  </div>
                </Form.Group>
              </div>
              <div className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="createdAt">
                  <TruncatedFormLabel>Created on</TruncatedFormLabel>
                  <div>
                    <Flatpickr
                      value={filter['create_timestamp']}
                      defaultValue={null}
                      onChange={(date) => handleFilterChange('create_timestamp', date.map(d => formatDate(new Date(d))))}
                      className='form-control form-control-sm'
                      placeholder="Select date or range"
                      options={{
                        enableTime: false,
                        dateFormat: "Y-m-d",
                        altInput: true,
                        altFormat: 'F j, Y',
                        maxDate: formatDate(new Date()),
                        mode: 'range'
                      }}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="dueDate">
                  <TruncatedFormLabel>Due date</TruncatedFormLabel>
                  <div>
                    <Flatpickr
                      value={filter['due_date']}
                      defaultValue={null}
                      onChange={(date) => handleFilterChange('due_date', date.map(d => formatDate(new Date(d))))}
                      className='form-control form-control-sm'
                      placeholder="Select date or range"
                      options={{
                        enableTime: false,
                        dateFormat: "Y-m-d",
                        altInput: true,
                        altFormat: 'F j, Y',
                        mode: 'range'
                      }}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className='me-1' style={{ width: '14%' }}>
                <Form.Group controlId="updatedAt">
                  <TruncatedFormLabel>Last updated</TruncatedFormLabel>
                  <div>
                    <Flatpickr
                      value={filter['update_timestamp']}
                      defaultValue={null}
                      onChange={(date) => handleFilterChange('update_timestamp', date.map(d => formatDate(new Date(d))))}
                      className='form-control form-control-sm'
                      placeholder="Select date or range"
                      options={{
                        enableTime: false,
                        dateFormat: "Y-m-d",
                        altInput: true,
                        altFormat: 'F j, Y',
                        maxDate: formatDate(new Date()),
                        mode: 'range'
                      }}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className='d-flex align-items-center justify-content-end ms-auto'>
                <div className='me-2'>
                  <Button size='sm' className='pt-1' variant="secondary" onClick={() => handleFilterSubmit()}>Filter</Button>
                </div>
                <div className=''>
                  <Button size='sm' className='pt-1' variant="secondary" onClick={() => handleClearFilters()}>Reset</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapse>

      <div className={isLoading ? 'table-wrap loading' : 'table-wrap'}>
        <Table {...getTableProps()} className='sub-table'>
          <thead className="thead-dark">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} className='align-middle'>
                {headerGroup.headers.map((column, index) => (
                  <th key={index} {...column.getHeaderProps({
                    className: column.collapse ? 'collapse px-1' : 'px-1',
                    style: { width: column.width }
                  })}>
                    <div className="d-flex align-items-center justify-content-between" onClick={() => { if (!column.disableSortBy) handleSortIconClick(column) }} style={{ cursor: 'pointer' }}>
                      <div>
                        <span>{column.render('Header')}</span>
                      </div>
                      <div>
                        {
                          !column.disableSortBy &&
                          <Button variant="link" className="p-0" title={`Sort by ${column.id}`} style={{ color: '#666' }}>
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TiArrowSortedDown />
                                ) : (
                                  <TiArrowSortedUp />
                                )
                              ) : (
                                <>
                                  <TiArrowUnsorted className="text-muted" />
                                </>
                              )}
                            </span>
                          </Button>
                        }
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className='align-middle'>
                  {row.cells.map(cell => (
                    <td
                      {...cell.getCellProps({
                        className: cell.column.collapse ? 'collapse px-1' : 'px-1',
                        style: { width: cell.column.width }
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>

        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="d-flex align-items-center justify-content-start">
            <ButtonGroup size="sm" className='me-2'>
              <Button variant="secondary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>First</Button>
              <Button variant="secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Button>
              <Button variant="secondary" onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
              <Button variant="secondary" onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>Last</Button>
            </ButtonGroup>
            <div className='me-2'>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </div>
            <div>
              <Form onSubmit={handleGotoPageSubmit}>
                <Form.Group className="mb-0 ms-1" style={{ width: '150%' }}>
                  <Form.Control size="sm" type="number" min="1" max={pageOptions.length} placeholder="Go to page" value={gotoPageInput} onChange={(e) => setGotoPageInput(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>
          <div className='d-flex'><div className="me-2 mb-0 ms-1 mt-1">Total records:<strong>{totalRecords}</strong></div>
            <Dropdown className='pagesize-dropdown'>
              <Dropdown.Toggle variant="secondary" id="page-size-dropdown" size="sm">Show {pageSize}</Dropdown.Toggle>
              <Dropdown.Menu>
                {[5, 10, 15].map(pageSizeOption => (
                  <Dropdown.Item
                    key={pageSizeOption}
                    active={pageSizeOption === pageSize}
                    onClick={() => setPageSize(pageSizeOption)}
                  >
                    Show {pageSizeOption}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <Modal dialogClassName='left-aligned-modal' show={compareOrderModal.show} onHide={()=>setCompareOrderModal({show:false,sub:'',type:''})}>
        <Modal.Header style={{background:'rgb(238,238,238)',width:'80vw'}}  closeButton>
          <Modal.Title>Compare Subscription State</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{background:'white',width:'80vw',height:'80vh',overflow:'auto'}}>
          <CompareOrdersModal sub={compareOrderModal.sub} stage={stage} region={region} id={compareOrderModal.id}/>
        </Modal.Body>
      </Modal>
      <Modal
        dialogClassName='left-aligned-modal'
        show={milestoneTable.show}
        onHide={() => {
          setMilestoneTable({ 
            show: false, 
            milestones: '', 
            milestones_state: '', 
            serviceActivationDueDate: '', 
            serviceActivationCompletionDate: '', 
            flowId: '' 
          });
          setEditActivationDueDate(false);
          setEditActivationCompletionDate(false);
        }}
      >   
        <Modal.Header style={{ background: 'rgb(238,238,238)', width: '90vw' }} closeButton>
          <Modal.Title className="w-100">
            <div className="d-flex align-items-center justify-content-between w-100">
              <span>Milestones</span>
              {milestoneTable.milestones_state && milestoneTable.milestones_state.split(',').some(state => state.startsWith('Cloud_Delivery:')) && (
                <div className="d-flex align-items-center justify-content-end" style={{ fontSize: '0.9rem', marginRight: '2rem' }}>
                  <div className="me-4 d-flex align-items-center">
                    <label className="me-2">Service Activation Due Date:</label>
                    {editActivationDueDate ? (
                      <>
                        <Flatpickr
                          value={selectedActivationDueDate || ''}
                          options={{ dateFormat: 'Y-m-d' }}
                          onChange={date => setSelectedActivationDueDate(formatDate(date[0]))}
                          className="form-control form-control-sm"
                          style={{ width: '100px' }}
                        />
                        <MdSave
                          className="ms-2"
                          style={{ cursor: 'pointer', color: 'black' }}
                          onClick={() => saveDateChange('due-date', selectedActivationDueDate)}
                        />
                      </>
                    ) : (
                      <>
                        <strong><span>{selectedActivationDueDate || '--'}</span></strong>
                        <MdEdit
                          className="ms-2"
                          style={{ cursor: 'pointer' }}
                          onClick={() => setEditActivationDueDate(true)}
                        />
                      </>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <label className="me-2">Service Activation Completion Date:</label>
                    {editActivationCompletionDate ? (
                      <>
                        <Flatpickr
                          value={selectedActivationCompletionDate || ''}
                          options={{ dateFormat: 'Y-m-d' }}
                          onChange={date => setSelectedActivationCompletionDate(formatDate(date[0]))}
                          className="form-control form-control-sm"
                          style={{ width: '100px' }}
                        />
                        <MdSave
                          className="ms-2"
                          style={{ cursor: 'pointer', color: 'black' }}
                          onClick={() => saveDateChange('completion-date', selectedActivationCompletionDate)}
                        />
                      </>
                    ) : (
                      <>
                        <strong><span>{selectedActivationCompletionDate || '--'}</span></strong>
                        <MdEdit
                          className="ms-2"
                          style={{ cursor: 'pointer' }}
                          onClick={() => setEditActivationCompletionDate(true)}
                        />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: 'white', width: '90vw', height: '80vh', overflow: 'auto' }}>
          <MilestoneTable milestones={milestoneTable.milestones} milestones_state={milestoneTable.milestones_state} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DataTable;
