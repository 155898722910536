import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Col, Dropdown, Form, Row, Table } from "react-bootstrap";
import { CopyButton, FlowGraph, TableDiff, TextDiff } from "./BootStrapCom";
import PropTypes from "prop-types";
const EMPTY_DASHBOARD = { header: [[], []], content: [[], []] };
const CompareOrdersModal = ({ sub, region, stage, id, compareLimit }) => {
  const [apiData, setApiData] = useState();
  const [dashboardList, setDashboardList] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [currentDashboard, setCurrentDashboard] = useState({});
  const [previousDashboard, setPreviousDashboard] = useState({});
  const [truncated, setTruncated] = useState(true);
  const [options, setOptions] = useState({});
  const [numOrdersForComparison, setNumOrdersForComparison] =
    useState(compareLimit);
  const parseDashboard = (item) => {
    const stateIndicator = item.state_indicator ? item.state_indicator : {};
    const state = stateIndicator.state_label
      ? stateIndicator.state_label
      : "N/A";
    const dashboardTP = item.dashboard[0];
    const updated = item.start_time;
    const dashboardId = item.dashboard_id;
    const description = dashboardTP.item.description;
    const descriptionLines = description.split("\n");
    const parsedDescription = {
      content: [],
      header: [],
      updated: updated,
      dashboardId: dashboardId,
      state: state,
    };
    let header_index = -1;
    descriptionLines.map((line, index) => {
      if (!line) return;
      if (line.includes("Contract")) {
        parsedDescription.contract = descriptionLines[index + 1];
      } else if (line.includes("Region")) {
        parsedDescription.region = line.split("Region:")[1].trim();
      } else if (line.includes("Enterprise ID:")) {
        parsedDescription.enterpriseId = line.split("Enterprise ID:")[1].trim();
      } else if (line.includes("Order Number:")) {
        parsedDescription.orderNumber = line.split("Order Number:")[1].trim();
      } else if (line.includes("Technical Contact Name:")) {
        parsedDescription.technicalContactName = line
          .split("Technical Contact Name:")[1]
          .trim();
      } else if (line.includes("Technical Contact Email:")) {
        parsedDescription.technicalContactEmail = line
          .split("Technical Contact Email:")[1]
          .trim();
      } else if (line.includes("Technical Contact Phone:")) {
        parsedDescription.technicalContactPhone = line
          .split("Technical Contact Phone:")[1]
          .trim();
      } else if (line.includes("Customer Email:")) {
        parsedDescription.customerEmail = line
          .split("Customer Email:")[1]
          .trim();
      } else if (line.includes("Customer Email Domain:")) {
        parsedDescription.customerEmailDomain = line
          .split("Customer Email Domain:")[1]
          .trim();
      } else if (line.includes("Opportunity Number:")) {
        parsedDescription.opportunityNumber = line
          .split("Opportunity Number:")[1]
          .trim();
      } else if (line.includes("||")) {
        header_index += 1;
        const headers = line.split("||");
        headers.shift();
        headers.pop();
        parsedDescription.header[header_index] = headers;
      } else if (line.includes("|")) {
        if (!parsedDescription.content[header_index]) {
          parsedDescription.content[header_index] = [];
        }
        const lines = line.split("|");
        lines.shift();
        lines.pop();
        parsedDescription.content[header_index].push(lines);
      }
    });
    return parsedDescription;
  };
  useEffect(() => {
    const initDataBySub = async () => {
      const data = await API.get("SspBackendApi", "/view-order-diff", {
        queryStringParameters: {
          id: sub,
          region: region,
          stage: stage,
        },
      });
      data.sort(
        (a, b) => new Date(b.start_time) - new Date(a.start_time)
      );
      const updatedDashboardList = data.map((item) => parseDashboard(item));
      const optionsTS = updatedDashboardList.map((item, index) => ({
        name: item.updated,
        sortBy: index,
        state: item.state,
        id: item.dashboardId,
      }));
      setOptions(optionsTS.reverse());
      setApiData(data);
      let filteredDashboardList = [];
      if (id) {
        filteredDashboardList = updatedDashboardList.slice(
          updatedDashboardList.findIndex((item) => item.dashboardId === id)
        );
        setDashboardList((prevDashboardList) => [
          ...prevDashboardList,
          ...filteredDashboardList,
        ]);
      } else {
        setDashboardList((prevDashboardList) => [
          ...prevDashboardList,
          ...updatedDashboardList,
        ]);
      }

      setCurrentDashboard(
        id ? filteredDashboardList[0] : updatedDashboardList[0]
      );
      setPreviousDashboard(
        id
          ? filteredDashboardList[1]
            ? filteredDashboardList[1]
            : EMPTY_DASHBOARD
          : updatedDashboardList[1]
          ? updatedDashboardList[1]
          : EMPTY_DASHBOARD
      );

      if (updatedDashboardList.length === 0) {
        setNoResults(true);
      }
    };
    try {
      initDataBySub();
    } catch (e) {
      setNoResults(true);
    }
  }, []);
  const onCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const onSet = (id) => {
    const current = dashboardList.find((item) => item.dashboardId === id);
    const index = dashboardList.indexOf(current);
    setCurrentDashboard(current);
    if (index === dashboardList.length - 1) {
      //first order
      setPreviousDashboard(EMPTY_DASHBOARD);
    } else {
      setPreviousDashboard(dashboardList[index + 1]);
    }
  };
  return (
    <div>
      {noResults && <div>No results found</div>}
      {dashboardList.length === 0 && !noResults && <div>Loading...</div>}
      {dashboardList.length > 0 && options.length > 0 && (
        <div>
          <FlowGraph
            options={options}
            markBoth={numOrdersForComparison === 2}
            checked={{
              start: previousDashboard.dashboardId,
              end: currentDashboard.dashboardId,
            }}
            onSet={onSet}
          />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Form.Check
              type="checkbox"
              label="Truncate"
              onChange={(e) => setTruncated(e.target.checked)}
              checked={truncated}
            />
          </div>
        </div>
      )}
      {dashboardList.length > 0 && (
        <div>
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap",overflowY:'auto' }}
          >
            {previousDashboard &&
              numOrdersForComparison === 2 &&
              Object.keys(previousDashboard).includes("contract") && (
                <div
                  style={{
                    flex: "1",
                    minWidth: "300px",
                    padding: "10px",
                    border: "1px solid #ccc",
                    margin: "5px",
                  }}
                >
                  <div>
                    <Row>
                      <Col>{previousDashboard.updated}</Col>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <CopyButton
                          onClickProp={() =>
                            onCopy(apiData[1].dashboard[0].item.description)
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="compare-table-cell">
                        Contract:
                        <TextDiff
                          oldText={previousDashboard.contract}
                          newText={currentDashboard.contract}
                          type="old"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="compare-table-cell">
                        Region:
                        <TextDiff
                          oldText={previousDashboard.region}
                          newText={currentDashboard.region}
                          type="old"
                        />
                      </Col>
                      <Col className="compare-table-cell">
                        Country:
                        <TextDiff
                          oldText={previousDashboard.country}
                          newText={currentDashboard.country}
                          type="old"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="compare-table-cell">
                        Enterprise ID:
                        <TextDiff
                          oldText={previousDashboard.enterpriseId}
                          newText={currentDashboard.enterpriseId}
                          type="old"
                        />
                      </Col>
                      <Col className="compare-table-cell">
                        Order #:
                        <TextDiff
                          oldText={previousDashboard.orderNumber}
                          newText={currentDashboard.orderNumber}
                          type="old"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="compare-table-cell">
                        Contact Name:
                        <TextDiff
                          oldText={previousDashboard.technicalContactName}
                          newText={currentDashboard.technicalContactName}
                          type="old"
                        />
                      </Col>
                      <Col className="compare-table-cell">
                        T-Email:
                        <TextDiff
                          oldText={previousDashboard.technicalContactEmail}
                          newText={currentDashboard.technicalContactEmail}
                          type="old"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="compare-table-cell">
                        Phone:
                        <TextDiff
                          oldText={previousDashboard.technicalContactPhone}
                          newText={currentDashboard.technicalContactPhone}
                          type="old"
                        />
                      </Col>
                      <Col className="compare-table-cell">
                        Email:
                        <TextDiff
                          oldText={previousDashboard.customerEmail}
                          newText={currentDashboard.customerEmail}
                          type="old"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="compare-table-cell">
                        Domain:
                        <TextDiff
                          oldText={previousDashboard.customerEmailDomain}
                          newText={currentDashboard.customerEmailDomain}
                          type="old"
                        />
                      </Col>
                      <Col className="compare-table-cell">
                        Opportunity Number:
                        <TextDiff
                          oldText={previousDashboard.opportunityNumber}
                          newText={currentDashboard.opportunityNumber}
                          type="old"
                        />
                      </Col>
                    </Row>
                    <Col>
                      <hr className="my-4" />
                      <Row>
                        <TableDiff
                          truncated={truncated}
                          previousTable={{
                            header: previousDashboard.header[0],
                            rows: previousDashboard.content[0],
                          }}
                          currentTable={{
                            header: currentDashboard.header[0],
                            rows: currentDashboard.content[0],
                          }}
                          type="old"
                        />
                      </Row>
                      <Row>
                        <TableDiff
                          truncated={truncated}
                          previousTable={{
                            header: previousDashboard.header[1],
                            rows: previousDashboard.content[1],
                          }}
                          currentTable={{
                            header: currentDashboard.header[1],
                            rows: currentDashboard.content[1],
                          }}
                          type="old"
                        />
                      </Row>
                    </Col>
                  </div>
                </div>
              )}
            {currentDashboard && (
              <div
                style={{
                  flex: "1",
                  minWidth: "300px",
                  padding: "10px",
                  border: "1px solid #ccc",
                  margin: "5px",
                }}
              >
                <div>
                  <Row>
                    <Col>{currentDashboard.updated}</Col>
                    <Col
                      style={{ display: "flex", flexDirection: "row-reverse" }}
                    >
                      <CopyButton
                        onClickProp={() =>
                          onCopy(apiData[0].dashboard[0].item.description)
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="compare-table-cell">
                      Contract:
                      <TextDiff
                        oldText={previousDashboard.contract}
                        newText={currentDashboard.contract}
                        type="new"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="compare-table-cell">
                      Region:
                      <TextDiff
                        oldText={previousDashboard.region}
                        newText={currentDashboard.region}
                        type="new"
                      />
                    </Col>
                    <Col className="compare-table-cell">
                      Country:
                      <TextDiff
                        oldText={previousDashboard.country}
                        newText={currentDashboard.country}
                        type="new"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="compare-table-cell">
                      Enterprise ID:
                      <TextDiff
                        oldText={previousDashboard.enterpriseId}
                        newText={currentDashboard.enterpriseId}
                        type="new"
                      />
                    </Col>
                    <Col className="compare-table-cell">
                      Order #:
                      <TextDiff
                        oldText={previousDashboard.orderNumber}
                        newText={currentDashboard.orderNumber}
                        type="new"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="compare-table-cell">
                      Contact Name:
                      <TextDiff
                        oldText={previousDashboard.technicalContactName}
                        newText={currentDashboard.technicalContactName}
                        type="new"
                      />
                    </Col>
                    <Col className="compare-table-cell">
                      T-Email:
                      <TextDiff
                        oldText={previousDashboard.technicalContactEmail}
                        newText={currentDashboard.technicalContactEmail}
                        type="new"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="compare-table-cell">
                      Phone:
                      <TextDiff
                        oldText={previousDashboard.technicalContactPhone}
                        newText={currentDashboard.technicalContactPhone}
                        type="new"
                      />
                    </Col>
                    <Col className="compare-table-cell">
                      Email:
                      <TextDiff
                        oldText={previousDashboard.customerEmail}
                        newText={currentDashboard.customerEmail}
                        type="new"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="compare-table-cell">
                      Domain:
                      <TextDiff
                        oldText={previousDashboard.customerEmailDomain}
                        newText={currentDashboard.customerEmailDomain}
                        type="new"
                      />
                    </Col>
                    <Col className="compare-table-cell">
                      Opportunity Number:
                      <TextDiff
                        oldText={previousDashboard.opportunityNumber}
                        newText={currentDashboard.opportunityNumber}
                        type="new"
                      />
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <Col>
                    <Row>
                      <TableDiff
                        truncated={truncated}
                        previousTable={{
                          header: previousDashboard.header[0],
                          rows: previousDashboard.content[0],
                        }}
                        currentTable={{
                          header: currentDashboard.header[0],
                          rows: currentDashboard.content[0],
                        }}
                        type="new"
                      />
                    </Row>
                    <Row>
                      <TableDiff
                        truncated={truncated}
                        previousTable={{
                          header: previousDashboard.header[1],
                          rows: previousDashboard.content[1],
                        }}
                        currentTable={{
                          header: currentDashboard.header[1],
                          rows: currentDashboard.content[1],
                        }}
                        type="new"
                      />
                    </Row>
                  </Col>
                </div>
              </div>
            )}
          </div>
          {options.length > 1 && (
            <div id='compare-order-modal-footer' style={{display:'flex',flexDirection:'row-reverse',alignItems:'center'}}>
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  Show {numOrdersForComparison.toString()}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setNumOrdersForComparison(1)}>
                    1
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setNumOrdersForComparison(2)}>
                    2
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
// Prop type declaration

CompareOrdersModal.propTypes = {
  sub: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  stage: PropTypes.string.isRequired,
  id: PropTypes.string,
  compareLimit: PropTypes.number,
};
CompareOrdersModal.defaultProps = {
  id: null,
  compareLimit: 2,
};
export default CompareOrdersModal;
