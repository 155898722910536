import Form from "react-bootstrap/Form";
import React, { useRef, useEffect } from "react";
import { useState } from "react";
import FormControl from "react-bootstrap/FormControl";
import "./bootstrapcom.css";
import { diffWordsWithSpace } from "diff";
import { Table } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { FaRegCopy } from "react-icons/fa";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoArrowForwardOutline } from "react-icons/io5";
import { TbPoint } from "react-icons/tb";
import { GoArrowDown } from "react-icons/go";

export const TruncatedFormLabel = ({ children }) => {
  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);
  const updateParentWidth = () => {
    try {
      const parentWidth = parentRef.current.offsetWidth;
      setParentWidth(`${parentWidth}px`);
    } catch (e) {}
  };
  useEffect(() => {
    updateParentWidth();
  }, [parentRef]);
  window.addEventListener("resize", updateParentWidth); // Update on window resize
  return (
    <div ref={parentRef} className="parent-element">
      <Form.Label
        title={children}
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: parentWidth,
        }}
      >
        {children}
      </Form.Label>
    </div>
  );
};

export const ShakyFormControl = ({
  value,
  onSuccess,
  validate,
  size,
  ...restProps
}) => {
  const [shake, setShake] = useState(false);
  const handleChange = (event) => {
    const isValid = validate(event.target.value);
    if (isValid) {
      onSuccess(event);
    } else {
      setShake(true);
      setTimeout(() => setShake(false), 500); // Duration should match the CSS animation
    }
  };

  return (
    <FormControl
      {...restProps} //must be the first prop
      className={`${shake ? "shake-animation" : ""} ${
        restProps.className || ""
      }`.trim()}
      size={size}
      value={value || ""}
      onChange={handleChange}
    />
  );
};

export const TextDiff = ({ oldText, newText, type }) => {
  if (!oldText && type === "new") {
    return newText;
  } else if (!oldText || !newText) return "";
  const diffResult = diffWordsWithSpace(oldText.trim(), newText);

  return (
    <div>
      {diffResult.map((part, index) => {
        if (type === "old") {
          // For the old component, highlight removed parts in red
          if (part.removed) {
            return (
              <span key={index} style={{ backgroundColor: "#fdb8c0" }}>
                <strong>{part.value}</strong>
              </span>
            );
          } else if (!part.added) {
            // Display unchanged parts normally
            return (
              <span key={index}>
                <strong>{part.value}</strong>
              </span>
            );
          }
          // Skip added parts for the old component
          return null;
        } else if (type === "new") {
          // For the new component, highlight added parts in green
          if (part.added) {
            return (
              <span
                key={index}
                style={{ display: "inline", backgroundColor: "#acf2bd" }}
              >
                <strong>{part.value}</strong>
              </span>
            );
          } else if (!part.removed) {
            // Display unchanged parts normally
            return (
              <span key={index}>
                <strong>{part.value}</strong>
              </span>
            );
          }
          // Skip removed parts for the new component
          return null;
        }

        return null;
      })}
    </div>
  );
};
const transformRowsToObject = (rows) => {
  return rows.reduce((acc, row) => {
    // key is serviceType_bundleName || dataCenter_account
    const key = row[1] + "_" + row[2];
    acc[key] = row;
    return acc;
  }, {});
};
export const TableDiff = ({
  previousTable,
  currentTable,
  type,
  truncated = true,
}) => {
  // Transform rows for easier comparison
  const currentRows = transformRowsToObject(currentTable.rows);
  const previousRows = transformRowsToObject(previousTable.rows);

  const renderRow = (rowKey, rowData, contextType) => {
    const otherTableRows = contextType === "new" ? previousRows : currentRows;
    const otherRow = otherTableRows[rowKey];

    return currentTable.header.map((header, cellIndex) => {
      const cell = rowData[cellIndex];
      let cellColor = "none"; // Default no highlight

      if (type === "new" && !otherRow) {
        cellColor = "lightgreen"; // Entire row is new
      } else if (type === "new" && cell !== otherRow?.[cellIndex]) {
        cellColor = "yellow"; // Cell value changed
      }
      if (type === "old" && !otherRow) {
        cellColor = "salmon"; // Entire row is removed
      }

      // Render cell with color based on comparison
      return (
        <td
          key={cellIndex}
          style={{
            backgroundColor: cellColor,
            width: truncated ? "40px" : "auto",
            fontSize: "1.5em",
          }}
        >
          {truncated ? <TruncatedFormLabel>{cell}</TruncatedFormLabel> : cell}
        </td>
      );
    });
  };

  const rowsToRender = type === "new" ? currentRows : previousRows;

  return (
    <div className="table-responsive">
      <Table striped bordered hover className="table-fit">
        <thead>
          <tr>
            {currentTable.header.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(rowsToRender).map((rowKey) => (
            <tr key={rowKey}>
              {renderRow(rowKey, rowsToRender[rowKey], type)}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export const FlowGraph = ({ options, checked, onSet, markBoth }) => {
  const buildOrderStateStatusClass = (orderStatus) => {
    return orderStatus === "Cancel"
      ? "cancel"
      : orderStatus === "Suspend"
      ? "suspend"
      : orderStatus === "Trial Extension"
      ? "trialExtension"
      : orderStatus === "Trial To Paid"
      ? "trialToPaid"
      : orderStatus === "New"
      ? "new"
      : orderStatus === "Change"
      ? "change"
      : "";
  };
  const color = "#068BBF";
  return (
    <div>
      <Form className="flow-graph">
        {options.length === 1 && (
          <Form.Label>
            <strong>
              This is a new order
              <GiCheckMark style={{ color: "green" }} />
            </strong>
          </Form.Label>
        )}
        {options.length > 1 &&
          options.map((option, index) => {
            const parseOptions = option?.name?.split(" ")[0]||'Date Not available';
            return (
              <div
                title={"Subscription timeline"}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    onClick={() => onSet(option.id)}
                    title={option.name}
                    style={{
                      cursor: "pointer",
                      border:
                        option.id === checked.start&&markBoth===true || option.id === checked.end
                          ? `3px solid ${color}`
                          : "",
                    }}
                    className={`d-inline-block me-1 order-status ${buildOrderStateStatusClass(
                      option.state
                    )}`}
                  >
                    {" "}
                    <strong>
                      {option.state} at:{parseOptions}
                    </strong>
                  </div>
                </div>
                {index < options.length - 1 && (
                  <IoArrowForwardOutline
                    style={{
                      color: option.id === checked.start ? color : "",
                      fontSize: "20px",
                      display: "block",
                      transform: "translateX(-20%)",
                      marginTop: "10px",
                    }}
                  />
                )}
              </div>
            );
          })}
      </Form>
    </div>
  );
};

export const CopyButton = ({ onClickProp }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const handleClick = () => {
    onClickProp();
    setShow(true);
    setTimeout(() => setShow(false), 1000);
  };

  return (
    <OverlayTrigger
      show={show}
      placement="top"
      overlay={<Tooltip id="tooltip-top">Copied!</Tooltip>}
      target={target.current}
    >
      <Button ref={target} onClick={handleClick} variant="secondary">
        <FaRegCopy style={{ verticalAlign: "middle", marginTop: "-4px" }} />
      </Button>
    </OverlayTrigger>
  );
};
