import { useEffect, useState, useRef } from "react";

import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import { TiDownload } from 'react-icons/ti';


import { Amplify, API } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

import LeftSideBar from "../common/LeftSideBar";
import DataTableForOrder from '../common/DataTableForOrder';
import EnvDetails from '../../env-details.json';

import { useNavigate, useSearchParams } from 'react-router-dom';

import '../../scss/sub.scss';

import awsExports from '../../aws-exports';
import * as Utils from "../../common/utils";
import * as RBACUtils from '../../common/RBACUtils';
Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true
}

const ENV = Utils.getCurrentEnv(), STAGE = Utils.getCurrentStage();

const ListOrder = ({ signOut, user }) => {

  //#region states
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isInitDataLoaded = useRef(false);
  const prevTableParams = useRef({});
  const initData = {
    offers: [],
  };
  const [orders, setOrders] = useState({ results: [], count: 0 });
  const [data, setData] = useState(initData);
  const [orderLoadStatus, setOrderLoadStatus] = useState({ status: false, message: '', retryCount: 1 });
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchFilter, setSearchFilter] = useState({});

  //#endregion

  //#region local static data

  const INIT_FILTER = Utils.urlParamsToObj(searchParams);

  const offerMap = new Map();
  data['offers'].forEach(o => {
    offerMap.set(o.offerId, o);
  });

  //#endregion
  
  //#region functions

  const invokeFetchOrders = (page = 0, pageSize = 5, sortBy = [{ id: 'update_timestamp', desc: true }], filter = INIT_FILTER, forceUpdate = false) => {
    fetchOrders(page, pageSize, sortBy, filter, forceUpdate, false)
      .then(order => {
        setOrders(order)
      })
      .catch(err => { });
  };

  const parseTotalCount = (data) => {
    if (!data) return null;
    if (data.length > 0 && data[0]['total_count']) return parseInt(data[0]['total_count']);
    return null;
  };

  const sanitizeFilter = (filter) => {
    Object.keys(filter).forEach(k => {
      if (['offers'].indexOf(k) !== -1 && !filter[k]) delete filter[k];
      if (['create_timestamp', 'update_timestamp', 'due_date', 'on_status'].indexOf(k) !== -1 && filter[k].length === 0) delete filter[k];
    });
    return typeof filter === 'object' && Object.keys(filter).length > 0 ? JSON.stringify(filter) : {};
  };

  useEffect(() => {
    setTotalRecords(orders.count);
  }, [orders]);

  const downloadOrders = () => {
    if (orders.count <= Utils.DOWNLOAD_RECORDS_MAX_SIZE) {
      const downloadSub = new Promise(async (resolve, reject) => {
        let sanitized_filter = sanitizeFilter(searchFilter);
        let retries = 1;
        while (retries <= Utils.RETRY_COUNT) {
          try {
            let params = {
              response: true,
              responseType: 'blob',
              timeout: 1000 * 60 * 5,
              queryStringParameters: {
                stage: STAGE,
                region: user.pool.userPoolId.split('_')[0],
                sortby: 'created',
                size: orders.count || 0,
                dir: 'asc',
                filter: sanitized_filter
              }
            };

            // setSubLoadStatus({ status: true, retryCount: retries, message: 'secondary::Downloading subscriptions...' })
            const response = await API.get("SspBackendApi", "/download-orders", params);

            if (response && response.data) {
              // setSubLoadStatus({ status: false, retryCount: 4, message: '' })
              const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `orders-${new Date().toISOString()}.csv`);
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(url);
              return resolve(true);
            } else {
              return reject({ 'msg': 'Error:: order list is not downloaded. Please try again!' });
            }
          } catch (error) {
            retries++;
            if (retries > Utils.RETRY_COUNT) {
              return reject({ 'msg': 'Having error while downloading order list. Please try again!' });
            };
          }
        }
      });

      downloadSub.then().catch(err => {
        // setSubAlertMessage(err.msg);
        console.error(err.msg);
      });
    } 
  };

  const fetchOrders = (page = 0, pageSize = 5, sortBy = [{ id: 'update_timestamp', desc: true }], filter = {}, forceUpdate = false, isInit = false) => {
    setSearchFilter(filter);
    return new Promise(async (resolve, reject) => {
      let sanitized_filter = sanitizeFilter(filter);
      const curTableParam = {
        page: page,
        pageSize: pageSize,
        sortBy: sortBy,
        filter: sanitized_filter
      };
      if (Utils.jsonEquals(prevTableParams.current, curTableParam) && !forceUpdate) return reject({ results: [], count: 0 });
      if (!isInit) setOrderLoadStatus({ status: true, retryCount: 1, message: 'secondary::Fetching orders...' });

      prevTableParams.current = { ...curTableParam };
      let retries = 1, offset = page * pageSize;
      while (retries <= Utils.RETRY_COUNT) {
        try {
          let params = {
            response: true,
            queryStringParameters: {
              stage: STAGE,
              region: user.pool.userPoolId.split('_')[0],
              offset: offset,
              limit: pageSize,
              sortby: sortBy.length > 0 && sortBy[0].id ? sortBy[0].id : '',
              dir: sortBy.length > 0 ? (sortBy[0].desc === true ? 'desc' : 'asc') : '',
              filter: sanitized_filter
            }
          };
          // console.log(`params: ${JSON.stringify(params)}`);

          // trigger order using SSP backend order API
          const response = await API.get("SspBackendApi", "/all-orders", params);
          if (response && response.data) {
            let count = parseTotalCount(response.data) || 0;
            if (!isInit) setOrderLoadStatus({ ...orderLoadStatus, status: false, message: '', retryCount: 0 });
            return resolve({ results: response.data, count: count });
          } else {
            if (!isInit) setOrderLoadStatus({ ...orderLoadStatus, status: true, message: 'danger::Error occured while fetching data.', retryCount: retries });
          }
          return reject({ results: [], count: 0 });
        } catch (error) {
          let err = JSON.parse(JSON.stringify(error));
          if (parseInt(err.status) === 504) {
            console.error('API request "GET /all-orders" timed-out. Retrying...', JSON.stringify(error));
            retries++;
            if (retries <= Utils.RETRY_COUNT) {
              if (!isInit) setOrderLoadStatus({ ...orderLoadStatus, status: true, message: 'danger::Request timed-out, retrying...', retryCount: retries });
              await new Promise((resolve) => setTimeout(resolve, 1000));
            } else {
              if (!isInit) setOrderLoadStatus({ ...orderLoadStatus, status: true, message: 'danger::Retry limit reached, please reload the page.', retryCount: retries });
              return reject({ results: [], count: 0 });
            }
          } else {
            console.error('API request "GET /all-orders" failed.', JSON.stringify(error));
            if (!isInit) setOrderLoadStatus({ ...orderLoadStatus, status: true, message: 'danger::Error occured while fetching data.', retryCount: retries });
            return reject({ results: [], count: 0 });
          }
        }
      }

    });
  };

  //#endregion

  //#region load information 

  const loadOffers = () => {
    return new Promise(async (resolve, reject) => {
      let cachedOffers = Utils.fetchFromSessionStorage(user.username, 'offers', [], true);
      if (cachedOffers.length > 0) {
        return resolve(cachedOffers);
      }
      let params = {
        response: false,
        queryStringParameters: {
          'stage': STAGE,
          'path': 'offers',
          'region': user.pool.userPoolId.split('_')[0]
        }
      };
      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const jsonResponse = JSON.parse(response);
        const rOffers = JSON.parse(jsonResponse.body);
        if (rOffers && rOffers.length > 0) {
          Utils.setInSessionStorage(user.username, 'offers', rOffers, true);
          return resolve(rOffers);
        }
      }).catch((error) => {
        console.error(`Error occured while fetching catalog data.`, JSON.stringify(error));
        reject([]);
      });
    });
  };
  
  const loadInitData = async () => {
    setOrderLoadStatus({ status: true, retryCount: 1, message: 'secondary::Fetching orders...' });

    const initDataPromises = [
      loadOffers(),
      fetchOrders(0, 5, [{ id: 'update_timestamp', desc: true }], INIT_FILTER, false, false),

    ];
    Promise.all(initDataPromises).then(res => {
      if (res[0].length > 0 && res[1].results.length > 0) {
        setData({ ...data, offers: res[0] });
        isInitDataLoaded.current = true;
        setOrders(res[1]);
        setOrderLoadStatus({ ...orderLoadStatus, status: false, message: '', retryCount: 0 });
      }
    }).catch(error => {
      console.error('An error occurred while fetching initial data: ', JSON.stringify(error));
    });
  };

  useEffect(() => {
    const roleAttribute = user['attributes']['custom:role'];
    let roleValue = roleAttribute ? JSON.parse(roleAttribute) : RBACUtils.getDefaultUserRole();
    Utils.setInSessionStorage(user.username, 'userRole', roleValue);
    if (RBACUtils.hasSubscriptionAccess(user.username, 'NONE')) {
      navigate("/user/profile");
    } else {
      if (Object.keys(orders).filter(k => orders[k].length === 0).length === 0 && isInitDataLoaded.current) {
        invokeFetchOrders();
      } else {
        loadInitData();
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  //#endregion

  return (
    <Container fluid className="p-2 mb-4" >
      <Row className='m-0'>
        {/* Left sidebar */}
        <Col md={2} className='l-nav'>
          <LeftSideBar active='lo' username={user.username} />
        </Col>

        {/* Content */}
        <Col md={10}>
          <Container fluid data-bs-spy="scroll" data-bs-target='#navbar' data-bs-offset="0" className="scrollspy-example" tabIndex="0">

            {/* top heading bar */}
            <Row className='p-0'>
              <Col className='title-wrap d-flex align-items-center justify-content-between p-0'>
                <div className='d-flex align-items-center justify-content-start title-text'>
                  <div>Dashboard</div>
                </div>
                <div className='d-flex align-items-center justify-content-end title-settings'>
                  <div className='me-2'>{user.attributes.email}</div>
                  <Dropdown size='sm' as={ButtonGroup} className='me-2'>
                    <Button variant="outline-secondary" title={`Environment: ${ENV}, Stage: ${STAGE}`}>{EnvDetails.filter(e => e.envName === ENV)[0].dispName}</Button>
                    <Dropdown.Toggle split variant="outline-secondary" id="dropdown-split-basic" />
                    <Dropdown.Menu variant='outline-secondary' align='end'>
                      {
                        EnvDetails.map(e =>
                          Utils.includeEnvInDropdown(e.envName) &&
                          <Dropdown.Item title={`Environment: ${e.envName}, Stage: ${e.stageName}`} href={e.url}>{e.dispName}</Dropdown.Item>
                        )
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button size="sm" variant="outline-secondary" onClick={signOut}>Signout</Button>
                </div>
              </Col>
            </Row>

            <Row className='p-0'>
              <Col className='p-0'>
                <div className='d-flex align-items-center justify-content-between my-2'>
                  <div>
                    <div className='d-flex align-items-center justify-content-start w-full'>
                      <div><h4 className='mb-1'>Orders dashboard</h4></div>
                      {
                        orderLoadStatus['status'] &&
                        <>
                          {
                            (
                              (parseInt(orderLoadStatus['retryCount']) === -1 && orderLoadStatus['message'].split('::')[0] === 'secondary') ||
                              (parseInt(orderLoadStatus['retryCount']) !== -1 && parseInt(orderLoadStatus['retryCount']) <= Utils.RETRY_COUNT)
                            ) &&
                            <div className='ms-2' style={{ fontSize: '14px' }}>
                              <Spinner size='sm' variant='secondary' animation="border" role="status" />
                            </div>
                          }
                          <div className={`fw-500 ms-2 text-${orderLoadStatus['message'].split('::')[0]}`} style={{ fontSize: '14px' }}>{orderLoadStatus['message'].split('::').length > 0 ? orderLoadStatus['message'].split('::')[1] : orderLoadStatus['message']}</div>
                          {
                            parseInt(orderLoadStatus['retryCount']) <= Utils.RETRY_COUNT &&
                            <div className={`fw-500 ms-2 text-${orderLoadStatus['message'].split('::')[0]}`}>
                              {
                                parseInt(orderLoadStatus['retryCount']) > 0 &&
                                <i><small>(Attempt {parseInt(orderLoadStatus['retryCount'])} of 3)</small></i>
                              }
                            </div>

                          }


                        </>
                      }
                    </div>
                    <small className='text-muted'>A dashboard to manage orders in the environment</small>
                  </div>
                  {/* TODO:Change it if there will be implementation for "order" role */}
                  {orders?.count > 0 && (RBACUtils.hasSubscriptionAccess(user.username, 'WRITE') || RBACUtils.hasSubscriptionAccess(user.username,'READ'))? (
                    <div className='btn btn-secondary btn-sm d-flex align-items-center justify-content-start rounded-3' title='Download Orders data' onClick={() => downloadOrders()}>
                      <div className='download-sub-btn-icon'><TiDownload /></div>
                    </div>)
                    : ""}
                </div>
                <div>
                  {
                    isInitDataLoaded.current &&
                    <DataTableForOrder username={user.username} data={orders} onTableUpdated={invokeFetchOrders} region={user.pool.userPoolId.split('_')[0]} isLoading={orderLoadStatus['status']} totalRecords={totalRecords} stage={STAGE} />
                  }
                </div>
              </Col>
            </Row>

          </Container>
        </Col>
      </Row>

    </Container>
  );

};

export default withAuthenticator(ListOrder, withAuthenticatorOptions);