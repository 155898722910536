// react lib
import { useState, useEffect } from "react";

// react bootstrap components and style
import ListGroup from 'react-bootstrap/ListGroup'
import { ListGroupItem, Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Collapse from 'react-bootstrap/Collapse';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Accordion from 'react-bootstrap/Accordion';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';
import { BsFillPatchPlusFill, BsFillPatchMinusFill } from "react-icons/bs";
import { MdOutlineCheckBox, MdOutlineIndeterminateCheckBox, MdCheck, MdClose, MdTry } from "react-icons/md";

// stylesheet
import '../../scss/sub.scss';

// external libs
import { Scrollbars } from 'react-custom-scrollbars-2';
import ReactJson from '@microlink/react-json-view'
import { useNavigate } from "react-router-dom";
import cloneDeep from 'lodash.clonedeep';
import 'json-diff-kit/dist/viewer.css';

// internal components
import EntitlementData from "./EntitlementData";
import LeftSideBar from "../common/LeftSideBar";
import TopHeadingBar from "../common/TopHeadingBar";
import UserInputField from "./UserInputField";

// internal data and libs
import FieldData from '../../data/FieldData.json';
import * as Utils from "../../common/utils";
import * as RBACUtils from '../../common/RBACUtils';

// amplify imports & config
import { Amplify, API } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../../aws-exports';
Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true
}

//#region constants

const STAGE = Utils.getCurrentStage(), QRN = Utils.generateQrn();
const MAX_CHIPS = 5;
const FIXED_MANDATORY_FIELDS = ['provisioningId1', 'companyCity', 'fixedCount'];
// all fields which shall not be modifieable or removed by user from subscription
const NON_MODIFIEABLE_FIELDS = [...FIXED_MANDATORY_FIELDS, 'trial'];
const PID1_LINKED_FIELDS = ['orderNumber', 'provisioningId1', 'provisioningId2', 'subscriptionName', 'customerAccountNumber', 'bpLinkId', 'contractNumber', 'opportunityNumber', 'purchaseOrder'];

//#endregion

const CreateSub = ({ signOut, user }) => {

  //#region states & hooks

  const [subscription, setSubscription] = useState({ subData: {}, custData: {}, userOffers: [], userBundles: [], bundleData: {}, userApps: [], userCaps: {}, entData: {} });
  const [data, setData] = useState({ offers: [], bundles: [], apps: [] });
  const [fields, setFields] = useState([]);
  const [activeProfile, setActiveProfile] = useState('offers');
  const [offerDetails, setOfferDetails] = useState([]);
  const [bundleDetails, setBundleDetails] = useState([]);
  const [bundleSelectAllMap, setBundleSelectAllMap] = useState([]);
  const [simpleView, setSimpleView] = useState(true);
  const [toggleChip, setToggleChip] = useState({});
  const [subAlertVariant, setSubAlertVariant] = useState("primary");
  const [subAlertmessage, setSubAlertMessage] = useState("Creating subscription...");
  const [showSubStatusModal, setShowSubStatusModal] = useState(false);
  const [subStatusLogs, setSubStatusLogs] = useState('');
  const [showPayload, setShowPayload] = useState(false);
  const [subPackageSelection, setSubPackageSelection] = useState({ type: 'offer', value: 0 });
  const [bundleDisabledFields, setBundleDisabledFields] = useState({});
  const [subIdCheckStatus, setSubIdCheckStatus] = useState({ status: 'idle', message: '' });
  const [trialSub, setTrialSub] = useState(false);

  const navigate = useNavigate();

  //#endregion

  //#region local static data

  const bundleMap = new Map();
  data['bundles'].forEach(bundle => {
    bundleMap.set(bundle.bundleId, bundle);
  });

  const offerMap = new Map();
  const bundleOfferMap = new Map();
  const offerBundleMap = new Map();
  data['offers'].forEach(o => {
    offerMap.set(o.offerId, o);
    o.offerBundles.forEach(b => {
      if (bundleMap.get(b.bundleId) && bundleOfferMap.get(b.bundleId) === undefined) {
        bundleOfferMap.set(b.bundleId, o.offerId);
      }
    });
  });
  for (var i = 0; i < data['offers'].length; i++) {
    const tArr = [];
    for (var j = 0; j < data['offers'][i].offerBundles.length; j++) {
      if (bundleMap.get(data['offers'][i].offerBundles[j].bundleId)) tArr.push(data['offers'][i].offerBundles[j].bundleId);
    }
    offerBundleMap.set(data['offers'][i].offerId, tArr);
  }

  const applicationMap = new Map();
  const applicationTypeToId = new Map();
  const capabilityMap = new Map();
  data['bundles'].forEach(b => {
    b.bundleCapabilities.forEach(c => {
      if (applicationMap.get(c.applicationId) === undefined) {
        applicationMap.set(c.applicationId, c);
      }
      if (applicationTypeToId.get(c.applicationName) === undefined) {
        applicationTypeToId.set(c.applicationName, c.applicationId);
      }
      if (capabilityMap.get(c.capabilityId) === undefined) {
        capabilityMap.set(c.capabilityId, c);
      }
    });
  });

  const pcApplicationMap = new Map();
  data['apps'].forEach((a) => {
    if (pcApplicationMap.get(applicationTypeToId.get(a.applicationName)) === undefined) {
      pcApplicationMap.set(applicationTypeToId.get(a.applicationName), a);
    }
  });

  let bundleOrderFields = [...FIXED_MANDATORY_FIELDS];
  subscription['userBundles'].forEach(bId => {
    bundleMap.get(bId).bundleOrderDetails.forEach(f => {
      if (bundleOrderFields.indexOf(f) === -1) {
        bundleOrderFields.push(f);
      }
    });
  });

  //#endregion

  //#region functions

  const handleSubscriptionData = (event) => {
    if (event.target.name === 'provisioningId1') {
      updateSubscriptionId(event.target.value);
    } else {
      subscription['subData'][event.target.name] = event.target.value;
      setSubscription(cloneDeep(subscription));
    }
  };

  const handleCustomerData = (event) => {
    subscription['custData'][event.target.name] = event.target.value;
    setSubscription(cloneDeep(subscription));
  }

  const updateSubscriptionId = (value) => {
    PID1_LINKED_FIELDS.forEach(k => {
      if (k in subscription['subData']) subscription['subData'][k] = value;
    });
    Object.keys(subscription['bundleData']).forEach(bId => {
      PID1_LINKED_FIELDS.forEach(k => {
        if (k in subscription['bundleData'][bId]) subscription['bundleData'][bId][k] = value;
      });
    });
    setSubscription(cloneDeep(subscription));
    if (subIdCheckStatus['stauts'] !== 'idle') setSubIdCheckStatus({ status: 'idle', message: '' });
  };

  const handleMultipleOfferSelect = (offers) => {
    offers.forEach(oid => {
      if (subscription['userOffers'].indexOf(oid) === -1) {
        subscription['userOffers'].push(oid);
      } else {
        subscription['userOffers'].splice(subscription['userOffers'].indexOf(oid), 1);
        offerBundleMap.get(oid).forEach((bundleId) => {
          if (subscription['userBundles'].indexOf(bundleId) !== -1) {
            subscription['userBundles'].splice(subscription['userBundles'].indexOf(bundleId), 1);
          }
        });
      }
      if (bundleSelectAllMap.indexOf(oid) !== -1) {
        bundleSelectAllMap.splice(bundleSelectAllMap.indexOf(oid), 1);
        setBundleSelectAllMap(bundleSelectAllMap.slice());
      }
    });
    subscription['userApps'].length = 0;
    subscription['userCaps'] = {};
    subscription['bundleData'] = {};
    subscription['userBundles'].forEach(bid => {
      bundleMap.get(bid).bundleCapabilities.forEach(c => {
        if (subscription['userApps'].indexOf(c.applicationId) === -1) {
          subscription['userApps'].push(c.applicationId);
          subscription['userCaps'][c.applicationId] = [c.capabilityId];
        } else {
          if (subscription['userCaps'][c.applicationId].indexOf(c.capabilityId) === -1) {
            subscription['userCaps'][c.applicationId].push(c.capabilityId);
          }
        }
      });
      subscription['bundleData'][bid] = initBundleData(bid);
    });

    setSubscription(cloneDeep(subscription));
  };

  const handleOfferSelect = (oid) => {
    handleMultipleOfferSelect([oid]);
  };

  const handleMultipleBundleSelect = (bundles) => {
    bundles.forEach(bid => {
      if (subscription['userBundles'].indexOf(bid) === -1) {
        subscription['userBundles'].push(bid);
      } else {
        subscription['userBundles'].splice(subscription['userBundles'].indexOf(bid), 1);
      }
    });

    subscription['userApps'].length = 0;
    subscription['userCaps'] = {};
    subscription['bundleData'] = {};
    subscription['userBundles'].forEach(bid => {
      bundleMap.get(bid).bundleCapabilities.forEach(c => {
        if (subscription['userApps'].indexOf(c.applicationId) === -1) {
          subscription['userApps'].push(c.applicationId);
          subscription['userCaps'][c.applicationId] = [c.capabilityId];
        } else {
          if (subscription['userCaps'][c.applicationId].indexOf(c.capabilityId) === -1) {
            subscription['userCaps'][c.applicationId].push(c.capabilityId);
          }
        }
      });
      subscription['bundleData'][bid] = initBundleData(bid);
    });
    setSubscription(cloneDeep(subscription));
  };

  const handleBundleSelect = (bid) => {
    handleMultipleBundleSelect([bid]);
  };

  const handleButtonClick = (action) => {
    if (activeProfile === 'offers') {
      setActiveProfile('details');
    } else if (activeProfile === 'details') {
      if (action === 'next')
        setActiveProfile('review');
      else
        setActiveProfile('offers');
    } else if (activeProfile === 'review') {
      if (action === 'next') {
        setShowSubStatusModal(true);
        if (subIdCheckStatus['status'] === 'idle') handleSubIdAvailableCheck(subscription['subData']['provisioningId1']);
        setSubAlertVariant('secondary');
        setSubAlertMessage("");
      } else {
        setActiveProfile('details');
      }
    }
  };

  const triggerNewSubscriptionRequest = async () => {

    setSubAlertVariant('primary');
    setSubAlertMessage("Creating a subscription...");

    // prepare payload for subscription request
    let payload = buildPayload();

    let params = {
      'stage': STAGE,
      'region': user.pool.userPoolId.split('_')[0],
      'payload': payload,
      'action': 'create'
    };

    // trigger subscription using SSP backend subscription API
    await API.post("SspBackendApi", "/subscription", {
      body: params,
      headers: {
        "Content-Type": "application/json"
      }
    }).then((response) => {
      setSubAlertVariant("success");
      setSubAlertMessage("Subscription created successfully.");
      setSubStatusLogs(response.output.logs);
    }).catch((error) => {
      setSubAlertVariant("danger");
      setSubAlertMessage("Error occurred while creating subscription.");
      setSubStatusLogs(error.response);
    });
  }

  const handleOfferDetailsClick = (offerId) => {
    const od = offerDetails;
    if (od.indexOf(offerId) === -1) {
      od.push(offerId);
    } else {
      od.splice(od.indexOf(offerId), 1);
    }
    setOfferDetails(od.slice());
  };

  const handleBundleDetailsClick = (bundleId) => {
    const bd = bundleDetails;
    if (bd.indexOf(bundleId) === -1) {
      bd.push(bundleId);
    } else {
      bd.splice(bd.indexOf(bundleId), 1);
    }
    setBundleDetails(bd.slice());
  };

  const handleBundleSelectAllClick = (oid) => {
    const bsam = bundleSelectAllMap;
    if (bsam.indexOf(oid) === -1) {
      bsam.push(oid);
      offerMap.get(oid).offerBundles.forEach(b => {
        if (bundleMap.get(b.bundleId) && subscription['userBundles'].indexOf(b.bundleId) === -1) {
          subscription['userBundles'].push(b.bundleId);
        }
      });
    } else {
      bsam.splice(bsam.indexOf(oid), 1);
      offerMap.get(oid).offerBundles.forEach((b) => {
        if (bundleMap.get(b.bundleId) && subscription['userBundles'].indexOf(b.bundleId) !== -1) {
          subscription['userBundles'].splice(subscription['userBundles'].indexOf(b.bundleId), 1);
        }
      });
    }

    subscription['userApps'].length = 0;
    subscription['userCaps'] = {};
    subscription['bundleData'] = {};
    subscription['userBundles'].forEach((bId) => {
      bundleMap.get(bId).bundleCapabilities.forEach((c) => {
        if (subscription['userApps'].indexOf(c.applicationId) === -1) {
          subscription['userApps'].push(c.applicationId);
          subscription['userCaps'][c.applicationId] = [c.capabilityId];
        } else {
          if (subscription['userCaps'][c.applicationId].indexOf(c.capabilityId) === -1) {
            subscription['userCaps'][c.applicationId].push(c.capabilityId);
          }
        }
      });
      subscription['bundleData'][bId] = initBundleData(bId);
    });
    setSubscription(cloneDeep(subscription));
    setBundleSelectAllMap(bsam.slice());
  };

  const initBundleData = (bid) => {
    let bundleData = {};
    let oid = bundleOfferMap.get(bid);
    fields.filter(f => f.category === 'bundle').forEach(f => {
      if (f.use && isFieldAllowedForUserOffers(f, oid)) {
        switch (f.type) {
          case 'text':
            bundleData[f.id] = f.value;
            break;
          case 'datetime-local':
            bundleData[f.id] = Utils.toDefaultFormat(f.value);
            break;
          default:
            break;
        }
        switch (f.id) {
          case "qrn":
          case "associatedQrn":
            if (bundleData[f.id]) bundleData[f.id] = QRN;
            break;
          default:
            break;
        }
      }
    });
    bundleData['serviceType'] = bundleMap.get(bid).bundleServiceType;
    bundleData['systemType'] = offerMap.get(bundleOfferMap.get(bid)).offerSystemType;
    bundleData['chargePlanId'] = '000000000000' + Utils.parseChargePlanId(bundleMap.get(bid).bundleSmartCode);
    return bundleData;
  }

  const isVisibleInSimpleView = (field = '', arr = [], bId = -1) => {

    if (!simpleView) return !simpleView;
    let x = false;
    if (field !== '') {
      x = bId === -1 ? bundleOrderFields.indexOf(field) !== -1 : bundleMap.get(bId).bundleOrderDetails.indexOf(field) !== -1;
    } else {
      for (let i = 0; i < arr.length; i++) {
        if (bId === -1 ? bundleOrderFields.indexOf(arr[i]) !== -1 : bundleMap.get(bId).bundleOrderDetails.indexOf(arr[i]) !== -1) {
          x = true;
          break;
        } else {
          continue;
        }
      }
    }
    return !simpleView || x;
  }

  const setPerBundleField = (event, b) => {
    let bundleData = { ...subscription['bundleData'][b] };
    bundleData[event.target.name] = event.target.value;
    subscription['bundleData'][b] = bundleData;
    setSubscription(cloneDeep(subscription));
  };

  const buildPayload = () => {
    return {
      orderNumber: subscription['subData'] ? subscription['subData']['provisioningId1'] : '',
      lineItems: subscription['userBundles'].map(b => getPayloadForBundle(subscription, b))
    };
  };

  const shouldShowApp = (aid) => {
    if (subPackageSelection['type'] === 'offer') {
      if (subPackageSelection['value'] === 0) return true;

      return offerMap.get(subPackageSelection['value']).offerBundles.filter(b => bundleMap.get(b.bundleId) && bundleMap.get(b.bundleId).bundleCapabilities.filter(c => c.applicationId === aid).length > 0).length > 0;
    } else if (subPackageSelection['type'] === 'bundle') {
      return bundleMap.get(subPackageSelection['value']).bundleCapabilities.filter(c => c.applicationId === aid).length > 0;
    }
  }

  const shouldShowCap = (cid) => {
    if (subPackageSelection['type'] === 'offer') {
      if (subPackageSelection['value'] === 0) return true;
      let tarr = [];
      offerMap.get(subPackageSelection['value']).offerBundles.forEach(b => bundleMap.get(b.bundleId) && bundleMap.get(b.bundleId).bundleCapabilities.forEach(bc => tarr.push(bc.capabilityId)));
      return tarr.indexOf(cid) !== -1;
    } else if (subPackageSelection['type'] === 'bundle') {
      return bundleMap.get(subPackageSelection['value']).bundleCapabilities.filter(bc => bc.capabilityId === cid).length > 0;
    }
  }

  const handleOfferBundleSelect = (type, value) => {
    if (subPackageSelection['type'] === type && subPackageSelection['value'] === value) {
      setSubPackageSelection({ ...subPackageSelection, type: 'offer', value: 0 });
    } else {
      setSubPackageSelection({ ...subPackageSelection, type: type, value: value });
    }
  }

  const formatValueForInput = (value) => {
    if (value === 'NULL') return '';
    return value;
  }

  const formatValueForPayload = (key, value) => {
    let field = getField(key);
    if (value === 'NULL' || value === '') {
      if (field && field['type'] === 'datetime-local') return '';
      return value === 'NULL' ? null : '';
    } else {
      if (field && field['type'] === 'datetime-local') return Utils.toCBSPFormat(value);
      return value;
    }
  }

  const findFieldPosition = (f) => {
    let field = fields.filter(field => field.id === f);
    return field.length > 0 ? field[0]['isSsd'] ? 1 : 0 : 1;
  }

  const isFieldUsed = (f, bid) => {
    let field = getField(f);
    return field ? (field['use'] && isFieldAllowedForUserOffers(f, bundleOfferMap.get(bid))) : false;
  }

  const isFieldDisabledForBundle = (f, bid) => {
    return bundleDisabledFields[bid] && bundleDisabledFields[bid].indexOf(f) !== -1 ? true : false
  };

  const removeField = (id, bid = -1) => {
    let field = getField(id);
    if (field) {
      let idx = fields.findIndex(f => f.id === id);
      if (idx !== -1) {
        if (bid === -1) {
          fields[idx]['use'] = false;
          setFields(fields.slice());
        } else {
          if (bundleDisabledFields[bid]) bundleDisabledFields[bid].push(id);
          else bundleDisabledFields[bid] = [id];
          setBundleDisabledFields({ ...bundleDisabledFields });
        }
      }
    }
  };

  const getPayloadForBundle = (sub, b) => {

    let lineItem = { 'systemSpecificData': {} };

    if (!sub['bundleData'][b]) return {};

    Object.keys(sub['subData']).forEach(k => {
      if (isFieldUsed(k, b)) {
        let pos = findFieldPosition(k);
        let val = formatValueForPayload(k, sub['subData'][k]);
        switch (pos) {
          case 0:
            lineItem[k] = val;
            break;
          case 1:
            lineItem['systemSpecificData'][k] = val;
            break;
          case 2:
            lineItem[k] = val;
            lineItem['systemSpecificData'][k] = val;
            break;
          default:
            break;
        }
      }
    });
    Object.keys(sub['custData']).forEach(k => {
      if (isFieldUsed(k, b)) {
        let pos = findFieldPosition(k);
        let val = formatValueForPayload(k, sub['custData'][k]);
        switch (pos) {
          case 0:
            lineItem[k] = val;
            break;
          case 1:
            lineItem['systemSpecificData'][k] = val;
            break;
          case 2:
            lineItem[k] = val;
            lineItem['systemSpecificData'][k] = val;
            break;
          default:
            break;
        }
      }
    });

    Object.keys(sub['bundleData'][b]).forEach(k => {
      if (isFieldUsed(k, b) && !isFieldDisabledForBundle(k, b)) {
        let pos = findFieldPosition(k);
        let val = formatValueForPayload(k, sub['bundleData'][b][k]);
        switch (pos) {
          case 0:
            lineItem[k] = val;
            break;
          case 1:
            lineItem['systemSpecificData'][k] = val;
            break;
          case 2:
            lineItem[k] = val;
            lineItem['systemSpecificData'][k] = val;
            break;
          default:
            break;
        }
      }
    });

    let ssd = lineItem['systemSpecificData'];
    delete lineItem['systemSpecificData'];
    let l1 = Object.keys(lineItem).sort().reduce((o, k) => { o[k] = lineItem[k]; return o; }, {});
    l1['systemSpecificData'] = Object.keys(ssd).sort().reduce((o, k) => { o[k] = ssd[k]; return o; }, {});

    return l1;
  };

  const getBundleOrderDetails = (bid) => {
    let data = {};
    bundleMap.get(bid).bundleOrderDetails.forEach(f => {
      let val = '';
      if (subscription['bundleData'][bid] && subscription['bundleData'][bid][f]) {
        val = subscription['bundleData'][bid][f];
      } else if (subscription['custData'][f]) {
        val = subscription['custData'][f];
      } else if (subscription['subData'][f]) {
        val = subscription['subData'][f];
      }
      data[f] = val;
    });
    return data;
  };

  const isAllowedToCreateSubscription = () => {
    let subIdAvailable = subIdCheckStatus['status'] === 'available';
    let offerBundleMissing = subscription['userOffers'].length > 0 && subscription['userBundles'].length > 0;
    let reqSubDataMissing = Object.keys(subscription['subData']).filter(f => bundleOrderFields.indexOf(f) !== -1 && Utils.isEmpty(subscription['subData'][f])).length === 0;
    let reqCustDataMissing = Object.keys(subscription['custData']).filter(f => bundleOrderFields.indexOf(f) !== -1 && Utils.isEmpty(subscription['custData'][f])).length === 0;
    let reqBundleDataMissing = Object.keys(subscription['bundleData']).map(Number).filter(b => Object.keys(subscription['bundleData'][b]).filter(f => bundleMap.get(b) && bundleMap.get(b).bundleOrderDetails.indexOf(f) !== -1 && Utils.isEmpty(subscription['bundleData'][b][f])).length !== 0).length === 0;
    return subIdAvailable && offerBundleMissing && reqSubDataMissing && reqCustDataMissing && reqBundleDataMissing;
  };

  const getFieldTitle = (title, id) => {
    return !Utils.isEmpty(title) ? title : Utils.displayTitle(id);
  };

  const getFieldDescription = (desc, id) => {
    return !Utils.isEmpty(desc) ? desc : Utils.displayTitle(id);
  };

  const getField = (id) => {
    let field = fields.filter(f => f.id === id);
    return field.length > 0 ? field[0] : null;
  };

  const isFieldAllowedForUserOffers = (field, oid = null) => {
    if (field.offers) {
      return field.offers === 'ALL' || (Utils.intersect(oid ? [oid] : subscription['userOffers'], field.offers).length > 0);
    }
    return true;
  };

  const shouldRenderField = (field) => {
    return isVisibleInSimpleView(field.id) && field.use && isFieldAllowedForUserOffers(field);
  };

  const handleSubIdAvailableCheck = async (id) => {
    setSubIdCheckStatus({ ...subIdCheckStatus, status: 'checking', message: 'Checking availability...' });

    const response = await isSubscriptionIdAvailable(id);
    setSubIdCheckStatus({ ...subIdCheckStatus, status: response.isAvailable ? 'available' : 'unavailable', message: response.message });

    // setTimeout(() => {
    //   // let response = { isAvailable: true, message: 'Subscription Id available' };
    //   let response = { isAvailable: false, message: 'Subscription Id unavailable' };
    //   setSubIdCheckStatus({ ...subIdCheckStatus, status: response.isAvailable ? 'available' : 'unavailable', message: response.message });
    // }, 2000);
  };

  const isSubscriptionIdAvailable = async (id) => {
    return new Promise(async (resolve, reject) => {

      let params = {
        response: false,
        queryStringParameters: {
          'stage': STAGE,
          'id': id,
          'region': user.pool.userPoolId.split('_')[0]
        }
      };

      await API.get("SspBackendApi", "/subscription/availability", params)
        .then(response => {
          return resolve(response);
        }).catch(error => {
          console.log(`Error occurred while checking subscription id availability: ${error}`);
          return reject({ isAvailable: false, message: 'Error occurred, try again.' });
        });

    });
  };

  const handleTrialSubscription = () => {
    let trialSubState = !trialSub;
    fields.filter(f => f.id === 'trial')[0].use = trialSubState;
    if (trialSubState) {
      Object.keys(subscription['bundleData']).forEach(b => {
        subscription['bundleData'][b]['trial'] = 'Y';
      });
    } else {
      Object.keys(subscription['bundleData']).forEach(b => {
        delete subscription['bundleData'][b]['trial'];
      });
    }
    setTrialSub(trialSubState);
  };

  //#endregion

  //#region load catalog information 

  const loadOffers = () => {
    return new Promise(async (resolve, reject) => {
      let cachedOffers = Utils.fetchFromSessionStorage(user.username, 'offers', [], true);
      if (cachedOffers.length > 0) {
        return resolve(cachedOffers);
      }
      let params = {
        response: false,
        queryStringParameters: {
          'stage': STAGE,
          'path': 'offers',
          'region': user.pool.userPoolId.split('_')[0]
        }
      };
      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const rOffers = JSON.parse(JSON.parse(response).body);
        if (rOffers && rOffers.length > 0) {
          Utils.setInSessionStorage(user.username, 'offers', rOffers, true);
          return resolve(rOffers);
        }
      }).catch((error) => {
        console.error(JSON.stringify(error));
        reject([]);
      });
    });
  };

  const loadBundles = () => {
    return new Promise(async (resolve, reject) => {
      let cachedBundles = Utils.fetchFromSessionStorage(user.username, 'bundles', [], true);
      if (cachedBundles.length > 0) {
        return resolve(cachedBundles);
      }

      let params = {
        response: false,
        queryStringParameters: {
          'stage': STAGE,
          'path': 'bundles',
          'region': user.pool.userPoolId.split('_')[0]
        }
      };

      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const rBundles = JSON.parse(JSON.parse(response).body);
        if (rBundles && rBundles.length > 0) {
          Utils.setInSessionStorage(user.username, 'bundles', rBundles, true);
          return resolve(rBundles);
        }
      }).catch((error) => {
        console.error(JSON.stringify(error));
        return reject([]);
      });
    });
  };

  const loadApps = () => {
    return new Promise(async (resolve, reject) => {
      let cachedApps = Utils.fetchFromSessionStorage(user.username, 'apps', [], true);
      if (cachedApps.length > 0) {
        return resolve(cachedApps);
      }
      let params = {
        response: false,
        queryStringParameters: {
          'stage': STAGE,
          'path': 'applications',
          'region': user.pool.userPoolId.split('_')[0]
        }
      };
      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const rApps = JSON.parse(JSON.parse(response).body);
        if (rApps && rApps.length > 0) {
          Utils.setInSessionStorage(user.username, 'apps', rApps, true);
          return resolve(rApps);
        }
      }).catch((error) => {
        console.error(JSON.stringify(error));
        reject([]);
      });
    });
  };

  const loadUserFieldsCustomizations = () => {
    return new Promise(async (resolve, reject) => {
      let cachedUserFC = Utils.fetchFromSessionStorage(user.username, 'fc', []);
      if (cachedUserFC.length > 0) {
        return resolve(cachedUserFC);
      }
      let params = {
        response: false,
        queryStringParameters: {
          'stage': STAGE,
          'id': user.username,
          'region': user.pool.userPoolId.split('_')[0]
        }
      };

      await API.get("SspBackendApi", "/userinfo", params)
        .then(response => {
          if (response.status === 'success') {
            let res = response.data && response.data.subTemplate ? response.data.subTemplate : [];
            Utils.setInSessionStorage(user.username, 'fc', res);
            return resolve(res);
          } else {
            Utils.setInSessionStorage(user.username, 'fc', []);
            return reject([]);
          }
        }).catch(error => {
          console.log(`Error occurred while fetching field customizations for user: ${error.message}`);
          Utils.setInSessionStorage(user.username, 'fc', []);
          return reject([]);
        });
    });
  };

  const loadInitData = async () => {

    const initDataPromises = [
      loadOffers(),
      loadBundles(),
      loadApps(),
      loadUserFieldsCustomizations()
    ];
    Promise.all(initDataPromises).then(res => {
      if (res[0].length > 0 && res[1].length > 0 && res[2].length > 0) {
        setData({
          ...data,
          offers: res[0].filter(o => RBACUtils.hasAccessToOffer(user.username, o.offerId)),
          bundles: res[1].filter(b => RBACUtils.hasAccessToBundle(user.username, b.bundleId)),
          apps: res[2]
        });
      }
      setFields(mergeFields(FieldData, res[3]));
    }).catch(error => {
      console.error('An error occurred while fetching initial data: ', error);
    });

  };

  const findByID = (id, arr) => {
    let idx = arr.findIndex(f => f.id === id);
    if (idx !== -1) return arr[idx];
    else return {};
  };

  const mergeFields = (orig, user) => {
    let fields = JSON.parse(JSON.stringify(orig));
    user.forEach(f => {
      let field = findByID(f.id, fields);
      if (Object.keys(field).length > 0) {
        Object.keys(f).forEach(k => {
          if (k !== 'id') field[k] = f[k];
        });
      } else {
        fields.push(f);
      }
    });
    return fields;
  };

  useEffect(() => {
    const roleAttribute = user['attributes']['custom:role'];
    let roleValue = roleAttribute ? JSON.parse(roleAttribute) : RBACUtils.getDefaultUserRole();
    Utils.setInSessionStorage(user.username, 'userRole', roleValue);
    initActivities();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let initSub = {}, initCust = {};
    fields.filter(f => f.category === 'subscription' || f.category === 'customer').forEach(f => {
      switch (f.type) {
        case 'text':
          if (f.category === 'subscription') initSub[f.id] = f.value;
          else if (f.category === 'customer') initCust[f.id] = f.value;
          break;
        case 'datetime-local':
          if (f.category === 'subscription') initSub[f.id] = Utils.toDefaultFormat(f.value);
          else if (f.category === 'customer') initCust[f.id] = Utils.toDefaultFormat(f.value);
          break;
        default:
          break;
      }
      switch (f.id) {
        case "qrn":
        case "associatedQrn":
          if (initSub[f.id]) initSub[f.id] = QRN;
          else if (initCust[f.id]) initCust[f.id] = QRN;
          break;
        default:
          break;
      }
    });
    setSubscription({ ...subscription, subData: { ...initSub }, custData: { ...initCust } })
  }, [fields]); // eslint-disable-line react-hooks/exhaustive-deps

  const initActivities = () => {
    if (RBACUtils.hasSubscriptionAccess(user.username, 'NONE')) {
      navigate("/user/profile");
    } else if (!RBACUtils.hasSubscriptionAccess(user.username, 'WRITE')) {
      navigate("/sub/view");
    } else {
      window.history.pushState("", "Title", window.location.pathname);
      if (Object.keys(data).filter(k => data[k].length === 0).length > 0 || fields.length === 0) {
        loadInitData();
      }
    }
  };

  //#endregion

  return (

    <Container fluid className="p-2 mb-4">
      <Row className='m-0'>

        {/* Left sidebar */}
        <Col md={2} className='l-nav'>
          <LeftSideBar active='cs' username={user.username} />
        </Col>

        {/* Content */}
        <Col md={8}>
          <Container fluid data-bs-spy="scroll" data-bs-target='#navbar' data-bs-offset="0" tabIndex="0">

            {/* top heading bar */}
            <TopHeadingBar title='Create subscription' user={user} signOut={signOut} />

            {/* pages area */}
            <Tabs className="mb-3 steps" fill activeKey={activeProfile} onSelect={(p) => setActiveProfile(p)}>

              {/* offer page */}
              <Tab eventKey="offers" title="Select offer">
                <Row className='mx-0'>
                  <Col className='border-0 rounded px-0 py-0 mx-1' style={{ backgroundColor: '#fff' }}>
                    <h4 className='text-center section-title' style={{ backgroundColor: '#c4e4ff', color: '#333', fontWeight: 'normal' }}>Offers</h4>
                    {
                      data['offers'].length === 0 && data['bundles'].length === 0 &&
                      <div className='d-flex align-items-center justify-content-center' style={{ backgroundColor: '#f6fbff', height: 800 }}>
                        <div className="spinner-border spinner-border-lg" style={{ color: '#abd8ff' }} role="status"></div>
                        <p className='p-0' style={{ marginTop: '80px', marginLeft: '-70px', color: '#89adcc' }}>Fetching offers...</p>
                      </div>
                    }
                    <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />} style={{ height: 800 }}>
                      <ListGroup as="ul" className='list-group-light offer-block'>
                        {
                          data['offers'].map(o =>
                            o.offerBundles.length > 0 &&
                            <ListGroupItem as="li" action key={"offer-" + o.offerId}>
                              <input className="form-check-input offer-check" style={{ cursor: "pointer" }} type="checkbox" checked={subscription['userOffers'].indexOf(o.offerId) === -1 ? false : true} id={"offer-" + o.offerId} onChange={() => handleOfferSelect(o.offerId)} />
                              <label className="form-check-label text-truncate" style={{ cursor: "pointer", padding: "0px 0px 0px 8px", maxWidth: "85%" }} htmlFor={"offer-" + o.offerId}>{o.offerCommercialName}</label>
                              <div className="float-end" style={{ cursor: "pointer", color: '#9dceff', fontSize: '22px', marginTop: '-4px' }} onClick={() => handleOfferDetailsClick(o.offerId)} aria-controls={o.offerId} aria-expanded={offerDetails.indexOf(o.offerId) !== -1}>
                                {offerDetails.indexOf(o.offerId) !== -1 ? <BsFillPatchMinusFill /> : <BsFillPatchPlusFill />}
                              </div>
                              <Collapse in={offerDetails.indexOf(o.offerId) !== -1}>
                                <div id={o.offerId}>
                                  <div className="px-3 py-2 mt-2 text-justify" style={{ backgroundColor: "rgba(255, 255, 255, 1)", borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>{o.offerDescription}</div>
                                  <p className='mb-2' style={{ backgroundColor: '#fff', padding: '8px 12px', borderRadius: '5px', borderTop: '1px solid #e4f2ff', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}>System Type: <span style={{ fontWeight: '500' }}>{o.offerSystemType}</span></p>
                                </div>
                              </Collapse>
                            </ListGroupItem>)
                        }
                      </ListGroup>
                    </Scrollbars>
                  </Col>
                  <Col className='border-0 rounded px-0 py-0 mx-1' style={{ backgroundColor: "#fff" }}>
                    <h4 className='text-center section-title' style={{ backgroundColor: '#e2bff1' }}>Bundles</h4>
                    <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />} style={{ height: 800 }}>
                      {
                        subscription['userOffers'].map(o =>
                          <Tabs id={o} className="bundle-tabs" key={'sel-offer-' + o}>
                            <Tab eventKey={o} key={o} title={offerMap.get(o).offerCommercialName}>
                              <OverlayTrigger placement="top" trigger={['hover', 'focus']} delay={50} overlay={<Tooltip className='bs-tooltip' id={'offer-bundle-tooltip-' + o}>{bundleSelectAllMap.indexOf(o) === -1 ? "Select all bundles" : "Unselect all bundles"}</Tooltip>}>
                                <span className='float-end bundle-s-all' onClick={() => handleBundleSelectAllClick(o)}>
                                  {bundleSelectAllMap.indexOf(o) === -1 ? <MdOutlineCheckBox /> : <MdOutlineIndeterminateCheckBox />}
                                </span>
                              </OverlayTrigger>
                              <ListGroup as="ul" className='list-group-light bundle-block'>
                                {
                                  offerMap.get(o).offerBundles.map(b =>
                                    bundleMap.get(b.bundleId) &&
                                    <ListGroupItem as="li" action key={"bundle-" + b.bundleId}>
                                      <input className="form-check-input bundle-check" checked={subscription['userBundles'].indexOf(b.bundleId) === -1 ? false : true} style={{ cursor: "pointer" }} type="checkbox" id={"bundle-" + b.bundleId} onChange={() => handleBundleSelect(b.bundleId)} />&nbsp;&nbsp;
                                      <label className="form-check-label text-truncate" style={{ maxWidth: '85%', cursor: 'pointer' }} htmlFor={"bundle-" + b.bundleId}>{bundleMap.get(b.bundleId).bundleCommercialName}</label>
                                      <div className="float-end" style={{ cursor: "pointer", color: '#d9aaec', fontSize: '22px', marginTop: '-4px' }} onClick={() => handleBundleDetailsClick(b.bundleId)} aria-controls={b.bundleId} aria-expanded={bundleDetails.indexOf(b.bundleId) !== -1}>
                                        {bundleDetails.indexOf(b.bundleId) !== -1 ? <BsFillPatchMinusFill /> : <BsFillPatchPlusFill />}
                                      </div>
                                      <Collapse in={bundleDetails.indexOf(b.bundleId) !== -1}>
                                        <div id={b.bundleId}>
                                          <div className="px-3 py-2 mt-2 text-justify" style={{ backgroundColor: "#fff", borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>{bundleMap.get(b.bundleId).bundleDescription}</div>
                                          <p className='mb-0' style={{ backgroundColor: '#fff', padding: '8px 12px', borderRadius: '0px', borderTop: '1px solid #f6ecfb' }}>Service Type: <span style={{ fontWeight: '500' }}>{bundleMap.get(b.bundleId).bundleServiceType}</span></p>
                                          <p className='mb-0' style={{ backgroundColor: '#fff', padding: '8px 12px', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', borderTop: '1px solid #f6ecfb' }}>Material Code: <span style={{ fontWeight: '500' }}>{bundleMap.get(b.bundleId).bundleSmartCode}</span></p>
                                        </div>
                                      </Collapse>
                                    </ListGroupItem>)
                                }
                              </ListGroup>
                            </Tab>
                          </Tabs>)
                      }
                    </Scrollbars>
                  </Col>
                  <Col className='border-0 rounded px-0 py-0 mx-1 cap-block' style={{ backgroundColor: "#fff" }}>
                    <h4 className='text-center section-title cap-title'>Capabilities</h4>
                    <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />} style={{ height: 800 }}>
                      {
                        subscription['userApps'].map(a =>
                          <Tabs id={a} className="app-tabs" key={'sel-app-' + a}>
                            <Tab className='app-tab' eventKey={a} key={a} title={applicationMap.get(a) ? applicationMap.get(a).applicationCommercialName : applicationMap.get(a).applicationName}>
                              <div className="chip-container nav justify-content-center">
                                {
                                  subscription['userCaps'][a].map(c =>
                                    <OverlayTrigger trigger={['hover', 'focus']} delay={250} key={'sel-cap-' + c} placement="auto" rootClose={true} overlay={
                                      <Popover id={'cap-popover-' + c} className='cap-popover'>
                                        <Popover.Header as="h3" className='text-truncate'>{capabilityMap.get(c) ? capabilityMap.get(c).capabilityCommercialName : capabilityMap.get(c).capabilityName}</Popover.Header>
                                        <Popover.Body>{capabilityMap.get(c) ? capabilityMap.get(c).capabilityDescription ? capabilityMap.get(c).capabilityDescription : 'NA' : 'NA'}</Popover.Body>
                                      </Popover>
                                    }>
                                      <div className="basic-chip background-green" >{capabilityMap.get(c) ? capabilityMap.get(c).capabilityCommercialName : capabilityMap.get(c).capabilityName}</div>
                                    </OverlayTrigger>
                                  )
                                }
                              </div>

                            </Tab>
                          </Tabs>
                        )
                      }
                    </Scrollbars>
                  </Col>
                </Row>
              </Tab>

              {/* details page */}
              <Tab eventKey="details" title="Fill details" disabled={subscription['userBundles'].length === 0}>
                <Container fluid className='p-0'>

                  {/* user options */}
                  <Row className="g-0 p-0 uo-area cs">
                    <Col>
                      <div className='d-flex align-items-center justify-content-end'>
                        <Form.Check type="checkbox" variant='secondary' checked={trialSub} onChange={() => handleTrialSubscription()} className='me-3 trial-check' label='Trial subscription' name='trial' id='trial-sub' />
                        <ButtonGroup>
                          <ToggleButton size='sm' id="details-view-switch-1" type="checkbox" variant="secondary" checked={simpleView} onChange={(e) => setSimpleView(!simpleView)}>Simple view</ToggleButton>
                          <ToggleButton size='sm' id="details-view-switch-2" type="checkbox" variant="secondary" checked={!simpleView} onChange={(e) => setSimpleView(!simpleView)}>Full view</ToggleButton>
                        </ButtonGroup>
                      </div>
                    </Col>
                  </Row>

                  {/* subscription data */}
                  <Row className='d-row'>
                    <p className='o-d-title mt-0' id='d-sub-data'>Subscription data</p>
                    <Row className='m-0 p-0' md={4}>
                      {
                        Object.keys(subscription['subData']).length > 0 &&
                        fields.filter(f => f.id === 'provisioningId1').map(f =>
                          <>
                            <Col className='d-col-l p-0' key={f.id}>
                              <UserInputField
                                id={f.id}
                                title={getFieldTitle(f.title, f.id)}
                                description={getFieldDescription(f.description, f.id)}
                                type={f.type}
                                value={formatValueForInput(subscription['subData'][f.id])}
                                isDisabled={false}
                                isRequired={true}
                                onChangeFn={handleSubscriptionData}
                                removeFieldFn={null}
                              />
                            </Col>
                            <Col className='d-col-l p-0 d-flex align-items-center justify-content-start' key={f.id + '-2'}>
                              <div className='ms-1 mt-4 avbl-check' onClick={() => handleSubIdAvailableCheck(subscription['subData'][f.id])} title={'Check subscription ID availability'}>
                                {
                                  subIdCheckStatus['status'] === 'idle' ? 'Check' : 'Re-check'
                                }
                              </div>
                              {
                                subIdCheckStatus['status'] === 'checking' ?
                                  <div className='avbl-check-spinner ms-1 mt-4'>
                                    <Spinner animation="border" size="sm" className='ms-1' />
                                    <small className='ms-2'>{subIdCheckStatus['message']}</small>
                                  </div>
                                  : subIdCheckStatus['status'] === 'available' ?
                                    <div className='ms-1 mt-4 avbl-check-success'><MdCheck /> <small>{subIdCheckStatus['message']}</small></div>
                                    : subIdCheckStatus['status'] === 'unavailable' ?
                                      <div className='ms-1 mt-4 avbl-check-failure'><MdClose /> <small>{subIdCheckStatus['message']}</small> </div>
                                      : null
                              }
                            </Col>
                          </>
                        )
                      }
                    </Row>
                    <Row className='m-0 p-0 mt-1' md={4}>
                      {
                        Object.keys(subscription['subData']).length > 0 &&
                        fields.filter(f => f.category === 'subscription')
                          .filter(f => ['provisioningId1'].indexOf(f.id) === -1 && shouldRenderField(f)).map(f =>
                            <Col key={f.id} className='d-col-l p-0'>
                              {
                                <UserInputField
                                  id={f.id}
                                  title={getFieldTitle(f.title, f.id)}
                                  description={getFieldDescription(f.description, f.id)}
                                  type={f.type}
                                  value={formatValueForInput(subscription['subData'][f.id])}
                                  isDisabled={!f.mutable}
                                  isRequired={bundleOrderFields.indexOf(f.id) !== -1}
                                  onChangeFn={handleSubscriptionData}
                                  removeFieldFn={NON_MODIFIEABLE_FIELDS.indexOf(f.id) !== -1 ? null : removeField}
                                />
                              }
                              {/* {
                              <Form.Group className={markFieldClasses(subscription['subData'][f.id], f.id).join(' ')} controlId={f.id}>
                                <Form.Label title={getFieldDescription(f.description, f.id)}>{getFieldTitle(f.title, f.id)}</Form.Label>
                                {
                                  bundleOrderFields.indexOf(f.id) !== -1 &&
                                  <Form.Label className='ms-1 text-danger fw-bold fs-6' title='Required'>*</Form.Label>
                                }
                                <Form.Control type={f.type} disabled={!f.use || !f.mutable} name={f.id} value={formatValueForInput(subscription['subData'][f.id])} onChange={e => handleSubscriptionData(e)} />
                              </Form.Group>
                            } */}
                            </Col>
                          )
                      }
                    </Row>

                    <p className='o-d-title' id='d-cust-data'>Customer data</p>
                    <Row className='m-0 p-0' md={4}>
                      {
                        Object.keys(subscription['custData']).length > 0 &&
                        fields.filter(f => f.category === 'customer' && shouldRenderField(f)).map(f =>
                          <Col key={f.id} className='d-col-l p-0'>
                            {
                              <UserInputField
                                id={f.id}
                                title={getFieldTitle(f.title, f.id)}
                                description={getFieldDescription(f.description, f.id)}
                                type={f.type}
                                value={formatValueForInput(subscription['custData'][f.id])}
                                isDisabled={!f.mutable}
                                isRequired={bundleOrderFields.indexOf(f.id) !== -1}
                                onChangeFn={handleCustomerData}
                                removeFieldFn={NON_MODIFIEABLE_FIELDS.indexOf(f.id) !== -1 ? null : removeField}
                              />
                            }
                            {/* {
                              <Form.Group className={markFieldClasses(subscription['custData'][f.id], f.id).join(' ')} controlId={f.id}>
                                <Form.Label title={getFieldDescription(f.description, f.id)}>{getFieldTitle(f.title, f.id)}</Form.Label>
                                {
                                  bundleOrderFields.indexOf(f.id) !== -1 &&
                                  <Form.Label className='ms-1 text-danger fw-bold fs-6' title='Required'>*</Form.Label>
                                }
                                <Form.Control type={f.type} disabled={!f.use || !f.mutable} name={f.id} value={formatValueForInput(subscription['custData'][f.id])} onChange={e => handleCustomerData(e)} />
                              </Form.Group>
                            } */}
                          </Col>
                        )
                      }
                    </Row>

                    <p className='o-d-title' id='d-bundle-data'>Bundle data</p>
                    {
                      Object.keys(subscription['bundleData']).length > 0 &&
                      subscription['userBundles'].length > 0 &&
                      <Row className='m-0 p-0 my-1'>
                        <Accordion alwaysOpen flush defaultActiveKey={[...Array(25).keys()].map(String)} className='d-accordion px-0'>
                          {
                            subscription['userOffers'].map((o, _idx) =>
                              <Accordion.Item eventKey={`${_idx}`} id={'offer-accordion-' + o} key={'o-a-' + o}>
                                <Accordion.Header className='acc-o-header' > {offerMap.get(o).offerCommercialName}</Accordion.Header>
                                <Accordion.Body className='acc-o-body px-2'>
                                  <Accordion flush defaultActiveKey={`ba-ek-0`}>
                                    {
                                      offerMap.get(o).offerBundles.filter(b => bundleMap.get(b.bundleId) && subscription['userBundles'].indexOf(b.bundleId) !== -1).map((b, _idx) =>
                                        <Accordion.Item eventKey={`ba-ek-${_idx}`} id={'bundle-accordion-' + b.bundleId} key={'b-a-' + b.bundleId}>
                                          <Accordion.Header className='acc-b-header'>{bundleMap.get(b.bundleId).bundleCommercialName}</Accordion.Header>
                                          <Accordion.Body className='acc-b-body'>
                                            <Row className='mb-2 pb-0' md={4}>
                                              {
                                                fields.filter(f => f.category === 'bundle')
                                                  .filter(f => shouldRenderField(f) && !isFieldDisabledForBundle(f.id, b.bundleId))
                                                  .map(f =>
                                                    <Col key={f.id} className='d-col-l p-0'>
                                                      {
                                                        <UserInputField
                                                          id={f.id}
                                                          title={getFieldTitle(f.title, f.id)}
                                                          description={getFieldDescription(f.description, f.id)}
                                                          type={f.type}
                                                          value={formatValueForInput(subscription['bundleData'][b.bundleId][f.id])}
                                                          isDisabled={!f.mutable}
                                                          isRequired={bundleMap.get(b.bundleId).bundleOrderDetails.indexOf(f.id) !== -1}
                                                          onChangeFn={setPerBundleField}
                                                          removeFieldFn={NON_MODIFIEABLE_FIELDS.indexOf(f.id) !== -1 ? null : removeField}
                                                          bundleId={b.bundleId} />
                                                      }
                                                      {/* {
                                                      <Form.Group className={markFieldClasses(subscription['bundleData'][b.bundleId][f.id], f.id).join(' ')} controlId={f.id}>
                                                        <Form.Label title={getFieldDescription(f.description, f.id)}>{getFieldTitle(f.title, f.id)}</Form.Label>
                                                        {
                                                          bundleOrderFields.indexOf(f.id) !== -1 &&
                                                          <Form.Label className='ms-1 text-danger fw-bold fs-6' title='Required'>*</Form.Label>
                                                        }
                                                        <Form.Control type={f.type} disabled={!f.use || !f.mutable} name={f.id} value={formatValueForInput(subscription['bundleData'][b.bundleId][f.id])} onChange={e => setPerBundleField(b.bundleId, e)} />
                                                      </Form.Group>
                                                    } */}
                                                    </Col>
                                                  )
                                              }
                                            </Row>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      )
                                    }
                                  </Accordion>
                                </Accordion.Body>
                              </Accordion.Item>
                            )
                          }
                        </Accordion>
                      </Row>
                    }
                  </Row>

                </Container>
              </Tab>

              {/* review page */}
              <Tab eventKey="review" title="Review & submit" disabled={subscription['userBundles'].length === 0}>
                <Container fluid className='d-cont'>
                  <Row className='d-row'>

                    {/* user options */}
                    <Row className="g-0 px-0 pt-0 pb-0 uo-area es">
                      <Col>
                        <div className='d-flex align-items-center justify-content-end'>
                          <div className='me-2 text-success'>
                            {
                              trialSub &&
                              <>
                                <MdTry /> <small>This is trial subscription</small>
                              </>
                            }
                          </div>
                          <Button size='sm' className='me-2' variant="secondary" onClick={() => setShowPayload(true)}>Payload</Button>
                          <Modal show={showPayload} size='lg' scrollable={false} onHide={() => setShowPayload(false)}>
                            <Modal.Header closeButton>
                              <Modal.Title>Subscription payload</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />} style={{ height: 650 }}>
                                <Container fluid>
                                  <Row>
                                    <Col>
                                      <ReactJson src={buildPayload()} name='Payload' iconStyle="triangle" indentWidth={2} collapsed={false} enableClipboard={true} displayObjectSize={false} displayDataTypes={false} quotesOnKeys={false} groupArraysAfterLength={1000} displayArrayKey={false} />
                                    </Col>
                                  </Row>
                                </Container>
                              </Scrollbars>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" size='sm' onClick={() => setShowPayload(false)}>Close</Button>
                            </Modal.Footer>
                          </Modal>
                          <ButtonGroup>
                            <ToggleButton size='sm' id="details-view-switch-1" type="checkbox" variant="secondary" checked={simpleView} onChange={(e) => setSimpleView(!simpleView)}>Simple view</ToggleButton>
                            <ToggleButton size='sm' id="details-view-switch-2" type="checkbox" variant="secondary" checked={!simpleView} onChange={(e) => setSimpleView(!simpleView)}>Full view</ToggleButton>
                          </ButtonGroup>
                        </div>
                      </Col>
                    </Row>

                    <p className='o-d-title mt-0' id='r-sub-data'>Subscription data</p>
                    <Row className="g-0 m-0 r-f-row" md={5}>
                      {
                        Object.keys(subscription['subData']).length > 0 &&
                        fields.filter(f => f.id === 'provisioningId1').map(f =>
                          <Col className={bundleOrderFields.indexOf(f.id) !== -1 && Utils.isEmpty(subscription['subData'][f.id]) ? 'r-f-col error' : 'r-f-col'}>
                            <div className='r-f-title d-flex justify-content-start' title={getFieldDescription(f.description, f.id)}>
                              <div>{getFieldTitle(f.title, f.id)}</div>
                              <div className='text-danger' style={{ margin: '-3px 0px 0px 2px' }}>*</div>
                            </div>
                            <div className='r-f-val'>{Utils.isEmpty(subscription['subData'][f.id]) ? '-' : subscription['subData'][f.id]}</div>
                          </Col>
                        )
                      }
                    </Row>
                    <Row className="g-0 m-0 mt-1 r-f-row" md={5}>
                      {
                        Object.keys(subscription['subData']).length > 0 &&
                        fields.filter(f => f.category === 'subscription')
                          .filter(f => ['provisioningId1'].indexOf(f.id) === -1 && shouldRenderField(f))
                          .map(f =>
                            <Col className={bundleOrderFields.indexOf(f.id) !== -1 && Utils.isEmpty(subscription['subData'][f.id]) ? 'r-f-col error' : 'r-f-col'}>
                              <div className='r-f-title d-flex justify-content-start' title={getFieldDescription(f.description, f.id)}>
                                <div>{getFieldTitle(f.title, f.id)}</div>
                                {
                                  bundleOrderFields.indexOf(f.id) !== -1 &&
                                  <div className='text-danger' style={{ margin: '-3px 0px 0px 2px' }}>*</div>
                                }
                              </div>
                              <div className='r-f-val'>{Utils.isEmpty(subscription['subData'][f.id]) ? '-' : subscription['subData'][f.id]}</div>
                            </Col>
                          )
                      }
                    </Row>

                    {/* Customer data */}
                    <p className='o-d-title' id='r-cust-data'>Customer data</p>
                    <Row className="g-0 m-0 r-f-row" md={5}>
                      {
                        Object.keys(subscription['subData']).length > 0 &&
                        fields.filter(f => f.category === 'customer').map(f =>
                          shouldRenderField(f) &&
                          <Col className={bundleOrderFields.indexOf(f.id) !== -1 && Utils.isEmpty(subscription['custData'][f.id]) ? 'r-f-col error' : 'r-f-col'}>
                            <div className='r-f-title d-flex justify-content-start' title={getFieldDescription(f.description, f.id)}>
                              <div>{getFieldTitle(f.title, f.id)}</div>
                              {
                                bundleOrderFields.indexOf(f.id) !== -1 &&
                                <div className='text-danger' style={{ margin: '-3px 0px 0px 2px' }}>*</div>
                              }
                            </div>
                            <div className='r-f-val text-break'>{Utils.isEmpty(subscription['custData'][f.id]) ? '-' : subscription['custData'][f.id]}</div>
                          </Col>
                        )
                      }
                    </Row>
                    {/* Selected subscription */}
                    <Row className="g-0 px-0 pt-0 pb-0 mt-3 sub-view" id='sel-sub'>
                      <p className='mb-1 view-title'>Subscription package</p>
                      <Col md={5} className='sub-view-col pe-2' style={{ borderRadius: '8px 0px 0px 8px' }}>
                        <p className='title  o-b-title'>Selected offers/bundles</p>
                        <div className='o-b-area'>
                          {
                            subscription['userOffers'].map(o =>
                              <>
                                <div className={(subPackageSelection['type'] === 'offer' && o === subPackageSelection['value']) ? 'offer active' : 'offer'} onClick={() => handleOfferBundleSelect('offer', o)}>{offerMap.get(o).offerCommercialName}</div>
                                <div className='bundles d-flex flex-wrap'>
                                  {
                                    offerMap.get(o).offerBundles.filter(b => bundleMap.get(b.bundleId) && subscription['userBundles'].indexOf(b.bundleId) !== -1).map((b, _idx) =>
                                      (_idx < MAX_CHIPS || toggleChip[`ob-toggle-${o}`]) &&
                                      <div className={['b-chip', 'd-flex', 'align-items-center', 'justify-cotent-start', subPackageSelection['type'] === 'bundle' && b.bundleId === subPackageSelection['value'] ? 'active' : ''].join(' ')} onClick={() => handleOfferBundleSelect('bundle', parseInt(b.bundleId))} title={bundleMap.get(b.bundleId).bundleCommercialName}>
                                        <div className='b-name text-truncate'>{bundleMap.get(b.bundleId).bundleCommercialName}</div>
                                        <div className='b-count'>{subscription['bundleData'][b.bundleId] && !Utils.isEmpty(subscription['bundleData'][b.bundleId]['fixedCount']) ? subscription['bundleData'][b.bundleId]['fixedCount'] : '-'}</div>
                                      </div>
                                    )
                                  }
                                  {
                                    offerMap.get(o).offerBundles.map(b => b.bundleId).filter(b => bundleMap.get(b) && subscription['userBundles'].indexOf(b) !== -1).length > MAX_CHIPS &&
                                    !toggleChip[`ob-toggle-${o}`] &&
                                    <div className='b-chip count' onClick={() => setToggleChip({ ...toggleChip, [`ob-toggle-${o}`]: true })} style={{ cursor: 'pointer' }}>
                                      <div className='b-name' title={`Show ${offerMap.get(o).offerBundles.map(b => b.bundleId).filter(b => bundleMap.get(b) && subscription['userBundles'].indexOf(b) !== -1).length - MAX_CHIPS} more bundles`}>
                                        {`+${offerMap.get(o).offerBundles.map(b => b.bundleId).filter(b => bundleMap.get(b) && subscription['userBundles'].indexOf(b) !== -1).length - MAX_CHIPS}`}
                                      </div>
                                    </div>
                                  }
                                </div>
                              </>
                            )
                          }
                        </div>
                      </Col>
                      <Col md={7} className='sub-view-col ps-2' style={{ borderRadius: '0px 8px 8px 0px' }}>
                        {
                          subPackageSelection['type'] === 'offer' && subPackageSelection['value'] !== 0 &&
                          <>
                            <p className='title b-vi-title'>{'Offer: ' + offerMap.get(subPackageSelection['value']).offerCommercialName}</p>
                            <div className='b-i-area'>
                              <Container fluid className='b-i-cont'>
                                <Row className='b-i-row'>
                                  <Col className='b-i-col desc'>{offerMap.get(subPackageSelection['value']).offerDescription}</Col>
                                </Row>
                                <Row className='b-i-row'>
                                  <Col className='b-i-col'>
                                    <span className='k'>System Type:</span>
                                    <span className='v'>{offerMap.get(subPackageSelection['value']).offerSystemType}</span>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          </>
                        }
                        {
                          subPackageSelection['type'] === 'bundle' && subPackageSelection['value'] !== 0 &&
                          <>
                            <p className='title b-vi-title'>{'Bundle: ' + bundleMap.get(subPackageSelection['value']).bundleCommercialName}</p>
                            <div className='b-i-area'>
                              <Container fluid className='b-i-cont'>
                                <Row className='b-i-row'>
                                  <Col className='b-i-col desc'>{bundleMap.get(subPackageSelection['value']).bundleDescription}</Col>
                                </Row>
                                <Row className='b-i-row'>
                                  <Col className='b-i-col'>
                                    <span className='k'>Service Type:</span>
                                    <span className='v'>{bundleMap.get(subPackageSelection['value']).bundleServiceType}</span>
                                  </Col>
                                </Row>
                                <Row className='b-i-row'>
                                  <Col className='b-i-col text-truncate'>
                                    <span className='k'>Material Code:</span>
                                    <span className='v' title={bundleMap.get(subPackageSelection['value']).bundleSmartCode}>{bundleMap.get(subPackageSelection['value']).bundleSmartCode}</span>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          </>
                        }
                        {
                          subPackageSelection['type'] === 'bundle' && subPackageSelection['value'] !== 0 &&
                          <>
                            <p className='title b-v-title'>Bundle values</p>
                            <div className='b-v-area'>
                              <Container fluid className='b-val-cont'>
                                <Row className='b-val-row' md={3}>
                                  {
                                    Object.entries(getBundleOrderDetails(subPackageSelection['value'])).sort((a, b) => { return a[0].localeCompare(b[0]) }).map(f =>
                                      <Col className='b-val-col'>
                                        <div className='sub-title b-val-title  d-flex justify-content-start'>
                                          <div className='text-truncate' title={Utils.displayTitle(f[0])}>{Utils.displayTitle(f[0])}</div>
                                          <div className='text-danger' style={{ margin: '-3px 0px 0px 2px' }}>*</div>
                                        </div>
                                        <div className='b-val text-break'>{Utils.isEmpty(f[1]) ? '-' : f[1]}</div>
                                      </Col>
                                    )
                                  }
                                </Row>
                                <Row className='b-val-row' md={3}>
                                  {
                                    Object.keys(subscription['bundleData'][subPackageSelection['value']]).sort().map(f =>
                                      isVisibleInSimpleView(f, [], subPackageSelection['value']) &&
                                      bundleMap.get(subPackageSelection['value']).bundleOrderDetails.indexOf(f) === -1 &&
                                      !isFieldDisabledForBundle(f, subPackageSelection['value']) &&
                                      <Col className='b-val-col'>
                                        <div className='sub-title b-val-title  d-flex justify-content-start'>
                                          <div>{Utils.displayTitle(f)}</div>
                                          {
                                            bundleMap.get(subPackageSelection['value']).bundleOrderDetails.indexOf(f) !== -1 &&
                                            <div className='text-danger' style={{ margin: '-3px 0px 0px 2px' }}>*</div>
                                          }
                                        </div>
                                        <div className='b-val text-break'>{Utils.isEmpty(subscription['bundleData'][subPackageSelection['value']][f]) ? '-' : subscription['bundleData'][subPackageSelection['value']][f]}</div>
                                      </Col>
                                    )
                                  }
                                </Row>
                              </Container>
                            </div>
                          </>
                        }
                        <p className='title a-c-title'>Enabled applications</p>
                        <div className='a-c-area'>
                          {
                            subscription['userApps'].map(a =>
                              shouldShowApp(a) &&
                              <div className='a-card' id={'app-' + a}>
                                <div className='app-title clearfix'>
                                  <div className='app-name float-start'>{applicationMap.get(a) ? applicationMap.get(a).applicationCommercialName : applicationMap.get(a).applicationName}</div>
                                  <div className='app-dc float-end'>{subscription['entData'][pcApplicationMap.get(a).applicationType] ? subscription['entData'][pcApplicationMap.get(a).applicationType]['state'] : ''}</div>
                                </div>
                                {
                                  pcApplicationMap.get(a) && !subscription['entData'][pcApplicationMap.get(a).applicationType] &&
                                  <Container fluid className='p-0 px-1'>
                                    <Row className='d-flex align-items-center px-3 py-1'>
                                      <Col md={4} className='text-start text-wrap ps-1'>Application type</Col>
                                      <Col md={8} className='text-break text-end text-wrap pe-2'>{pcApplicationMap.get(a).applicationType}</Col>
                                    </Row>
                                    <Row className='d-flex align-items-center px-3 py-1'>
                                      <Col md={4} className='text-start text-wrap ps-1'>Application description</Col>
                                      <Col md={8} className='text-break text-end text-wrap pe-2'>{pcApplicationMap.get(a).applicationDescription}</Col>
                                    </Row>
                                  </Container>
                                }
                                {
                                  pcApplicationMap.get(a) && subscription['entData'][pcApplicationMap.get(a).applicationType] &&
                                  <EntitlementData appEntData={subscription['entData'][pcApplicationMap.get(a).applicationType]} appDesc={pcApplicationMap.get(a).applicationDescription} username={user.username} />
                                }
                                <div className='cap-heading'>Capabilities</div>
                                <div className='app-caps d-flex flex-wrap'>
                                  {
                                    subscription['userCaps'][a].filter(c => shouldShowCap(c)).map((c, _idx) =>
                                      (_idx < MAX_CHIPS || toggleChip[`ac-toggle-${a}`]) &&
                                      <OverlayTrigger trigger={['hover', 'focus']} delay={250} placement="auto" rootClose={true} overlay={
                                        <Popover id={'cap-popover-' + c} className='cap-popover'>
                                          <Popover.Header as="h3" className='text-truncate'>{capabilityMap.get(c) ? capabilityMap.get(c).capabilityCommercialName : capabilityMap.get(c).capabilityName}</Popover.Header>
                                          <Popover.Body>{capabilityMap.get(c) ? capabilityMap.get(c).capabilityDescription ? capabilityMap.get(c).capabilityDescription : 'NA' : 'NA'}</Popover.Body>
                                        </Popover>
                                      }>
                                        <div className='app-cap'>{capabilityMap.get(c) ? capabilityMap.get(c).capabilityCommercialName : capabilityMap.get(c).capabilityName}</div>
                                      </OverlayTrigger>
                                    )
                                  }
                                  {
                                    subscription['userCaps'][a].filter(c => shouldShowCap(c)).length > MAX_CHIPS && !toggleChip[`ac-toggle-${a}`] &&
                                    <div className='app-cap' onClick={() => setToggleChip({ ...toggleChip, [`ac-toggle-${a}`]: true })} style={{ cursor: 'pointer' }} title={`Show ${subscription['userCaps'][a].filter(c => shouldShowCap(c)).length - MAX_CHIPS} more capabilities`}>{`+${subscription['userCaps'][a].filter(c => shouldShowCap(c)).length - MAX_CHIPS}`}</div>
                                  }
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </Container>
              </Tab>

            </Tabs>

            {
              /* bottom navigation buttons */
              subscription['userBundles'].length > 0 &&
              <div className='clearfix mt-3'>
                <div className={(activeProfile === 'details' || activeProfile === 'review') ? 'float-start profile-button visible' : 'float-start profile-button invisible'} onClick={() => handleButtonClick('prev')}>Previous</div>
                <Button className="profile-button float-end" disabled={subscription['userBundles'].length === 0} onClick={() => handleButtonClick('next')}>{activeProfile === 'review' ? 'Submit' : 'Next'}</Button>
              </div>
            }

            {/* Trigger new subscription modal */}
            <Modal size='lg' backdrop='static' keyboard={false} show={showSubStatusModal}>
              <Modal.Header>
                <Modal.Title>Create subscription</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container fluid>
                  <Row>
                    <Col>
                      <p variant="secondary">Click <b>Create subscription</b> to trigger new subscription, you can not go back once subscription triggered.</p>
                      <p variant="secondary">Click <b>Cancel</b> to continue editing subscription details.</p>
                      <ul className='m-0 p-0 mb-3'>
                        {
                          subIdCheckStatus['status'] === 'checking' ?
                            <li className='d-block my-2'>
                              <Spinner animation="border" size="sm" />
                              <small className='ms-1 fs-6'>Checking subscription Id availability...</small>
                            </li>
                            : subIdCheckStatus['status'] === 'available' ?
                              <li className='d-block my-2 text-success'><MdCheck />  Subscription Id ({subscription['subData']['provisioningId1']}) available.</li>
                              : subIdCheckStatus['status'] === 'unavailable' ?
                                <li className='d-block my-2 text-danger'><MdClose /> Subscription Id ({subscription['subData']['provisioningId1']}) unavailable. Please try again with different subscription Id.</li>
                                : null
                        }
                        {
                          trialSub &&
                          <li className='d-block my-2 text-success'><MdTry /> This is trial subscription.</li>
                        }
                        {
                          (subscription['userOffers'].length === 0 || subscription['userBundles'].length === 0) &&
                          <li className='d-block text-danger my-2'>No Offer/Bundle selected. Please select appropriate offer or bundle from 'Select offer' section.</li>
                        }
                        {
                          fields.filter(f => f.id === 'provisioningId1').map(f =>
                            Utils.isEmpty(subscription['subData'][f.id]) &&
                            <li className='d-block text-danger my-2'>Please specify required fields: <b>{f.id}</b></li>
                          )
                        }
                        {
                          fields.filter(f => f.category === 'subscription').map(f =>
                            f.id !== 'provisioningId1' && bundleOrderFields.indexOf(f.id) !== -1 && Utils.isEmpty(subscription['subData'][f.id]) &&
                            <li className='d-block text-danger my-2'>Please specify required subscription data: <b>{f.id}</b></li>
                          )
                        }
                        {
                          fields.filter(f => f.category === 'customer').map(f =>
                            bundleOrderFields.indexOf(f.id) !== -1 && Utils.isEmpty(subscription['custData'][f.id]) &&
                            <li className='d-block text-danger my-2'>Please specify required customer data: <b>{f.id}</b></li>
                          )
                        }
                        {
                          Object.keys(subscription['bundleData']).map(Number).map(b =>
                            Object.keys(subscription['bundleData'][b]).map(f =>
                              bundleMap.get(b) && bundleMap.get(b).bundleOrderDetails.indexOf(f) !== -1 && Utils.isEmpty(subscription['bundleData'][b][f]) &&
                              <li className='d-block text-danger'>Please specify required bundle data <i>{bundleMap.get(b).bundleCommercialName}</i>: <b>{f}</b></li>
                            )
                          )
                        }
                      </ul>
                      {
                        subAlertmessage !== '' &&
                        <Alert key={subAlertVariant} variant={subAlertVariant}>
                          {
                            subAlertVariant === 'primary' &&
                            <div className="spinner-border text-primary spinner-grow-sm me-2" role="status"></div>
                          }
                          {subAlertmessage}
                        </Alert>
                      }
                      <p style={{ margin: '-5px 0px 2px 2px', fontWeight: '500' }}>Logs:</p>
                      <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />} style={{ height: 300 }}>
                        <div className="sub-status-logs" style={{ backgroundColor: '#f5f5f5', padding: '3px', borderRadius: '5px', fontSize: '12px', minHeight: '100px' }}>
                          {
                            subStatusLogs.split('\n').map(l =>
                              <div className='text-break' style={{ borderBottom: '1px solid #e5e5e5', padding: '2px 5px' }}>{l.replace(/,/g, ", ")}</div>
                            )
                          }
                        </div>
                      </Scrollbars>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <div className='clearfix'>
                  {
                    subAlertVariant === 'secondary' &&
                    <Button variant="secondary" className='float-start me-2' size='sm' title='Close this modal, and continue creating subscription' onClick={() => setShowSubStatusModal(false)}>Cancel</Button>
                  }
                  {
                    subAlertVariant === 'secondary' &&
                    <Button variant="info" className='float-end ms-2' size='sm' title='Trigger new subscription' disabled={!isAllowedToCreateSubscription()} onClick={() => triggerNewSubscriptionRequest()}>Create subscription</Button>
                  }
                  {
                    (subAlertVariant === 'success' || subAlertVariant === 'error' || subAlertVariant === 'primary') &&
                    <Button variant="primary" className='float-end ms-2' size='sm' disabled={(subAlertVariant === 'primary') ? true : false} onClick={() => { setShowSubStatusModal(false); window.location.reload(); }}>Close</Button>
                  }
                </div>
              </Modal.Footer>
            </Modal>
          </Container>
        </Col>

        {/* Right sidebar */}
        <Col md={2}>
          {
            activeProfile === 'details' ?
              <Nav id='navbar' className="flex-column r-nav">
                <p className='mb-1'>On this page</p>
                <Nav.Link href="#d-sub-data" eventKey='d-sub-data'>Subscription data</Nav.Link>
                <Nav.Link href="#d-cust-data" eventKey='d-cust-data'>Customer data</Nav.Link>
                <Nav.Link href="#d-bundle-data" eventKey='d-bundle-data'>Bundle data</Nav.Link>
                {
                  subscription['userOffers'].map(o =>
                    <Nav.Link key={'r-s-o-' + o} className='ps-4' style={{ fontSize: '13px' }} href={'#offer-accordion-' + o} eventKey={'offer-accordion-' + o}>{offerMap.get(o).offerCommercialName}</Nav.Link>
                  )
                }
              </Nav>
              : activeProfile === 'review' ?
                <Nav id='navbar' className="flex-column r-nav cs">
                  <p className='mb-1'>On this page</p>
                  <Nav.Link href="#r-sub-data" eventKey='r-sub-data'>Subscription data</Nav.Link>
                  {
                    Object.keys(subscription['custData']).length > 0 &&
                    <Nav.Link href="#r-cust-data" eventKey='r-cust-data'>Customer data</Nav.Link>
                  }
                  {
                    subscription['userOffers'].length > 0 &&
                    <Nav.Link href="#sel-sub" eventKey='sel-sub'>Subscription package</Nav.Link>
                  }
                  {
                    subscription['userApps'].map(a =>
                      shouldShowApp(a) &&
                      <Nav.Link key={'r-s-a-' + a} className='ps-4' style={{ fontSize: '13px' }} href={'#app-' + a} eventKey={'app-' + a}>{applicationMap.get(a) ? applicationMap.get(a).applicationCommercialName : applicationMap.get(a).applicationName}</Nav.Link>
                    )
                  }
                </Nav>
                :
                <div></div>
          }
        </Col>

      </Row>
    </Container>
  );

};

export default withAuthenticator(CreateSub, withAuthenticatorOptions);