import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Row, Col } from 'react-bootstrap';

import * as Utils from '../../common/utils';

// stylesheet
import '../../scss/top_heading_bar.scss';

import EnvDetails from '../../env-details.json';

const ENV = Utils.getCurrentEnv(), STAGE = Utils.getCurrentStage()

const TopHeadingBar = ({ title, user, signOut }) => {
  return (
    <>
      <Row>
        <Col className='title-wrap d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center justify-content-start title-text'>
            <div>{title}</div>
          </div>
          <div className='d-flex align-items-center justify-content-end title-settings'>
            <div className='me-2'>{user.attributes.email}</div>
            <Dropdown size='sm' as={ButtonGroup} className='me-2'>
              <Button variant="outline-secondary" title={`Environment: ${ENV}, Stage: ${STAGE}`}>{EnvDetails.filter(e => e.envName === ENV)[0].dispName}</Button>
              <Dropdown.Toggle split variant="outline-secondary" id="dropdown-split-basic" />
              <Dropdown.Menu variant='outline-secondary' align='end'>
                {
                  EnvDetails.map(e =>
                    Utils.includeEnvInDropdown(e.envName) &&
                    <Dropdown.Item key={e.envName} title={`Environment: ${e.envName}, Stage: ${e.stageName}`} href={e.url}>{e.dispName}</Dropdown.Item>
                  )
                }
              </Dropdown.Menu>
            </Dropdown>
            <Button size="sm" variant="outline-secondary" onClick={() => signOut()}>Signout</Button>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default TopHeadingBar;