import * as React from "react"
const Gcp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    imageRendering="optimizeQuality"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    width={20}
    height={20}
    viewBox="0 0 333334 268125"
    {...props}
  >
    <path
      fill="#ea4335"
      d="m212126 74104 10677 183 29010-29011 1406-12291C229395 11715 198568-27 166631 1 106527 1 55798 40897 40782 96293c3167-2209 9937-552 9937-552l57969-9531s2979-4932 4489-4687c13706-15059 33128-23638 53489-23630 17188 36 33021 6109 45459 16156v56h1z"
    />
    <path
      fill="#4285f4"
      d="M292553 96355c-6735-24844-20615-46781-39349-63422l-41078 41078c16459 13281 27016 33604 27016 56354v7250c19984 0 36219 16271 36219 36219 0 19984-16271 36219-36219 36219h-72438l-7250 7292v43469l7250 7213h72438c51989-73 94115-42198 94193-94187-37-32057-16146-60417-40781-77479v-5z"
    />
    <path
      fill="#34a853"
      d="M94193 268125h72396v-58020H94193c-5144 1-10227-1104-14906-3240l-10453 3203-29010 29010-2541 9792c16348 12472 36343 19224 56906 19214l5 41z"
    />
    <path
      fill="#fbbc05"
      d="M94193 79688C42204 79813 73 121938 0 173928c0 30589 14652 57787 37323 75016l41999-42000c-12984-5856-21337-18772-21349-33016 0-19984 16271-36219 36219-36219 14687 0 27313 8854 33021 21355l42000-42000c-17224-22672-44427-37323-75015-37323l-5-53z"
    />
  </svg>
)
export default Gcp
