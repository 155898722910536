import rdiff from "recursive-diff";
let jsonDrawerOpeningEvents = [
  // "element.hover",
  // "element.out",
  "element.click",
  // "element.dblclick",
  // "element.mousedown",
  // "element.mouseup",
];
let fireFunctionOpenningEvents = [
  // "element.hover",
  // "element.out",
  // "element.click",
  "element.dblclick",
  // "element.mousedown",
  // "element.mouseup",
];
export function addEventListeners(viewer, stateObj) {
  addEventListenerForBpmnTool(viewer, {
    setDrawer: stateObj.setDrawer,
    idListJson: stateObj.idListJson,
    setEventDetails: stateObj.setEventDetails,
  });
}
export function addEventListenerForBpmnTool(bpmnJsViewer, stateObj) {
  let eventBus = bpmnJsViewer.get("eventBus");

  jsonDrawerOpeningEvents.forEach((event) => {
    eventBus.on(event, function (e) {
      const key = e.element.id.split("_")[2];

      if (stateObj.idListJson[key] && stateObj.idListJson[key][e.element.id]) {
        stateObj.setDrawer(true);
        stateObj.setEventDetails(stateObj.idListJson[key][e.element.id]);
      } else {
        stateObj.setDrawer(false);
      }
    });
  });
  fireFunctionOpenningEvents.forEach((event) => {
    //for later: dbclick is not appropiate for function fire, find an event that cannot be fired by mistake or add confirmation window
    eventBus.on(event, function (e) {
      console.log(event);
    });
  });
}

export function getElementId(elements, searchBy) {
  for (const [key, value] of Object.entries(elements)) {
    if (key.endsWith(searchBy)) {
      return elements[key].element;
    }
  }
}

export function getNewLabel(value, state, design) {
  const recLabel = value.child_step_name || value.step_name;
  const timeLabel = parseDate(value);
  const newLabel = `${recLabel.replaceAll("_", " ")}\n ${
    design[state].emoji
  }\n ${timeLabel}`;
  return newLabel;
}
export function getNewState(value) {
  return value.child_step_state || value.step_state;
}
export function getChangedStep(newData, oldData) {
  const diff = rdiff.getDiff(oldData, newData);
  const changedSteps = {};
  let stepStream;
  let dataIntial = newData;
  for (let diffrence of diff) {
    try {
      stepStream = dataIntial;
      diffrence.path.pop();
      for (let keyArr of diffrence.path) {
        stepStream = stepStream[keyArr];
      }
      if ("child_step_id" in stepStream)
        changedSteps[stepStream.child_step_id] = stepStream;
      else if ("step_id" in stepStream)
        changedSteps[stepStream.step_id] = stepStream;
      else throw new Error("not valid step");
    } catch (e) {
      console.log("stepStream");
      console.log(stepStream);
    }
  }

  return changedSteps;
}
export const colorsDict = {
  //TODO get colorsDict from server
  COMPLETED: { fill: "#f7f7f7", stroke: "#005706" },
  FAILED: { fill: "#f7f7f7", stroke: "#de2134" },
  IN_PROGRESS: { fill: "#f7f7f7", stroke: "#e87b0e" },
  PENDING: { fill: "#f7f7f7", stroke: "#e87b0e" },
};

const emojiDict = {
  COMPLETED: "✔️",
  FAILED: "👎",
  IN_PROGRESS: "🔄",
  PENDING: "⏳",
};
export function addLayersView(viewer, xml) {
  let canvas = viewer.get("canvas");
  let search = new URLSearchParams(window.location.search);
  // listen to browser navigation and change the root element accordingly
  window.addEventListener("popstate", (event) => {
    let rootElement = event.state && event.state.element;

    if (!rootElement) {
      return;
    }

    canvas.setRootElement(canvas.findRoot(rootElement));
  });

  // import the diagram and set the root element from the search params
  viewer.importXML(xml).then(function () {
    let root = search.get("rootElement");
    if (root) {
      canvas.setRootElement(canvas.findRoot(root));
    }
  });
}
function parseDate(value) {
  const startDate = new Date(value["start_date_timestamp"]);
  const endDate = new Date(value["end_date_timestamp"]);
  let secounds = (endDate - startDate) / 1000;
  if (secounds > 60 * 60 * 24) return parseDay(secounds);
  return parseHours(secounds);
}
function parseDay(seconds) {
  seconds = Number(seconds);
  let d = two_digits_please(Math.floor(seconds / (3600 * 24)));
  let h = two_digits_please(Math.floor((seconds % (3600 * 24)) / 3600));
  let m = two_digits_please(Math.floor((seconds % 3600) / 60));

  return `D(${d}|${h}|${m})`;
}
function parseHours(seconds) {
  seconds = Number(seconds);
  let h = two_digits_please(Math.floor((seconds % (3600 * 24)) / 3600));
  let m = two_digits_please(Math.floor((seconds % 3600) / 60));
  let s = two_digits_please(Math.floor(seconds % 60));

  return `H(${h}|${m}|${s})`;
}

function two_digits_please(duration) {
  if (duration < 10) return `0${duration}`;
  return `${duration}`;
}
