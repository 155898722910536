import { useState } from "react";

import Container from 'react-bootstrap/Container'
import { Row, Col } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Collapse from 'react-bootstrap/Collapse';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdContentCopy, MdOpenInNew } from "react-icons/md";
import { BsPlusSquare, BsDashSquare } from "react-icons/bs";

import { isEmpty, contains, displayTitle, isProductionEnv } from "../../common/utils";
import { hasRole } from "../../common/RBACUtils";

import '../../scss/entitlement_data.scss';

const EntitlementData = ({ appEntData, appDesc, subId, username }) => {

  const [clipboardMap, setClipboardMap] = useState(new Map());

  // list of sensitive data identifier keys
  const SENSITIVE_DATA_IDENTIFIERS = ['password', 'secret', 'token', 'key', 'sid'];

  const handleClipboardCopy = (str, title) => {
    setClipboardMap(new Map(clipboardMap));
    navigator.clipboard.writeText(str);
    clipboardMap.set(title, true);
    setClipboardMap(new Map(clipboardMap));
  }

  const handleClipboardHover = (str, title) => {
    clipboardMap.delete(title);
    setTimeout(() => setClipboardMap(new Map(clipboardMap)), 1000);
  }

  const formatUrl = (url) => {
    if (!url.includes('https://') && !url.includes('http://')) {
      return '//' + url;
    }
    return url;
  }

  const format = (str, title) => {

    if (isEmpty(str) || isEmpty(title)) {
      return '-';
    }

    if (SENSITIVE_DATA_IDENTIFIERS.some(k => String(title).toLowerCase().includes(k.toLowerCase()))) {
      if (isProductionEnv() && !hasRole(username, 'ADMINISTRATOR')) return <span title='Contact System Administrator to access sensitive data in Production.' className='fst-italic text-danger'>{str}</span>
      else
        return (
          <span>
            <span className='password' title='Click to show/hide' style={{ cursor: 'pointer' }} onClick={(e) => { e.target.innerHTML === '*'.repeat(str.length) ? e.target.innerHTML = str : e.target.innerHTML = '*'.repeat(str.length) }} >{'*'.repeat(str.length)}</span>
            <OverlayTrigger placement="top" delay={150} overlay={<Tooltip style={{ width: '150px', textAlign: 'center' }} id={'tooltip-for-' + title}>{clipboardMap.get(title) === undefined ? 'Copy to clipboard!' : 'Copied!'}</Tooltip>}>
              <small style={{ paddingLeft: '5px', cursor: 'pointer' }} onClick={() => handleClipboardCopy(str, title)} onMouseLeave={() => handleClipboardHover(str, title)}><MdContentCopy /></small>
            </OverlayTrigger>
          </span>
        );
    } else if (contains(title, 'url') || title === 'flowId') {
      return (
        <span className='d-flex align-items-center justify-content-end'>
          <span className='url d-block text-truncate' title='Opens URL in new tab'>
            <a href={title === 'flowId' ? '/bpmn?flow_id=' + subId + '&flow_type=flow_external_id' : formatUrl(str)} target='_blank' rel='noreferrer'>{str}
            </a>
          </span>
          <small style={{ paddingLeft: '2px', cursor: 'pointer', color: '#286a5d' }}><MdOpenInNew /></small>
        </span>
      );
    } else if (contains(title, 'user')) {
      return (
        <span>
          <span className='username'>{str}</span>
          <OverlayTrigger placement="top" delay={150} overlay={<Tooltip style={{ width: '150px', textAlign: 'center' }} id={'tooltip-for-' + title}>{clipboardMap.get(title) === undefined ? 'Copy to clipboard!' : 'Copied!'}</Tooltip>}>
            <small style={{ paddingLeft: '5px', cursor: 'pointer' }} onClick={() => handleClipboardCopy(str, title)} onMouseLeave={() => handleClipboardHover(str, title)}><MdContentCopy /></small>
          </OverlayTrigger>
        </span>
      );
    } else {
      return str;
    }
  }

  const typeOfServiceConnection = (data) => {
    if (typeof data === 'object')
      return Array.isArray(data) ? 'array' : 'object';
    return 'string';
  }

  const leftColSize = 4, rightColSize = 8;
  const [collapseAllFields, setCollapseAllFields] = useState(false);

  const entitlementKeys = ['applicationAccount', 'applicationInstanceId', 'timeZone', 'applicationSubAccount', 'applicationType', 'applicationDescription', 'activationInstanceId', 'failureReason', 'activationCompletionDate', 'applicationSpecificData', 'flowId'];
  return (
    <div className='app-info-wrap'>
      <Container fluid className='p-0'>
        {
          Object.keys(appEntData).length > 0 &&
          entitlementKeys.filter(key => (appEntData[key] || key === 'applicationDescription') && key !== 'applicationSpecificData').map((key, _idx) =>
            _idx < 3 &&
            <Row className='app-info d-flex align-items-center px-2'>
              <Col md={leftColSize} className='k text-start text-wrap ps-1'>{displayTitle(key)}</Col>
              <Col md={rightColSize} className='v text-break text-end text-wrap pe-2'>{key === 'applicationDescription' ? appDesc : format(appEntData[key], key)}</Col>
            </Row>
          )
        }
        <Collapse in={collapseAllFields}>
          <div className='collapsible-app-fields'>
            {
              Object.keys(appEntData).length > 0 &&
              entitlementKeys.filter(key => (appEntData[key] || key === 'applicationDescription') && key !== 'applicationSpecificData').map((key, _idx) =>
                _idx > 3 &&
                <Row className='app-info d-flex align-items-center px-2'>
                  <Col md={leftColSize} className='k text-start text-wrap ps-1'>{displayTitle(key)}</Col>
                  <Col md={rightColSize} className='v text-break text-end text-wrap pe-2'>{key === 'applicationDescription' ? appDesc : format(appEntData[key], key)}</Col>
                </Row>
              )
            }

            <div>
              {
                Object.keys(appEntData).length > 0 &&
                Object.keys(appEntData).map(key =>
                  key === 'applicationSpecificData' && Object.keys(appEntData[key]).length > 0 &&
                  <div>
                    <p className='p-0 my-1 fw-bold'>{displayTitle(key)}</p>
                    <Container fluid style={{ backgroundColor: '#fff', border: '1px solid #dbf1ed', padding: '6px 10px', borderRadius: '8px' }}>
                      {
                        [...Object.keys(appEntData[key]).filter(a => a !== 'serviceConnection'), 'serviceConnection'].map(skey =>
                          skey !== 'serviceConnection' || typeOfServiceConnection(appEntData[key][skey]) === 'string' ?
                            <Row className='app-info d-flex align-items-center px-2'>
                              <Col md={leftColSize} className='k text-start text-wrap ps-1'>{displayTitle(skey)}</Col>
                              <Col md={rightColSize} className='v text-break text-end text-wrap pe-2'>{format(appEntData[key][skey], skey)}</Col>
                            </Row>
                            : typeOfServiceConnection(appEntData[key][skey]) === 'object' ?
                              <div>
                                <p className='p-0 my-1 fw-bold'>{displayTitle(skey)}</p>
                                <Container fluid className='p-0 my-1 px-1' style={{ backgroundColor: '#fff' }}>
                                  {
                                    [...Object.keys(appEntData[key][skey]).filter(a => a !== 'connectionDetails'), 'connectionDetails'].map(sskey =>
                                      sskey !== 'connectionDetails' ?
                                        <Row className='app-info d-flex align-items-center px-3'>
                                          <Col md={leftColSize} className='k text-start text-wrap ps-1'>{displayTitle(sskey)}</Col>
                                          <Col md={rightColSize} className='v text-break text-end text-wrap pe-2'>{format(appEntData[key][skey][sskey], sskey)}</Col>
                                        </Row>
                                        :
                                        <div>
                                          <p className='p-0 m-0 px-2' style={{ fontWeight: '500' }}>{displayTitle(sskey)}</p>
                                          <Container fluid className='p-0 px-2'>
                                            {
                                              Object.keys(appEntData[key][skey][sskey]).map(ssskey =>
                                                <Row className='app-info d-flex align-items-center px-2'>
                                                  <Col md={leftColSize} className='k text-start text-wrap ps-1'>{displayTitle(ssskey)}</Col>
                                                  <Col md={rightColSize} className='v text-break text-end text-wrap pe-2'>{format(appEntData[key][skey][sskey][ssskey], ssskey)}</Col>
                                                </Row>
                                              )
                                            }
                                          </Container>
                                        </div>
                                    )
                                  }
                                </Container>
                              </div>
                              :
                              <div>
                                <p className='p-0 my-1 fw-bold'>{displayTitle(skey)}</p>
                                {
                                  appEntData[key][skey].map(arr =>
                                    <Container fluid className='p-0 my-2 px-1' style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '1px 1px 0px #f9f9f9', border: '1px solid #dbf1ed' }}>
                                      {
                                        typeof arr === 'object' && arr ?
                                        [...Object.keys(arr).filter(a => a !== 'connectionDetails'), 'connectionDetails'].map(sskey =>
                                          sskey !== 'connectionDetails' ?
                                            <Row className='app-info d-flex align-items-center px-3'>
                                              <Col md={leftColSize} className='k text-start text-wrap ps-1'>{displayTitle(sskey)}</Col>
                                              <Col md={rightColSize} className='v text-break text-end text-wrap pe-2'>{format(arr[sskey], sskey)}</Col>
                                            </Row>
                                            :
                                            <div>
                                              <p className='p-0 my-1 px-2' style={{ fontWeight: '500' }}>{displayTitle(sskey)}</p>
                                              <Container fluid className='p-0 px-3'>
                                                {
                                                  arr[sskey] ?  
                                                  Object.keys(arr[sskey]).map(ssskey =>
                                                    <Row className='app-info d-flex align-items-center px-2'>
                                                      <Col md={leftColSize} className='k text-start text-wrap ps-1'>{displayTitle(ssskey)}</Col>
                                                      <Col md={rightColSize} className='v text-break text-end text-wrap pe-2'>{format(arr[sskey][ssskey], ssskey)}</Col>
                                                    </Row>
                                                  )
                                                  : '-'
                                                }
                                              </Container>
                                            </div>
                                        ) : '-'
                                      }
                                    </Container>
                                  )
                                }
                              </div>

                        )
                      }
                    </Container>
                  </div>
                )
              }
            </div>
          </div>
        </Collapse>
        <div className='d-flex align-items-center justify-content-end'>
          <div className='app-info-collapse' onClick={() => setCollapseAllFields(!collapseAllFields)} aria-controls="collapsible-app-fields" aria-expanded={collapseAllFields} title={collapseAllFields ? 'Show less' : 'Show more'}>
            {collapseAllFields ? <BsDashSquare /> : <BsPlusSquare />} {collapseAllFields ? ' show less ' : ' show more '}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default EntitlementData;