import { useState, useEffect, useRef } from "react";

import ListGroup from "react-bootstrap/ListGroup";
import { ListGroupItem, Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { GenerateReportBtn } from "../common/btn";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Collapse from "react-bootstrap/Collapse";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DatePicker from "react-datepicker";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "../common/Spinner";
import { BsFillPatchPlusFill, BsFillPatchMinusFill } from "react-icons/bs";
import {
  MdSelectAll,
  MdDeselect,
} from "react-icons/md";

import ReactJson from '@microlink/react-json-view'

import { Scrollbars } from "react-custom-scrollbars-2";
import cloneDeep from "lodash.clonedeep";

import { Amplify, API } from "aws-amplify";

import "@aws-amplify/ui-react/styles.css";

import * as Utils from "../../../common/utils";
import * as RBACUtils from "../../../common/RBACUtils";

import "../../../scss/common.scss";
import "../../../scss/pc.scss";

import awsExports from "../../../aws-exports";
Amplify.configure(awsExports);

const curStage = Utils.getCurrentStage();

const OfferBundlesView = ({
  user,
  systemTypes,
  subId,
  productCat,
  setRecentActivity,
  linkIdAppTypeMap,
}) => {

  const INIT_SUB = {
    userOffers: [],
    userBundles: [],
  };

  //#region states

  const [subscription, setSubscription] = useState(INIT_SUB);
  const [offerDetails, setOfferDetails] = useState([]);
  const [bundleDetails, setBundleDetails] = useState([]);
  const [bundleSelectAllMap, setBundleSelectAllMap] = useState([]);
  const [monthView, setMonthView] = useState(true);
  const [spinner, setSpinner] = useState({ status: false, message: "" });
  const [selectAll, setSelectAll] = useState(false);
  const [modal, setModal] = useState({ show: false, type: "", id: "" });
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  const startOfMonth = new Date(yesterday.getFullYear(), yesterday.getMonth(), 1);
  const [startDate, setStartDate] = useState(startOfMonth);
  const [endDate, setEndDate] = useState(yesterday);
  
  const [requestStatus, setRequestStatus] = useState("INITIAL");
  const [serviceAppMap, setServiceAppMap] = useState({});
  const [bundleCdrMap, setBundleCdrMap] = useState({});
  const [userBundleOptions, setUserBundleOptions] = useState([]);
  const [userOffersOptions, setUserOffersOptions] = useState([]);
  const [cdrRequest, setCdrRequest] = useState(false);

  const originalOffers = useRef([]);

  //#endregion

  //#region local static data
  const availableCommercialStatuses = [];
  const availableTechnicalStatuses = [];

  const offerMap = new Map();
  const bundleOfferMap = new Map();
  const offerBundleMap = new Map();
  userOffersOptions.forEach((o) => {
    offerMap.set(o.offerId, o);
    o.offerBundles.forEach((b) => {
      if (bundleOfferMap.get(b.bundleId) === undefined) {
        bundleOfferMap.set(b.bundleId, o.offerId);
      }
    });
    if (
      o.offerCommercialStatus &&
      o.offerBundles.length > 0 &&
      availableCommercialStatuses.indexOf(o.offerCommercialStatus) === -1
    ) {
      availableCommercialStatuses.push(o.offerCommercialStatus);
    }
    if (
      o.offerTechnicalStatus &&
      o.offerBundles.length > 0 &&
      availableTechnicalStatuses.indexOf(o.offerTechnicalStatus) === -1
    ) {
      availableTechnicalStatuses.push(o.offerTechnicalStatus);
    }
  });

  for (var i = 0; i < userOffersOptions.length; i++) {
    const tArr = [];
    for (var j = 0; j < userOffersOptions[i].offerBundles.length; j++) {
      tArr.push(userOffersOptions[i].offerBundles[j].bundleId);
    }
    offerBundleMap.set(userOffersOptions[i].offerId, tArr);
  }
  
  const bundleMap = new Map();
  const applicationMap = new Map();
  const applicationTypeToId = new Map();
  const capabilityMap = new Map();
  userBundleOptions.forEach((b) => {
    bundleMap.set(b.bundleId, b);
    b.bundleCapabilities.forEach((c) => {
      if (applicationMap.get(c.applicationId) === undefined) {
        applicationMap.set(c.applicationId, c);
      }
      if (applicationTypeToId.get(c.applicationName) === undefined) {
        applicationTypeToId.set(c.applicationName, c.applicationId);
      }
      if (capabilityMap.get(c.capabilityId) === undefined) {
        capabilityMap.set(c.capabilityId, c);
      }
    });
    if (
      b.bundleCommercialStatus &&
      availableCommercialStatuses.indexOf(b.bundleCommercialStatus) === -1
    ) {
      availableCommercialStatuses.push(b.bundleCommercialStatus);
    }
    if (
      b.bundleTechnicalStatus &&
      availableTechnicalStatuses.indexOf(b.bundleTechnicalStatus) === -1
    ) {
      availableTechnicalStatuses.push(b.bundleTechnicalStatus);
    }
  });

  const pcApplicationMap = new Map();

  const createServiceAppMap = () => {
    const serviceAppMap = {};
    const bundleCdrMapTemp = {};
    for (const catObj of productCat) {
      serviceAppMap[catObj.bundleServiceType] = catObj.applicationType;
      bundleCdrMapTemp[catObj.bundleServiceType] =
        catObj.bundleBillingTarget === "ECDR";
    }
    setServiceAppMap(serviceAppMap);
    setBundleCdrMap(bundleCdrMapTemp);
    return { serviceApp: serviceAppMap, bundleCdr: bundleCdrMapTemp };
  };

  //#region functions
  const handleDatePick = (date) => {
    // insures endDate will never be after yesterday
    setStartDate(date[0]);
    if (monthView) {
      const endOfMonth = new Date(
        date[0].getFullYear(),
        date[0].getMonth() + 1,
        0
      );
      if (endOfMonth > yesterday) setEndDate(yesterday);
      else setEndDate(endOfMonth);
    } else setEndDate(date[1]);
  };

  const handleOfferSelect = (oid) => {
    if (subscription["userOffers"].indexOf(oid) === -1) {
      subscription["userOffers"].push(oid);
    } else {
      subscription["userOffers"].splice(
        subscription["userOffers"].indexOf(oid),
        1
      );
      offerBundleMap.get(oid).forEach((bundleId) => {
        if (subscription["userBundles"].indexOf(bundleId) !== -1) {
          subscription["userBundles"].splice(
            subscription["userBundles"].indexOf(bundleId),
            1
          );
        }
      });
      setBundleDetails(
        bundleDetails
          .filter((b) => offerBundleMap.get(oid).indexOf(b) === -1)
          .slice()
      );
    }
    if (bundleSelectAllMap.indexOf(oid) !== -1) {
      bundleSelectAllMap.splice(bundleSelectAllMap.indexOf(oid), 1);
      setBundleSelectAllMap(bundleSelectAllMap.slice());
    }
    
    setSubscription(cloneDeep(subscription));
  };

  const handleBundleSelect = (bid) => {
    if (subscription["userBundles"].indexOf(bid) === -1) {
      subscription["userBundles"].push(bid);
    } else {
      subscription["userBundles"].splice(
        subscription["userBundles"].indexOf(bid),
        1
      );
    }
    
    setSubscription(cloneDeep(subscription));
  };

  const handleOfferDetailsClick = (oid) => {
    if (offerDetails.indexOf(oid) === -1) {
      offerDetails.push(oid);
    } else {
      offerDetails.splice(offerDetails.indexOf(oid), 1);
    }
    setOfferDetails(offerDetails.slice());
  };

  const handleCdrSelect = (e) => {
    setCdrRequest(e);
    setSelectAll(false);
    subscription['userBundles'].length=0;
    setSubscription(cloneDeep(subscription));
  }

  const handleBundleDetailsClick = (bid) => {
    if (bundleDetails.indexOf(bid) === -1) {
      bundleDetails.push(bid);
    } else {
      bundleDetails.splice(bundleDetails.indexOf(bid), 1);
    }
    setBundleDetails(bundleDetails.slice());
  };

  const getServiceTypeArr = () => {
    const serviceTypeArr = [];

    for (const bundle of subscription.userBundles) {
      serviceTypeArr.push(bundleMap.get(bundle).bundleServiceType);
    }
    return serviceTypeArr;
  };

  const getSystemType = () => {
    const serviceTypeArr = [];

    for (const offer of subscription.userOffers) {
      serviceTypeArr.push(offerMap.get(offer).offerSystemType);
    }
    return serviceTypeArr;
  };

  const getLinkIdList = () => {
    const linkIdList = [];

    const serviceTypeArr = getServiceTypeArr();
    for (const serviceType of serviceTypeArr) {
      const appType = serviceAppMap[serviceType];
      Object.keys(linkIdAppTypeMap).forEach((linkId) => {
        if (linkIdAppTypeMap[linkId].includes(appType)) {
          linkIdList.push(linkId);
        }
      });
      return linkIdList;
    }
  };

  const allFieldsCompleted = () => {
    return (
      startDate &&
      endDate &&
      subscription.userBundles.length > 0 &&
      subscription.userOffers.length > 0
    );
  };
  const formatDate=(date)=> {

    const strDate = date.toLocaleDateString('en-US'); //mm/dd/yyyy
    const parts = strDate.split("/").map((part) => part.padStart(2, '0'));
    const [month, day, year] = parts;
    return `${year}-${month}-${day}`;

}

  const handleSubmit = async () => {
    try {
      setRequestStatus("INFLIGHT");
      const serviceTypeToSubmit = getServiceTypeArr();
      const systemTypeToSubmit = getSystemType();
      const linkIdList = getLinkIdList();
      for (const linkId of linkIdList) {
        
        const payload = {
          startDate: formatDate(startDate),
          endDate: formatDate(endDate),
          subscriptionId: subId,
          linkId,
          appTypes: linkIdAppTypeMap[linkId],
          serviceTypes: serviceTypeToSubmit,
          systemTypes: systemTypeToSubmit,
          stage: Utils.getCurrentStage(),
          cdrRequest,
        };
        
        console.log("payload",payload)
        const res = await API.post("SspBackendApi", "/generateReport", {
          body: payload,
          queryStringParameters: {
            stage: Utils.getCurrentStage(),
            id: user.username,
            region: user.pool.userPoolId.split("_")[0],
          },
        });
        //TODO: finish recentActivity
        const requestId = res.requestId;
        payload.requestId = requestId;
        setRecentActivity(payload);
      }

      // Clear the failure message
      setRequestStatus("COMPLETED");

    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = JSON.stringify(error.response.data.message);
        if (errorMessage.includes("is not authorized")) {
          setRequestStatus("UNAUTHORIZED");
        } else {
          setRequestStatus("FAILED");
        }
      }
    }
  };

  const handleSelectAllOffers = () => {
    subscription["userOffers"].length = 0;
    subscription["userBundles"].length = 0;
    setSelectAll(true);
    for (const offer of userOffersOptions) {
      subscription["userOffers"].push(offer.offerId);
    }
    for (const bundle of userBundleOptions) {
      if(bundle.show)      
      subscription["userBundles"].push(bundle.bundleId);
    }
  };

  const handleResetOffers = () => {
    setSelectAll(false);
    subscription["userOffers"].length = 0;
    subscription["userBundles"].length = 0;

    bundleDetails.length = 0;
    bundleSelectAllMap.length = 0;
    offerDetails.length = 0;

    setBundleDetails(bundleDetails.slice());
    setBundleSelectAllMap(bundleSelectAllMap.slice());
    setOfferDetails(offerDetails.slice());

    setSubscription(cloneDeep(subscription));
  };

  //#endregion

  //#region load catalog information

  const loadOffers = (countryCode = null) => {
    return new Promise(async (resolve, reject) => {
      let cachedOffers = Utils.fetchFromSessionStorage(
        user.username,
        countryCode ? countryCode + ".offers" : "offers",
        [],
        true
      );
      if (cachedOffers.length > 0) {
        return resolve(cachedOffers);
      }
      let params = {
        response: false,
        queryStringParameters: {
          stage: curStage,
          path: "offers",
          countryCode: countryCode,
          region: user.pool.userPoolId.split("_")[0],
        },
      };
      await API.get("SspBackendApi", "/catalog", params)
        .then((response) => {
          const rOffers = JSON.parse(JSON.parse(response).body);
          if (rOffers && rOffers.length > 0) {
            Utils.setInSessionStorage(
              user.username,
              countryCode ? countryCode + ".offers" : "offers",
              rOffers,
              true
            );
            return resolve(rOffers);
          }
        })
        .catch((error) => {
          console.error(JSON.stringify(error));
          reject([]);
        });
    });
  };

  const loadBundles = (countryCode = null) => {
    return new Promise(async (resolve, reject) => {
      let cachedBundles = Utils.fetchFromSessionStorage(
        user.username,
        countryCode ? countryCode + ".bundles" : "bundles",
        [],
        true
      );
      if (cachedBundles.length > 0) {
        return resolve(cachedBundles);
      }

      let params = {
        response: false,
        queryStringParameters: {
          stage: curStage,
          path: "bundles",
          countryCode: countryCode,
          region: user.pool.userPoolId.split("_")[0],
        },
      };

      await API.get("SspBackendApi", "/catalog", params)
        .then((response) => {
          const rBundles = JSON.parse(JSON.parse(response).body);
          if (rBundles && rBundles.length > 0) {
            Utils.setInSessionStorage(
              user.username,
              countryCode ? countryCode + ".bundles" : "bundles",
              rBundles,
              true
            );
            return resolve(rBundles);
          }
        })
        .catch((error) => {
          console.error(JSON.stringify(error));
          return reject([]);
        });
    });
  };

  const loadInitData = async () => {
    setSpinner({
      ...spinner,
      status: true,
      message: `Fetching offers and bundle...`,
    });
    const { bundleCdr } = createServiceAppMap();
    const initDataPromises = [loadOffers(), loadBundles()];
    Promise.all(initDataPromises)
      .then((res) => {
        if (res[0].length > 0 && res[1].length > 0) {
          setSpinner({ ...spinner, status: false, message: "" });
          originalOffers.current = res[0];

          const filteredOffers = [];
          
          const filteredBundles = [];
          //filter offers for subscription
          const systemTypesOfSub = systemTypes.map((obj) => {
            return obj.systemType;
          });
          const serviceTypesOfSub = systemTypes.map((obj) => {
            return obj.serviceType;
          });
          // filter offers for subscription
          for (const offer of res[0]) {
            if (systemTypesOfSub.indexOf(offer.offerSystemType) !== -1) {
              filteredOffers.push(offer);
            }
          }
          // filter bundles for subscription
          for (const bundle of res[1]) {
            if (
              
              serviceTypesOfSub.includes(bundle.bundleServiceType)
            ) {
              bundle.show = bundleCdr[bundle.bundleServiceType]===cdrRequest;
              filteredBundles.push(bundle);
            }
          }
          setUserOffersOptions(filteredOffers);
          setUserBundleOptions(filteredBundles);
        }
      })
      .catch((error) => {
        console.error("An error occurred while fetching initial data: ", error);
      });
  };

  useEffect(()=>{
    const updatedUserBundleOptions = userBundleOptions.map(bundle => ({
      ...bundle,
      show: bundleCdrMap[bundle.bundleServiceType] === cdrRequest,
    }));
  
    setUserBundleOptions(updatedUserBundleOptions);
  },[cdrRequest]); // eslint-disable-line react-hooks/exhaustive-deps
  

  useEffect(() => {
    const roleAttribute = user["attributes"]["custom:role"];
    let roleValue = roleAttribute
      ? JSON.parse(roleAttribute)
      : RBACUtils.getDefaultUserRole();
    Utils.setInSessionStorage(user.username, "userRole", roleValue);

    loadInitData();
  }, [systemTypes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let offers = userOffersOptions.map((o) => o.offerId);
    let bundles = userBundleOptions.map((b) => b.bundleId);

    let fo = subscription["userOffers"].filter(
      (oid) => offers.indexOf(oid) !== -1
    );
    let fb = subscription["userBundles"].filter(
      (bid) => bundles.indexOf(bid) !== -1
    );
    setSubscription(
      cloneDeep({
        ...subscription,
        userOffers: fo,
        userBundles: fb,
      })
    );
  }, [userOffersOptions,userBundleOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  //#endregion

  return (
    <Container fluid className="p-2 mb-4">
      <Row className="">
        <Col>
          <Container
            fluid
            data-bs-spy="scroll"
            data-bs-target="#navbar"
            data-bs-offset="0"
            tabIndex="0"
          >
            <Row className="d-row">
              {/* top heading bar */}
              <Row className="g-0 m-0 p-0">
                <Col className="title-wrap d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-end title-settings"></div>
                </Col>
              </Row>

              <Spinner spinner={spinner} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: "light",
                  width: "100%",
                }}
              >
                &nbsp;
              </div>
              {/* user options */}
              <Row className="g-0 px-0 pt-0 pb-0 uo-area pc ">
                <Col>
                  <Collapse in={true}>
                    <div>
                      <div className="d-flex align-items-center justify-content-between bg-light py-2 px-2 w-auto rounded">
                        <Form.Group
                          id="only-cdr-toggle"
                          className="flex flex-col align-items-center"
                        >
                          <Form.Check
                            type="radio"
                            label="CDR"
                            name="cdrOptions"
                            id="cdr"
                            onChange={() => handleCdrSelect(true)}
                            checked={cdrRequest}
                            className="me-2"
                          />
                          <Form.Check
                            type="radio"
                            label="Non-CDR"
                            name="cdrOptions"
                            id="non-cdr"
                            onChange={() => handleCdrSelect(false)}
                            checked={!cdrRequest}
                          />
                        </Form.Group>

                        {!selectAll && (
                          <div>
                            <div>
                              <small>Select all</small>
                            </div>
                            <div onClick={() => handleSelectAllOffers()}>
                              <MdSelectAll />
                            </div>
                          </div>
                        )}
                        {selectAll && (
                          <div>
                              <div>
                                <small>Unselect all</small>
                              </div>
                              <div
                                // className="reset"
                                onClick={() => handleResetOffers()}
                              >
                                <MdDeselect />
                              </div>

                          </div>
                        )}
                        <div>
                          <Form>
                            <Form.Check
                              type="radio"
                              label="Day"
                              name="day-radio-btn"
                              id="day-radio-btn"
                              value={true}
                              checked={!monthView}
                              onChange={() => setMonthView(false)}
                            />
                            <Form.Check
                              type="radio"
                              label="Month"
                              name="month-radio-btn"
                              id="month-radio-btn"
                              value={true}
                              checked={monthView}
                              onChange={() => setMonthView(true)}
                            />
                          </Form>
                        </div>
                        <div>
                          <DatePicker
                            className="report-date-picker"
                            selected={startDate}
                            onChange={handleDatePick}
                            dateFormat={"dd/MM/yyyy"}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={yesterday} // yesterday
                            selectsRange
                            showMonthYearPicker={monthView}
                          />
                        </div>
                        <GenerateReportBtn
                          requestStatus={requestStatus}
                          disabled={!allFieldsCompleted()}
                          onClick={() => handleSubmit()}
                        />
                      </div>
                    </div>
                  </Collapse>
                </Col>
              </Row>

              {/* Content */}
              <Row className="g-0 m-0 p-0">
                <Col
                  className="border-0 rounded px-0 py-0 mx-1"
                  style={{ backgroundColor: "#fff" }}
                >
                  <h4
                    className="text-center section-title"
                    style={{
                      backgroundColor: "#c4e4ff",
                      color: "#333",
                      fontWeight: "normal",
                    }}
                  >
                    Offers
                  </h4>
                  <Scrollbars
                    renderTrackVertical={(props) => (
                      <div {...props} className="track-vertical" />
                    )}
                    style={{ height: 800 }}
                  >
                    <ListGroup as="ul" className="list-group-light offer-block">
                      {/* TODO:Make offers without bundles un-clickable */}
                      {userOffersOptions.map(
                        
                        (o) =>
                          o.offerBundles.length > 0 && (
                            <ListGroupItem
                              as="li"
                              action
                              key={"offer-" + o.offerId}
                            >
                              <input
                                className="form-check-input offer-check"
                                style={{ cursor: "pointer" }}
                                type="checkbox"
                                checked={
                                  subscription["userOffers"].indexOf(
                                    o.offerId
                                  ) === -1
                                    ? false
                                    : true
                                }
                                id={"offer-" + o.offerId}
                                onChange={() => handleOfferSelect(o.offerId)}
                              />
                              <label
                                className="form-check-label text-truncate"
                                style={{
                                  cursor: "pointer",
                                  padding: "0px 0px 0px 8px",
                                  maxWidth: "85%",
                                }}
                                htmlFor={"offer-" + o.offerId}
                                title={o.offerCommercialName}
                              >
                                {o.offerCommercialName}
                              </label>
                              <div
                                className="float-end"
                                style={{
                                  cursor: "pointer",
                                  color: "#9dceff",
                                  fontSize: "22px",
                                  marginTop: "-4px",
                                }}
                                onClick={() =>
                                  handleOfferDetailsClick(o.offerId)
                                }
                                aria-controls={o.offerId}
                                aria-expanded={
                                  offerDetails.indexOf(o.offerId) !== -1
                                }
                              >
                                {offerDetails.indexOf(o.offerId) !== -1 ? (
                                  <BsFillPatchMinusFill />
                                ) : (
                                  <BsFillPatchPlusFill />
                                )}
                              </div>
                              <Collapse
                                in={offerDetails.indexOf(o.offerId) !== -1}
                              >
                                <div id={o.offerId}>
                                  <div
                                    className="px-3 py-2 mt-2 text-justify"
                                    style={{
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      borderTopLeftRadius: "5px",
                                      borderTopRightRadius: "5px",
                                    }}
                                  >
                                    {o.offerDescription}
                                  </div>
                                  <p
                                    className="mb-0"
                                    style={{
                                      backgroundColor: "#fff",
                                      padding: "8px 12px 0px",
                                      borderRadius: "0px",
                                      borderTop: "1px solid #e4f2ff",
                                    }}
                                  >
                                    System Type:{" "}
                                    <span style={{ fontWeight: "500" }}>
                                      {o.offerSystemType}
                                    </span>
                                  </p>
                                  <div
                                    className="pb-1 pe-3 text-end link-secondary"
                                    style={{
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                      borderTopLeftRadius: "0px",
                                      borderTopRightRadius: "0px",
                                      cursor: "pointer",
                                      fontSize: "13px",
                                    }}
                                    onClick={() =>
                                      setModal({
                                        show: true,
                                        type: "offer",
                                        id: o.offerId,
                                      })
                                    }
                                  >
                                    details&raquo;
                                  </div>
                                </div>
                              </Collapse>
                            </ListGroupItem>
                          )
                      )}
                    </ListGroup>
                  </Scrollbars>
                </Col>
                <Col
                  className="border-0 rounded px-0 py-0 mx-1"
                  style={{ backgroundColor: "#fff" }}
                >
                  <h4
                    className="text-center section-title"
                    style={{ backgroundColor: "#e2bff1" }}
                  >
                    Bundles
                  </h4>
                  <Scrollbars
                    renderTrackVertical={(props) => (
                      <div {...props} className="track-vertical" />
                    )}
                    style={{ height: 800 }}
                  >
                    {subscription["userOffers"].map((o) => (
                      <Tabs id={o} className="bundle-tabs">
                        <Tab
                          eventKey={o}
                          key={o}
                          title={offerMap.get(o).offerCommercialName}
                        >
                          <OverlayTrigger
                            placement="top"
                            trigger={["hover", "focus"]}
                            delay={50}
                            overlay={
                              <Tooltip
                                className="bs-tooltip"
                                id={"offer-bundle-tooltip-" + o}
                              >
                                {bundleSelectAllMap.indexOf(o) === -1
                                  ? "Select all bundles"
                                  : "Unselect all bundles"}
                              </Tooltip>
                            }
                          >
                            <></>
                          </OverlayTrigger>
                          <ListGroup
                            as="ul"
                            className="list-group-light bundle-block"
                          >
                            {userBundleOptions.map((b) => (
                              
                              offerBundleMap.get(o).includes(b.bundleId)&&
                              b.show&&
                              <ListGroupItem
                                as="li"
                                action
                                key={"bundle-" + b.bundleId}
                              >
                                <input
                                  className="form-check-input bundle-check"
                                  checked={
                                    subscription["userBundles"].indexOf(
                                      b.bundleId
                                    ) === -1
                                      ? false
                                      : true
                                  }
                                  style={{ cursor: "pointer" }}
                                  type="checkbox"
                                  id={"bundle-" + b.bundleId}
                                  onChange={() =>
                                    handleBundleSelect(b.bundleId)
                                  }
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label text-truncate"
                                  style={{
                                    maxWidth: "85%",
                                    cursor: "pointer",
                                  }}
                                  htmlFor={"bundle-" + b.bundleId}
                                  title={
                                    bundleMap.get(b.bundleId)
                                      .bundleCommercialName
                                  }
                                >
                                  {
                                    bundleMap.get(b.bundleId)
                                      .bundleCommercialName
                                  }
                                </label>
                                <div
                                  className="float-end"
                                  style={{
                                    cursor: "pointer",
                                    color: "#d9aaec",
                                    fontSize: "22px",
                                    marginTop: "-4px",
                                  }}
                                  onClick={() =>
                                    handleBundleDetailsClick(b.bundleId)
                                  }
                                  aria-controls={b.bundleId}
                                  aria-expanded={
                                    bundleDetails.indexOf(b.bundleId) !== -1
                                  }
                                >
                                  {bundleDetails.indexOf(b.bundleId) !== -1 ? (
                                    <BsFillPatchMinusFill />
                                  ) : (
                                    <BsFillPatchPlusFill />
                                  )}
                                </div>
                                <Collapse
                                  in={bundleDetails.indexOf(b.bundleId) !== -1}
                                >
                                  <div id={b.bundleId}>
                                    <div
                                      className="px-3 py-2 mt-2 text-justify"
                                      style={{
                                        backgroundColor: "#fff",
                                        borderTopLeftRadius: "5px",
                                        borderTopRightRadius: "5px",
                                      }}
                                    >
                                      {
                                        bundleMap.get(b.bundleId)
                                          .bundleDescription
                                      }
                                    </div>
                                    <p
                                      className="mb-0"
                                      style={{
                                        backgroundColor: "#fff",
                                        padding: "8px 12px",
                                        borderRadius: "0px",
                                        borderTop: "1px solid #f6ecfb",
                                      }}
                                    >
                                      Service Type:{" "}
                                      <span style={{ fontWeight: "500" }}>
                                        {
                                          bundleMap.get(b.bundleId)
                                            .bundleServiceType
                                        }
                                      </span>
                                    </p>
                                    <p
                                      className="mb-0"
                                      style={{
                                        backgroundColor: "#fff",
                                        padding: "8px 12px",
                                        borderBottomLeftRadius: "5px",
                                        borderBottomRightRadius: "5px",
                                        borderTop: "1px solid #f6ecfb",
                                      }}
                                    >
                                      Material Code:{" "}
                                      <span style={{ fontWeight: "500" }}>
                                        {
                                          bundleMap.get(b.bundleId)
                                            .bundleSmartCode
                                        }
                                      </span>
                                    </p>
                                    <div
                                      className="pb-1 pe-3 text-end link-secondary"
                                      style={{
                                        backgroundColor: "#fff",
                                        borderRadius: "5px",
                                        borderTopLeftRadius: "0px",
                                        borderTopRightRadius: "0px",
                                        cursor: "pointer",
                                        fontSize: "13px",
                                      }}
                                      onClick={() =>
                                        setModal({
                                          show: true,
                                          type: "bundle",
                                          id: b.bundleId,
                                        })
                                      }
                                    >
                                      details&raquo;
                                    </div>
                                  </div>
                                </Collapse>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        </Tab>
                      </Tabs>
                    ))}
                  </Scrollbars>
                </Col>
              </Row>
            </Row>
          </Container>
        </Col>

        <Modal
          size="lg"
          show={modal["show"]}
          onHide={() => setModal({ show: false, type: "", id: "" })}
          keyboard={true}
          backdrop
          className={modal["type"]}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="ps-3">
                {modal["type"] === "offer"
                  ? offerMap.get(modal["id"]).offerCommercialName
                  : modal["type"] === "bundle"
                  ? bundleMap.get(modal["id"]).bundleCommercialName
                  : modal["type"] === "app"
                  ? pcApplicationMap.get(modal["id"]).applicationCommercialName
                  : ""}
              </div>
              <div
                className="text-muted ps-3 pt-1"
                style={{ fontSize: "14px", fontWeight: "normal" }}
              >
                Additional information for the selected{" "}
                {modal["type"] === "app"
                  ? "Application"
                  : Utils.displayTitle(modal["type"])}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Scrollbars
              renderTrackVertical={(props) => (
                <div {...props} className="track-vertical" />
              )}
              autoHeight
              autoHeightMin={100}
              autoHeightMax={650}
            >
              <Container fluid className="p-0">
                {modal["type"] === "offer"
                  ? Object.keys(offerMap.get(modal["id"])).map((k) => (
                      <Row
                        className="mx-2 py-1 d-flex align-items-center justify-content-start"
                        style={{ borderBottom: "1px dashed #f1f1f1" }}
                      >
                        <Col md={3} className="px-1 py-1 fw-bold text-black-50">
                          {Utils.displayTitle(k)}
                        </Col>
                        <Col md={9} className="px-1 py-1">
                          <div className="text-break">
                            {k === "offerBundles"
                              ? offerMap.get(modal["id"])[k].map((b) => (
                                  <div className="">
                                    <small>
                                      {b.bundleName}{" "}
                                      <span className="ms-1">
                                        <small>[{b.bundleServiceType}]</small>
                                      </span>
                                    </small>
                                  </div>
                                ))
                              : Utils.displayValue(
                                  offerMap.get(modal["id"])[k]
                                )}
                          </div>
                        </Col>
                      </Row>
                    ))
                  : modal["type"] === "bundle"
                  ? Object.keys(bundleMap.get(modal["id"]))
                      .filter((k) => k !== "bundleCapabilities")
                      .map((k) => (
                        <Row
                          className="mx-2 py-1 d-flex align-items-center justify-content-start"
                          style={{ borderBottom: "1px dashed #f1f1f1" }}
                        >
                          <Col
                            md={4}
                            className="px-1 py-1 fw-bold text-black-50"
                          >
                            {Utils.displayTitle(k)}
                          </Col>
                          <Col md={8} className="px-1 py-1">
                            <div className="text-break">
                              {[
                                "bundleProperties",
                                "bundleOrderDetails",
                                "bundleCountryAvailability",
                              ].indexOf(k) !== -1 ? (
                                <ReactJson
                                  src={bundleMap.get(modal["id"])[k]}
                                  name={null}
                                  iconStyle="triangle"
                                  indentWidth={2}
                                  collapsed={
                                    k === "bundleCountryAvailability"
                                      ? true
                                      : false
                                  }
                                  enableClipboard={false}
                                  displayObjectSize={false}
                                  displayDataTypes={false}
                                  quotesOnKeys={false}
                                  groupArraysAfterLength={1000}
                                  displayArrayKey={false}
                                />
                              ) : (
                                Utils.displayValue(
                                  bundleMap.get(modal["id"])[k]
                                )
                              )}
                            </div>
                          </Col>
                        </Row>
                      ))
                  : modal["type"] === "app"
                  ? Object.keys(pcApplicationMap.get(modal["id"])).map((k) => (
                      <Row
                        className="mx-2 py-1 d-flex align-items-center justify-content-start"
                        style={{ borderBottom: "1px dashed #f1f1f1" }}
                      >
                        <Col md={5} className="px-1 py-1 fw-bold text-black-50">
                          {Utils.displayTitle(k)}
                        </Col>
                        <Col md={7} className="px-1 py-1 text-break">
                          {Utils.displayValue(
                            pcApplicationMap.get(modal["id"])[k]
                          )}
                        </Col>
                      </Row>
                    ))
                  : ""}
              </Container>
            </Scrollbars>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => setModal({ show: false, type: "", id: "" })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </Container>
  );
};

export { OfferBundlesView };
