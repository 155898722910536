import * as RBACUtils from '../../common/RBACUtils';
import { isFinOpsEnv } from "../../common/utils";
import { MdCloudQueue } from "react-icons/md";

import '../../scss/left_side_bar.scss';

const LeftSideBar = ({ active, username }) => {

  return (
    <>
      <div className='main-app-title' onClick={() => window.location = '/'}>
        <div className='d-flex align-items-center justify-content-center app-title-wrap'>
          <div className='app-icon'><MdCloudQueue /></div>
          <div className='app-icon-tag'>
            <p className='p main fw-bold text-start'>Cloud</p>
            <p className='p sec text-start'>Management</p>
          </div>
        </div>
        <p className='s'>Simplified cloud services management </p>
      </div>
      <div className="v-l-nav">
        {!RBACUtils.hasSubscriptionAccess(username, "NONE") && (
          <>
            <p className='muted-category'>SUBSCRIPTION</p>
            <ul className='sc'>
              <li><a className={active === 'ls' ? 'active' : ''} href='/sub/list'>Subscriptions</a></li>
              <li><a className={active === 'lo' ? 'active' : ''} href='/order/list'>Orders</a></li>
              {
                RBACUtils.hasFieldCustomizationAccess(username) && !RBACUtils.hasSubscriptionAccess(username, 'NONE') &&
                <li><a className={active === 'fc' ? 'active' : ''} href='/user/fc'>Fields customizations</a></li>
              }
            </ul>
          </>
        )}
        <p className="muted-category">CATALOG</p>
        <ul className="sc">
          <li><a className={active === "pc" ? "active" : ""} href="/pc">Product catalog</a></li>
        </ul>
        <p className='muted-category'>USER</p>
        <ul className='sc'>
          <li><a className={active === 'up' ? 'active' : ''} href='/user/profile'>User profile</a></li>
          {
            RBACUtils.hasRole(username, 'ADMINISTRATOR') &&
            <li><a className={active === 'um' ? 'active' : ''} href='/user/manage'>User management</a></li>
          }
        </ul>
        {!RBACUtils.hasSubscriptionAccess(username, "NONE") && (
          <>
            <p className="muted-category">WORKFLOW</p>
            <ul className="sc">
              <li><a className={active === "wf" ? "active" : ""} href="/bpmn">Workflows</a></li>
              <li><a className={active === "lw" ? "active" : ""} href="/liveworkflow">Live workflows</a></li>
            </ul>
          </>
        )}
        {isFinOpsEnv() && (
          <>
            <p className="muted-category">FINOPS</p>
            <ul className="sc">
              <li><a className={active === "en" ? "active" : ""} href="/environments">Environments</a></li>
              <li><a className={active === "pr" ? "active" : ""} href="/products">Products</a></li>
            </ul>
          </>
        )}
      </div>

    </>
  );
};
export default LeftSideBar;