import { API, Cache } from "aws-amplify";
import { getCurrentStage } from "../../../common/utils";
export async function getFlowIdList() {
  try {
    let apiresponse = await API.get("devopsUIAPI", "/updateflowlist");
    Cache.setItem("flow_id_list", apiresponse["Items"]);
    console.log(apiresponse);
  } catch (e) {
    console.error(e);
  }
}

export async function BpmnInteractive(context) {
  let apiresponse;
  try {
    let queryPayload = {
      stage: getCurrentStage(),
      flow_id: context.flowId,
      flow_type: context.flowType,
      injection_api_name: "ruleEngineV2",
      bpmn_function_name: "interactiveWorkflow",
    };
    apiresponse = await API.get("bpmnGen", "/getbpmn", {
      queryStringParameters: queryPayload,
    });
    context.loading = false;
  } catch (e) {
    context.searchError = true;
    context.loading = false;
    if (e.response.status === 404) {
      // return errorResponse;
    }
    throw e;
  }
  context.loading = false;
  return apiresponse;
}
export async function loadRuleEngine(flowId) {
  let x = {
    flow_id: "ca160adbb8ac4d08833c32dee29cf719",
    creation_time: "2022-09-06 07:51:39.734",
    action_description: "1 - create a Jira dashboard",
    request: {
      action_type: "CREATE_DASHBOARD",
      dashboard_id: "ca160adbb8ac4d08833c32dee29cf719",
      dashboard_params: {
        components: ["Avaya OneCloud Public CCaaS"],
        is_components_init: "True",
        project: "BUSSOL",
      },
      dashboard_type: "JIRA",
      payload: [
        {
          item: {
            components: [
              {
                name: "Avaya OneCloud Public CCaaS",
              },
            ],
            customfield_16170: {
              id: "16743",
              value: "Ensure Serviceability",
            },
            description:
              "\t+*Contract:*+\r\n\t8813190985\r\n\r\n\t+*Order Details:*+\r\n        Region: NA\r\n\tCountry: US (United States)\r\n\tEnterprise ID: 0001148683\r\n\tOrder Number: 0123456789\r\n\tTechnical Contact Name: Test User\r\n\tTechnical Contact Email: avivekverma@avaya.com\r\n\tTechnical Contact Phone: 525555246961\r\n\tCustomer Email: avivekverma@avaya.com\r\n\tCustomer Email Domain: 9913190989.com\r\n\tOpportunity Number: 0003682314\r\n\t\r\n\t+*Applications:*+\r\n\r\n\t||Type||Data Center||Account||subAccount||Capabilities||\r\n\t|CCaaSDevops |cbs-demo-1| | |CPaaS SIP Trunk|\r\n\t|IXCC |cbs-demo-1| | |Dynamic Self-Service, Call Back, Post Call Survey, CC Desktop, Proactive Outreach Â Preview Add-on, Business Voice, Attribute Matching, Operational Reporting, Self Service Interactions, PSTN Voice, Journey Service, Call Recording|\r\n\t\r\n \r\n\t||Offers||Service Type||Bundle Name||Bundle Count||\r\n\t|Avaya OneCloud Public CCaaS(CCAAS_PUBLIC_CLOUD)|CC_VOICE_SUBS|(Omni-channel) Voice|70|\r\n",
            duedate: "2022-12-05",
            environment:
              "[Activation Flow - Flow ID: ca160adbb8ac4d08833c32dee29cf719|https://d2o22gb3una6yn.cloudfront.net/bpmn?flow_id=0123456789&flow_type=flow_external_id]",
            issuetype: {
              name: "Story",
            },
            labels: [
              "CBS:Activation_Started",
              "Verint_Ticket:TBA",
              "APS:TBA",
              "Gainsight:TBA",
            ],
            project: {
              id: "26125",
            },
            summary:
              "New Order #0123456789 CN: HOTEL BARCELO MAYA BEACH RESORT ST: 0050258266 Contract: 8813190985",
          },
          subitems: [
            {
              item: {
                description: "CBSP Automated Activation description",
                duedate: "2022-09-07",
                issuetype: {
                  name: "Sub-task",
                },
                labels: ["Provisioning:CBSP_Automated_Activation"],
                project: {
                  id: "26125",
                },
                summary: "CBSP Automated Activation",
              },
            },
            {
              item: {
                description: "Cloudops Configure Verint description",
                duedate: "2022-09-08",
                issuetype: {
                  name: "Sub-task",
                },
                labels: ["Provisioning:Cloudops_Configure_Verint"],
                project: {
                  id: "26125",
                },
                summary: "Cloudops Configure Verint",
              },
            },
            {
              item: {
                description: "APS: Project Started description",
                duedate: "2022-09-08",
                issuetype: {
                  name: "Sub-task",
                },
                labels: ["APS:PROJECT_STARTED"],
                project: {
                  id: "26125",
                },
                summary: "APS: Project Started",
              },
            },
            {
              item: {
                description: "APS: Pm Assigned description",
                duedate: "2022-09-10",
                issuetype: {
                  name: "Sub-task",
                },
                labels: ["APS:PM_ASSIGNED"],
                project: {
                  id: "26125",
                },
                summary: "APS: Pm Assigned",
              },
            },
            {
              item: {
                description: "APS: Project Complete description",
                duedate: "2022-12-05",
                issuetype: {
                  name: "Sub-task",
                },
                labels: ["APS:PROJECT_COMPLETE"],
                project: {
                  id: "26125",
                },
                summary: "APS: Project Complete",
              },
            },
            {
              item: {
                description: "CCaaS Public Cloud: CSM Introduction description",
                duedate: "2022-09-08",
                issuetype: {
                  name: "Sub-task",
                },
                labels: ["CCaaS_Public_Cloud:CSM_Introduction"],
                project: {
                  id: "26125",
                },
                summary: "CCaaS Public Cloud: CSM Introduction",
              },
            },
            {
              item: {
                description:
                  "CCaaS Public Cloud: CSM Internal Call description",
                duedate: "2022-09-08",
                issuetype: {
                  name: "Sub-task",
                },
                labels: ["CCaaS_Public_Cloud:CSM_Internal_Call"],
                project: {
                  id: "26125",
                },
                summary: "CCaaS Public Cloud: CSM Internal Call",
              },
            },
            {
              item: {
                description:
                  "CCaaS Public Cloud: Lets Get Started Email description",
                duedate: "2022-09-14",
                issuetype: {
                  name: "Sub-task",
                },
                labels: ["CCaaS_Public_Cloud:Lets_Get_Started_Email"],
                project: {
                  id: "26125",
                },
                summary: "CCaaS Public Cloud: Lets Get Started Email",
              },
            },
            {
              item: {
                description:
                  "CCaaS Public Cloud: Customer Engagement Meeting description",
                duedate: "2022-09-14",
                issuetype: {
                  name: "Sub-task",
                },
                labels: ["CCaaS_Public_Cloud:Customer_Engagement_Meeting"],
                project: {
                  id: "26125",
                },
                summary: "CCaaS Public Cloud: Customer Engagement Meeting",
              },
            },
            {
              item: {
                description:
                  "CCaaS Public Cloud: Kick-off Call Date With Customer description",
                duedate: "2022-10-01",
                issuetype: {
                  name: "Sub-task",
                },
                labels: ["CCaaS_Public_Cloud:Kick-off_Call_Date_With_Customer"],
                project: {
                  id: "26125",
                },
                summary: "CCaaS Public Cloud: Kick-off Call Date With Customer",
              },
            },
            {
              item: {
                description:
                  "CCaaS Public Cloud: CSM To Forward Login Credentials To Customer description",
                duedate: "2022-12-05",
                issuetype: {
                  name: "Sub-task",
                },
                labels: [
                  "CCaaS_Public_Cloud:CSM_To_Forward_Login_Credentials_To_Customer",
                ],
                project: {
                  id: "26125",
                },
                summary:
                  "CCaaS Public Cloud: CSM To Forward Login Credentials To Customer",
              },
            },
            {
              item: {
                description:
                  "CCaaS Public Cloud: Moving To Adoption description",
                duedate: "2022-12-05",
                issuetype: {
                  name: "Sub-task",
                },
                labels: ["CCaaS_Public_Cloud:Moving_To_Adoption"],
                project: {
                  id: "26125",
                },
                summary: "CCaaS Public Cloud: Moving To Adoption",
              },
            },
          ],
        },
      ],
    },
    request_path:
      "services/servicename=ruleEngineV2/data=triggers/0123456789/ca160adbb8ac4d08833c32dee29cf719/ca160adbb8ac4d08833c32dee29cf719_1/request_07:51:39.358950.json",
    status_code: "200",
    step_id: "ca160adbb8ac4d08833c32dee29cf719_1",
    step_name: "Initiate Activation step",
    subscription_id: "0123456789",
    timestamp: "2022-09-06T07:50:39Z",
    trigger_name: "dashboardActivation",
  };
  return x;
}
