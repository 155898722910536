import { useState, useEffect, useRef } from "react";

import ListGroup from 'react-bootstrap/ListGroup'
import { ListGroupItem, Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Collapse from 'react-bootstrap/Collapse';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { BsFillPatchPlusFill, BsFillPatchMinusFill } from "react-icons/bs";
import { MdOutlineCheckBox, MdOutlineIndeterminateCheckBox, MdSelectAll, MdDeselect } from "react-icons/md";

import ReactJson from '@microlink/react-json-view'

import { Scrollbars } from 'react-custom-scrollbars-2';
import cloneDeep from 'lodash.clonedeep';

import { Amplify, API } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import LeftSideBar from "../common/LeftSideBar";

import EnvDetails from '../../env-details.json';
import * as Utils from "../../common/utils";
import * as RBACUtils from '../../common/RBACUtils';

import '../../scss/common.scss';
import '../../scss/pc.scss';

import awsExports from '../../aws-exports';
Amplify.configure(awsExports);
const withAuthenticatorOptions = {
  hideSignUp: true
}

const curEnv = Utils.getCurrentEnv();
const curStage = Utils.getCurrentStage();

const DEPRECATED_OFFERS = [
  'CC_ACI_AUTO',
  'CCAAS_PUBLIC_B2B',
  'AURA_MIDMARKET_CLOUD',
  'AI_FLOW_STUDIO',
  'AVAYA_PSAAS_CLOUD',
  'ANALYTICS_CLOUD',
  'CPAAS_VIRTUAL_AGENT',
  'GOOGLE',
  'AT_Processing_310'
];

const ProductCatalog = ({ signOut, user }) => {

  const initData = {
    offers: [],
    bundles: [],
    apps: [],
    countrycodes: []
  };
  const INIT_SUB = { userOffers: [], userBundles: [], userApps: [], userCaps: {} };

  //#region states

  const [subscription, setSubscription] = useState(INIT_SUB);
  const [data, setData] = useState(initData);
  const [offerDetails, setOfferDetails] = useState([]);
  const [bundleDetails, setBundleDetails] = useState([]);
  const [bundleSelectAllMap, setBundleSelectAllMap] = useState([]);
  const [commercialStatus, setCommercialStatus] = useState('default');
  const [technicalStatus, setTechnicalStatus] = useState('default');
  const [filterCountry, setFilterCountry] = useState('default');
  const [spinner, setSpinner] = useState({ status: false, message: '' });
  const [modal, setModal] = useState({ show: false, type: '', id: '' });
  const [showOptions, setShowOptions] = useState(true);
  const [hideDeprecatedOffers, setHideDeprecatedOffers] = useState(true);

  const originalOffers = useRef([]);

  //#endregion

  //#region local static data
  const availableCommercialStatuses = [];
  const availableTechnicalStatuses = [];

  const offerMap = new Map();
  const bundleOfferMap = new Map();
  const offerBundleMap = new Map();
  data['offers'].forEach(o => {
    offerMap.set(o.offerId, o);
    o.offerBundles.forEach(b => {
      if (bundleOfferMap.get(b.bundleId) === undefined) {
        bundleOfferMap.set(b.bundleId, o.offerId);
      }
    });
    if (o.offerCommercialStatus && o.offerBundles.length > 0 && availableCommercialStatuses.indexOf(o.offerCommercialStatus) === -1) {
      availableCommercialStatuses.push(o.offerCommercialStatus);
    }
    if (o.offerTechnicalStatus && o.offerBundles.length > 0 && availableTechnicalStatuses.indexOf(o.offerTechnicalStatus) === -1) {
      availableTechnicalStatuses.push(o.offerTechnicalStatus);
    }
  });
  for (var i = 0; i < data['offers'].length; i++) {
    const tArr = [];
    for (var j = 0; j < data['offers'][i].offerBundles.length; j++) {
      tArr.push(data['offers'][i].offerBundles[j].bundleId);
    }
    offerBundleMap.set(data['offers'][i].offerId, tArr);
  }

  const bundleMap = new Map();
  const applicationMap = new Map();
  const applicationTypeToId = new Map();
  const capabilityMap = new Map();
  data['bundles'].forEach(b => {
    bundleMap.set(b.bundleId, b);
    b.bundleCapabilities.forEach(c => {
      if (applicationMap.get(c.applicationId) === undefined) {
        applicationMap.set(c.applicationId, c);
      }
      if (applicationTypeToId.get(c.applicationName) === undefined) {
        applicationTypeToId.set(c.applicationName, c.applicationId);
      }
      if (capabilityMap.get(c.capabilityId) === undefined) {
        capabilityMap.set(c.capabilityId, c);
      }
    });
    if (b.bundleCommercialStatus && availableCommercialStatuses.indexOf(b.bundleCommercialStatus) === -1) {
      availableCommercialStatuses.push(b.bundleCommercialStatus);
    }
    if (b.bundleTechnicalStatus && availableTechnicalStatuses.indexOf(b.bundleTechnicalStatus) === -1) {
      availableTechnicalStatuses.push(b.bundleTechnicalStatus);
    }
  });

  const pcApplicationMap = new Map();
  data['apps'].forEach(a => {
    if (pcApplicationMap.get(applicationTypeToId.get(a.applicationName)) === undefined) {
      pcApplicationMap.set(applicationTypeToId.get(a.applicationName), a);
    }
    if (a.applicationCommercialStatus && availableCommercialStatuses.indexOf(a.applicationCommercialStatus) === -1) {
      availableCommercialStatuses.push(a.applicationCommercialStatus);
    }
    if (a.applicationTechnicalStatus && availableTechnicalStatuses.indexOf(a.applicationTechnicalStatus) === -1) {
      availableTechnicalStatuses.push(a.applicationTechnicalStatus);
    }
  });

  //#endregion

  //#region functions

  const handleOfferSelect = (oid) => {
    if (subscription['userOffers'].indexOf(oid) === -1) {
      subscription['userOffers'].push(oid);
    } else {
      subscription['userOffers'].splice(subscription['userOffers'].indexOf(oid), 1);
      offerBundleMap.get(oid).forEach((bundleId) => {
        if (subscription['userBundles'].indexOf(bundleId) !== -1) {
          subscription['userBundles'].splice(subscription['userBundles'].indexOf(bundleId), 1);
        }
      });
      setBundleDetails(bundleDetails.filter(b => offerBundleMap.get(oid).indexOf(b) === -1).slice());
    }
    if (bundleSelectAllMap.indexOf(oid) !== -1) {
      bundleSelectAllMap.splice(bundleSelectAllMap.indexOf(oid), 1);
      setBundleSelectAllMap(bundleSelectAllMap.slice());
    }

    subscription['userApps'].length = 0;
    subscription['userCaps'] = {};
    subscription['userBundles'].forEach(bid => {
      bundleMap.get(bid).bundleCapabilities.forEach(c => {
        if (subscription['userApps'].indexOf(c.applicationId) === -1) {
          subscription['userApps'].push(c.applicationId);
          subscription['userCaps'][c.applicationId] = [c.capabilityId];
        } else {
          if (subscription['userCaps'][c.applicationId].indexOf(c.capabilityId) === -1) {
            subscription['userCaps'][c.applicationId].push(c.capabilityId);
          }
        }
      });
    });
    setSubscription(cloneDeep(subscription));
  };

  const handleBundleSelect = (bid) => {
    if (subscription['userBundles'].indexOf(bid) === -1) {
      subscription['userBundles'].push(bid);
    } else {
      subscription['userBundles'].splice(subscription['userBundles'].indexOf(bid), 1);
    }
    subscription['userApps'].length = 0;
    subscription['userCaps'] = {};
    subscription['userBundles'].forEach(bid => {
      bundleMap.get(bid).bundleCapabilities.forEach(c => {
        if (subscription['userApps'].indexOf(c.applicationId) === -1) {
          subscription['userApps'].push(c.applicationId);
          subscription['userCaps'][c.applicationId] = [c.capabilityId];
        } else {
          if (subscription['userCaps'][c.applicationId].indexOf(c.capabilityId) === -1) {
            subscription['userCaps'][c.applicationId].push(c.capabilityId);
          }
        }
      });
    });
    setSubscription(cloneDeep(subscription));
  }

  const handleOfferDetailsClick = (oid) => {
    if (offerDetails.indexOf(oid) === -1) {
      offerDetails.push(oid);
    } else {
      offerDetails.splice(offerDetails.indexOf(oid), 1);
    }
    setOfferDetails(offerDetails.slice());
  };

  const handleBundleDetailsClick = (bid) => {
    if (bundleDetails.indexOf(bid) === -1) {
      bundleDetails.push(bid);
    } else {
      bundleDetails.splice(bundleDetails.indexOf(bid), 1);
    }
    setBundleDetails(bundleDetails.slice());
  };

  const handleBundleSelectAllClick = (oid) => {
    if (bundleSelectAllMap.indexOf(oid) === -1) {
      bundleSelectAllMap.push(oid);
      offerMap.get(oid).offerBundles.forEach(b => {
        if (subscription['userBundles'].indexOf(b.bundleId) === -1) {
          subscription['userBundles'].push(b.bundleId);
        }
      });
    } else {
      bundleSelectAllMap.splice(bundleSelectAllMap.indexOf(oid), 1);
      offerMap.get(oid).offerBundles.forEach((b) => {
        if (subscription['userBundles'].indexOf(b.bundleId) !== -1) {
          subscription['userBundles'].splice(subscription['userBundles'].indexOf(b.bundleId), 1);
        }
      });
    }

    subscription['userApps'].length = 0;
    subscription['userCaps'] = {};
    subscription['userBundles'].forEach((bId) => {
      bundleMap.get(bId).bundleCapabilities.forEach((c) => {
        if (subscription['userApps'].indexOf(c.applicationId) === -1) {
          subscription['userApps'].push(c.applicationId);
          subscription['userCaps'][c.applicationId] = [c.capabilityId];
        } else {
          if (subscription['userCaps'][c.applicationId].indexOf(c.capabilityId) === -1) {
            subscription['userCaps'][c.applicationId].push(c.capabilityId);
          }
        }
      });
    });
    setSubscription(cloneDeep(subscription));
    setBundleSelectAllMap(bundleSelectAllMap.slice());
  };

  const handleCommercialStatusFilter = (value) => {
    setCommercialStatus(value);
    setSubscription({
      ...subscription,
      'userOffers': subscription['userOffers'] = subscription['userOffers'].filter(o => isVisibleForCommercialStatus(offerMap.get(o).offerCommercialStatus)),
      'userBundles': subscription['userBundles'] = subscription['userBundles'].filter(b => isVisibleForCommercialStatus(bundleMap.get(b).bundleCommercialStatus)),
      'userApps': subscription['userApps'] = subscription['userApps'].filter(a => isVisibleForCommercialStatus(pcApplicationMap.get(a).applicationCommercialStatus))
    });
  };

  const handleTechnicalStatusFilter = (value) => {
    setTechnicalStatus(value);
    setSubscription({
      ...subscription,
      'userOffers': subscription['userOffers'] = subscription['userOffers'].filter(o => isVisibleForTechnicalStatus(offerMap.get(o).offerTechnicalStatus)),
      'userBundles': subscription['userBundles'] = subscription['userBundles'].filter(b => isVisibleForTechnicalStatus(bundleMap.get(b).bundleTechnicalStatus)),
      'userApps': subscription['userApps'] = subscription['userApps'].filter(a => isVisibleForTechnicalStatus(pcApplicationMap.get(a).applicationTechnicalStatus))
    });
  };

  const handleCountryFilter = (value) => {
    setFilterCountry(value);
    setSpinner({ ...spinner, status: true, message: 'Updating product catalog...' });
    fetchDataForCountry(value === 'default' ? null : value);
  };

  const isVisibleForCommercialStatus = (status) => {
    return !(commercialStatus !== 'default' && status && status.toUpperCase() !== commercialStatus.toUpperCase());
  };

  const isVisibleForTechnicalStatus = (status) => {
    return !(technicalStatus !== 'default' && status && status.toUpperCase() !== technicalStatus.toUpperCase());
  };

  const handleSelectAllOffers = () => {
    subscription['userOffers'] = Array.from(offerMap.keys());
    subscription['userBundles'] = Array.from(bundleMap.keys());
    subscription['userApps'].length = 0;
    subscription['userCaps'] = {};
    subscription['userBundles'].forEach(bid => {
      bundleMap.get(bid).bundleCapabilities.forEach(c => {
        if (subscription['userApps'].indexOf(c.applicationId) === -1) {
          subscription['userApps'].push(c.applicationId);
          subscription['userCaps'][c.applicationId] = [c.capabilityId];
        } else {
          if (subscription['userCaps'][c.applicationId].indexOf(c.capabilityId) === -1) {
            subscription['userCaps'][c.applicationId].push(c.capabilityId);
          }
        }
      });
    });
    setBundleSelectAllMap(Array.from(offerMap.keys()));
    setSubscription(cloneDeep(subscription));
  };

  const handleResetOffers = () => {
    subscription['userOffers'].length = 0
    subscription['userBundles'].length = 0;
    subscription['userApps'].length = 0;
    subscription['userCaps'] = {};

    bundleDetails.length = 0;
    bundleSelectAllMap.length = 0;
    offerDetails.length = 0;

    setBundleDetails(bundleDetails.slice());
    setBundleSelectAllMap(bundleSelectAllMap.slice());
    setOfferDetails(offerDetails.slice());

    setSubscription(cloneDeep(subscription));
  };

  const handleResetFilter = () => {
    handleCommercialStatusFilter('default');
    handleTechnicalStatusFilter('default');
    handleCountryFilter('default');
    handleResetOffers();
    setHideDeprecatedOffers(true);
  };

  //#endregion

  //#region load catalog information 

  const loadOffers = (countryCode = null) => {
    return new Promise(async (resolve, reject) => {
      let cachedOffers = Utils.fetchFromSessionStorage(user.username, countryCode ? countryCode + '.offers' : 'offers', [], true);
      if (cachedOffers.length > 0) {
        return resolve(cachedOffers);
      }
      let params = {
        response: false,
        queryStringParameters: {
          'stage': curStage,
          'path': 'offers',
          'countryCode': countryCode,
          'region': user.pool.userPoolId.split('_')[0]
        }
      };
      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const rOffers = JSON.parse(JSON.parse(response).body);
        if (rOffers && rOffers.length > 0) {
          Utils.setInSessionStorage(user.username, countryCode ? countryCode + '.offers' : 'offers', rOffers, true);
          return resolve(rOffers);
        }
      }).catch((error) => {
        console.error(JSON.stringify(error));
        reject([]);
      });
    });
  };

  const loadBundles = (countryCode = null) => {
    return new Promise(async (resolve, reject) => {
      let cachedBundles = Utils.fetchFromSessionStorage(user.username, countryCode ? countryCode + '.bundles' : 'bundles', [], true);
      if (cachedBundles.length > 0) {
        return resolve(cachedBundles);
      }

      let params = {
        response: false,
        queryStringParameters: {
          'stage': curStage,
          'path': 'bundles',
          'countryCode': countryCode,
          'region': user.pool.userPoolId.split('_')[0]
        }
      };

      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const rBundles = JSON.parse(JSON.parse(response).body);
        if (rBundles && rBundles.length > 0) {
          Utils.setInSessionStorage(user.username, countryCode ? countryCode + '.bundles' : 'bundles', rBundles, true);
          return resolve(rBundles);
        }
      }).catch((error) => {
        console.error(JSON.stringify(error));
        return reject([]);
      });
    });
  };

  const loadApps = (countryCode = null) => {
    return new Promise(async (resolve, reject) => {
      let cachedApps = Utils.fetchFromSessionStorage(user.username, countryCode ? countryCode + '.apps' : 'apps', [], true);
      if (cachedApps.length > 0) {
        return resolve(cachedApps);
      }
      let params = {
        response: false,
        queryStringParameters: {
          'stage': curStage,
          'path': 'applications',
          'countryCode': countryCode,
          'region': user.pool.userPoolId.split('_')[0]
        }
      };
      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const rApps = JSON.parse(JSON.parse(response).body);
        if (rApps && rApps.length > 0) {
          Utils.setInSessionStorage(user.username, countryCode ? countryCode + '.apps' : 'apps', rApps, true);
          return resolve(rApps);
        }
      }).catch((error) => {
        console.error(JSON.stringify(error));
        reject([]);
      });
    });
  };

  const loadCountryCodes = () => {
    return new Promise(async (resolve, reject) => {
      let cachedApps = Utils.fetchFromLocalStorage(user.username, 'countrycodes', [], true);
      if (cachedApps.length > 0) {
        return resolve(cachedApps);
      }
      let params = {
        response: false,
        queryStringParameters: {
          'stage': curStage,
          'path': 'countrycodes',
          'region': user.pool.userPoolId.split('_')[0]
        }
      };
      await API.get("SspBackendApi", "/catalog", params).then((response) => {
        const countryCodes = JSON.parse(JSON.parse(response).body);
        if (countryCodes && countryCodes.length > 0) {
          Utils.setInLocalStorage(user.username, 'countrycodes', countryCodes, true);
          return resolve(countryCodes);
        }
      }).catch((error) => {
        console.error(JSON.stringify(error));
        reject([]);
      });
    });
  };

  const fetchDataForCountry = async (countryCode) => {
    const dataPromises = [
      loadOffers(countryCode),
      loadBundles(countryCode),
      loadApps(countryCode)
    ];
    Promise.all(dataPromises).then(res => {
      if (res[0].length > 0 && res[1].length > 0 && res[2].length > 0) {
        setSpinner({ ...spinner, status: false, message: '' });
        originalOffers.current = res[0];
        setData({ ...data, offers: hideDeprecatedOffers === true ? res[0].filter(o => DEPRECATED_OFFERS.indexOf(o.offerSystemType) === -1) : res[0], bundles: res[1], apps: res[2] });
      }
    }).catch(error => {
      console.error('An error occurred while fetching initial data: ', error);
    });
  };

  const loadInitData = async () => {
    setSpinner({ ...spinner, status: true, message: 'Fetching product catalog...' });
    const initDataPromises = [
      loadOffers(),
      loadBundles(),
      loadApps(),
      loadCountryCodes()
    ];
    Promise.all(initDataPromises).then(res => {
      if (res[0].length > 0 && res[1].length > 0 && res[2].length > 0 && res[3].length > 0) {
        setSpinner({ ...spinner, status: false, message: '' });
        originalOffers.current = res[0];
        setData({ ...data, offers: hideDeprecatedOffers === true ? res[0].filter(o => DEPRECATED_OFFERS.indexOf(o.offerSystemType) === -1) : res[0], bundles: res[1], apps: res[2], countrycodes: res[3] });
      }
    }).catch(error => {
      console.error('An error occurred while fetching initial data: ', error);
    });

  };

  useEffect(() => {
    const roleAttribute = user['attributes']['custom:role'];
    let roleValue = roleAttribute ? JSON.parse(roleAttribute) : RBACUtils.getDefaultUserRole();
    Utils.setInSessionStorage(user.username, 'userRole', roleValue);
    if (Object.keys(data).filter(k => data[k].length === 0).length > 0) {
      loadInitData();
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let apps = data['apps'].map(a => a.applicationName);
    let offers = data['offers'].map(o => o.offerId);
    let bundles = data['bundles'].map(b => b.bundleId);

    let fo = subscription['userOffers'].filter(oid => offers.indexOf(oid) !== -1);
    let fb = subscription['userBundles'].filter(bid => bundles.indexOf(bid) !== -1);
    let fa = subscription['userApps'].filter(aid => applicationMap.get(aid) && apps.indexOf(applicationMap.get(aid).applicationName) !== -1);
    Object.keys(subscription['userCaps']).map(Number).filter(aid => fa.indexOf(aid) === -1).forEach(aid => delete subscription['userCaps'][aid]);

    setSubscription(cloneDeep({
      ...subscription,
      'userOffers': fo,
      'userBundles': fb,
      'userApps': fa,
      'userCaps': subscription['userCaps'],
    }));

  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setData(
      cloneDeep(
        { ...data, offers: hideDeprecatedOffers === true ? originalOffers.current.filter(o => DEPRECATED_OFFERS.indexOf(o.offerSystemType) === -1) : originalOffers.current }
      )
    );
  }, [hideDeprecatedOffers]);

  //#endregion

  return (

    <Container fluid className="p-2 mb-4">
      <Row className='m-0'>

        {/* Left sidebar */}
        <Col md={2} className='l-nav'>
          <LeftSideBar active='pc' username={user.username} />
        </Col>

        {/* Content */}
        <Col md={8}>
          <Container fluid data-bs-spy="scroll" data-bs-target='#navbar' data-bs-offset="0" tabIndex="0">
            <Row className='d-row'>

              {/* top heading bar */}
              <Row className='g-0 m-0 p-0'>
                <Col className='title-wrap d-flex align-items-center justify-content-between'>
                  <div className='d-flex align-items-center justify-content-start title-text'>
                    <div>Product Catalog</div>
                  </div>
                  <div className='d-flex align-items-center justify-content-end title-settings'>
                    <div className='me-2'>{user.attributes.email}</div>
                    <Dropdown size='sm' as={ButtonGroup} className='me-2'>
                      <Button variant="outline-secondary" title={`Environment: ${curEnv}, Stage: ${curStage}`}>{EnvDetails.filter(e => e.envName === curEnv)[0].dispName}</Button>
                      <Dropdown.Toggle split variant="outline-secondary" id="dropdown-split-basic" />
                      <Dropdown.Menu variant='outline-secondary' align='end'>
                        {
                          EnvDetails.map(e =>
                            Utils.includeEnvInDropdown(e.envName) &&
                            <Dropdown.Item title={`Environment: ${e.envName}, Stage: ${e.stageName}`} href={e.url}>{e.dispName}</Dropdown.Item>
                          )
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                    <Button size="sm" variant="outline-secondary" onClick={signOut}>Signout</Button>
                  </div>
                </Col>
              </Row>

              <Modal show={spinner['status']} size="lg" backdrop="static" keyboard={false} aria-labelledby="loading-modal" centered className='loading-modal'>
                <Modal.Body>
                  <div className='d-flex flex-column align-items-center justify-content-center'>
                    <div className="spinner-border spinner-border-md" role="status"></div>
                    <p className='mb-0 mt-1'>{spinner['message']}</p>
                  </div>
                </Modal.Body>
              </Modal>

              {/* user options */}
              <Row className="g-0 px-0 pt-0 pb-0 uo-area pc">
                <Col>
                  <div>
                    <h6 className='mb-2'>Options <small className='ms-1' style={{ color: '#00f', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setShowOptions(!showOptions)} >{showOptions ? 'Hide' : 'Show'}</small></h6>
                  </div>
                  <Collapse in={showOptions}>
                    <div>
                      <div className='d-flex align-items-center justify-content-start bg-light py-2 px-2 w-auto rounded'>
                        <div className='w-auto me-3'>
                          <div className='mb-0'><small>Country</small></div>
                          <Form.Select value={filterCountry} size="sm" title='Filter by country' aria-label="Filter by country" onChange={(e) => handleCountryFilter(e.target.value)}>
                            <option value='default'>All countries</option>
                            {
                              data['countrycodes'].map(c =>
                                <option data-tokens={`${c.countryCodeName} (${c.countryCode})`} value={c.countryCode}>{`${c.countryCodeName} (${c.countryCode})`}</option>
                              )
                            }
                          </Form.Select>
                        </div>
                        <div className='w-auto me-3'>
                          <div className='mb-0'><small>Commercial status</small></div>
                          <Form.Select value={commercialStatus} title='Filter by commercial status' size="sm" aria-label="Filter by commercial status" onChange={(e) => handleCommercialStatusFilter(e.target.value)} data-live-search="true">
                            <option value='default'>All statuses</option>
                            {
                              availableCommercialStatuses.map(s =>
                                <option value={s}>{s}</option>
                              )
                            }
                          </Form.Select>
                        </div>
                        <div className='w-auto me-3' title="Filter by technical status">
                          <div className='mb-0'><small>Technical status</small></div>
                          <Form.Select value={technicalStatus} title='Filter by technical status' size="sm" aria-label="Filter by technical status" onChange={(e) => handleTechnicalStatusFilter(e.target.value)} data-live-search="true">
                            <option value='default'>All statuses</option>
                            {
                              availableTechnicalStatuses.map(s =>
                                <option value={s}>{s}</option>
                              )
                            }
                          </Form.Select>
                        </div>
                        <div className='w-auto me-3 d-flex flex-column align-items-baseline justify-content-start' title='Select everything'>
                          <div><small>Select all</small></div>
                          <div className='sel-all' onClick={() => handleSelectAllOffers()}><MdSelectAll /></div>
                        </div>
                        <div className='w-auto me-3 d-flex flex-column align-items-baseline justify-content-start' title='Reset all selections'>
                          <div><small>Unselect all</small></div>
                          <div className='reset' onClick={() => handleResetOffers()}><MdDeselect /></div>
                        </div>
                        <div className='w-auto me-4' title='Hide deprecated offers'>
                          <Form.Group controlId="hideDeprecatedOffers" className='d-flex flex-column align-align-items-baseline justify-content-start'>
                            <Form.Label className='mb-0'><small>Hide deprecated</small></Form.Label>
                            <Form.Check type="checkbox" onChange={(e) => { setHideDeprecatedOffers(e.target.checked) }} checked={hideDeprecatedOffers} label='' className="ms-1 internal-app-checkbox hide-deprecated" name='hide-deprecated-offers' id='hide-deprecated-offers' />
                          </Form.Group>
                        </div>
                        <div className='w-auto me-3'>
                          <div className='mb-0'><small>&nbsp;</small></div>
                          <Button size='sm' variant='secondary' onClick={() => handleResetFilter()} title='Reset options'>Reset</Button>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </Col>
              </Row>

              {/* Content */}
              <Row className='g-0 m-0 p-0'>
                <Col className='border-0 rounded px-0 py-0 mx-1' style={{ backgroundColor: '#fff' }}>
                  <h4 className='text-center section-title' style={{ backgroundColor: '#c4e4ff', color: '#333', fontWeight: 'normal' }}>Offers</h4>
                  {/* {
                    data['offers'].length === 0 && data['bundles'].length === 0 &&
                    <div className='d-flex align-items-center justify-content-center' style={{ backgroundColor: '#f6fbff', height: 800 }}>
                      <div className="spinner-border spinner-border-lg" style={{ color: '#abd8ff' }} role="status"></div>
                      <p className='p-0' style={{ marginTop: '80px', marginLeft: '-70px', color: '#89adcc' }}>Loading Offers...</p>
                    </div>
                  } */}
                  <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />} style={{ height: 800 }}>
                    <ListGroup as="ul" className='list-group-light offer-block'>
                      {
                        data['offers'].map(o =>
                          o.offerBundles.length > 0 && isVisibleForCommercialStatus(o.offerCommercialStatus) && isVisibleForTechnicalStatus(o.offerTechnicalStatus) &&
                          <ListGroupItem as="li" action key={"offer-" + o.offerId}>
                            <input className="form-check-input offer-check" style={{ cursor: "pointer" }} type="checkbox" checked={subscription['userOffers'].indexOf(o.offerId) === -1 ? false : true} id={"offer-" + o.offerId} onChange={() => handleOfferSelect(o.offerId)} />
                            <label className="form-check-label text-truncate" style={{ cursor: "pointer", padding: "0px 0px 0px 8px", maxWidth: "85%" }} htmlFor={"offer-" + o.offerId} title={o.offerCommercialName}>{o.offerCommercialName}</label>
                            <div className="float-end" style={{ cursor: "pointer", color: '#9dceff', fontSize: '22px', marginTop: '-4px' }} onClick={() => handleOfferDetailsClick(o.offerId)} aria-controls={o.offerId} aria-expanded={offerDetails.indexOf(o.offerId) !== -1}>
                              {offerDetails.indexOf(o.offerId) !== -1 ? <BsFillPatchMinusFill /> : <BsFillPatchPlusFill />}
                            </div>
                            <Collapse in={offerDetails.indexOf(o.offerId) !== -1}>
                              <div id={o.offerId}>
                                <div className="px-3 py-2 mt-2 text-justify" style={{ backgroundColor: "rgba(255, 255, 255, 1)", borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>{o.offerDescription}</div>
                                <p className='mb-0' style={{ backgroundColor: '#fff', padding: '8px 12px 0px', borderRadius: '0px', borderTop: '1px solid #e4f2ff' }}>System Type: <span style={{ fontWeight: '500' }}>{o.offerSystemType}</span></p>
                                <div
                                  className='pb-1 pe-3 text-end link-secondary'
                                  style={{ backgroundColor: '#fff', borderRadius: '5px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px', cursor: 'pointer', fontSize: '13px' }}
                                  onClick={() => setModal({ show: true, type: 'offer', id: o.offerId })}>
                                  details&raquo;
                                </div>
                              </div>
                            </Collapse>
                          </ListGroupItem>)
                      }
                    </ListGroup>
                  </Scrollbars>
                </Col>
                <Col className='border-0 rounded px-0 py-0 mx-1' style={{ backgroundColor: "#fff" }}>
                  <h4 className='text-center section-title' style={{ backgroundColor: '#e2bff1' }}>Bundles</h4>
                  <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />} style={{ height: 800 }}>
                    {
                      subscription['userOffers'].map(o =>
                        offerMap.get(o) && isVisibleForCommercialStatus(offerMap.get(o).offerCommercialStatus) && isVisibleForTechnicalStatus(offerMap.get(o).offerTechnicalStatus) &&
                        <Tabs id={o} className="bundle-tabs">
                          <Tab eventKey={o} key={o} title={offerMap.get(o).offerCommercialName}>
                            <OverlayTrigger placement="top" trigger={['hover', 'focus']} delay={50} overlay={<Tooltip className='bs-tooltip' id={'offer-bundle-tooltip-' + o}>{bundleSelectAllMap.indexOf(o) === -1 ? "Select all bundles" : "Unselect all bundles"}</Tooltip>}>
                              <span className='float-end bundle-s-all' onClick={() => handleBundleSelectAllClick(o)}>
                                {bundleSelectAllMap.indexOf(o) === -1 ? <MdOutlineCheckBox /> : <MdOutlineIndeterminateCheckBox />}
                              </span>
                            </OverlayTrigger>
                            <ListGroup as="ul" className='list-group-light bundle-block'>
                              {
                                offerMap.get(o).offerBundles.map(b =>
                                  isVisibleForCommercialStatus(offerMap.get(o).offerCommercialStatus) &&
                                  isVisibleForTechnicalStatus(offerMap.get(o).offerTechnicalStatus) &&
                                  bundleMap.get(b.bundleId) &&
                                  isVisibleForCommercialStatus(bundleMap.get(b.bundleId).bundleCommercialStatus) &&
                                  isVisibleForTechnicalStatus(bundleMap.get(b.bundleId).bundleTechnicalStatus) &&
                                  <ListGroupItem as="li" action key={"bundle-" + b.bundleId}>
                                    <input className="form-check-input bundle-check" checked={subscription['userBundles'].indexOf(b.bundleId) === -1 ? false : true} style={{ cursor: "pointer" }} type="checkbox" id={"bundle-" + b.bundleId} onChange={() => handleBundleSelect(b.bundleId)} />&nbsp;&nbsp;
                                    <label className="form-check-label text-truncate" style={{ maxWidth: '85%', cursor: 'pointer' }} htmlFor={"bundle-" + b.bundleId} title={bundleMap.get(b.bundleId).bundleCommercialName}>{bundleMap.get(b.bundleId).bundleCommercialName}</label>
                                    <div className="float-end" style={{ cursor: "pointer", color: '#d9aaec', fontSize: '22px', marginTop: '-4px' }} onClick={() => handleBundleDetailsClick(b.bundleId)} aria-controls={b.bundleId} aria-expanded={bundleDetails.indexOf(b.bundleId) !== -1}>
                                      {bundleDetails.indexOf(b.bundleId) !== -1 ? <BsFillPatchMinusFill /> : <BsFillPatchPlusFill />}
                                    </div>
                                    <Collapse in={bundleDetails.indexOf(b.bundleId) !== -1}>
                                      <div id={b.bundleId}>
                                        <div className="px-3 py-2 mt-2 text-justify" style={{ backgroundColor: "#fff", borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>{bundleMap.get(b.bundleId).bundleDescription}</div>
                                        <p className='mb-0' style={{ backgroundColor: '#fff', padding: '8px 12px', borderRadius: '0px', borderTop: '1px solid #f6ecfb' }}>Service Type: <span style={{ fontWeight: '500' }}>{bundleMap.get(b.bundleId).bundleServiceType}</span></p>
                                        <p className='mb-0' style={{ backgroundColor: '#fff', padding: '8px 12px', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', borderTop: '1px solid #f6ecfb' }}>Material Code: <span style={{ fontWeight: '500' }}>{bundleMap.get(b.bundleId).bundleSmartCode}</span></p>
                                        <div
                                          className='pb-1 pe-3 text-end link-secondary'
                                          style={{ backgroundColor: '#fff', borderRadius: '5px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px', cursor: 'pointer', fontSize: '13px' }}
                                          onClick={() => setModal({ show: true, type: 'bundle', id: b.bundleId })}>
                                          details&raquo;
                                        </div>
                                      </div>
                                    </Collapse>
                                  </ListGroupItem>)
                              }
                            </ListGroup>
                          </Tab>
                        </Tabs>)
                    }
                  </Scrollbars>
                </Col>
                <Col className='border-0 rounded px-0 py-0 mx-1 cap-block' style={{ backgroundColor: "#fff" }}>
                  <h4 className='text-center section-title cap-title'>Capabilities</h4>
                  <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />} style={{ height: 800 }}>
                    {
                      subscription['userApps'].map(a =>
                        applicationMap.get(a) && pcApplicationMap.get(a) &&
                        isVisibleForCommercialStatus(pcApplicationMap.get(a).applicationCommercialStatus) &&
                        isVisibleForTechnicalStatus(pcApplicationMap.get(a).applicationTechnicalStatus) &&
                        <Tabs id={a} className="app-tabs">
                          <Tab className='app-tab' eventKey={a} key={a} title={applicationMap.get(a).applicationCommercialName}>
                            <div>
                              <div className="chip-container nav justify-content-center">
                                {
                                  subscription['userCaps']?.[a].map(c =>
                                    <OverlayTrigger trigger={['hover', 'focus']} delay={250} placement="auto" rootClose={true} overlay={
                                      <Popover id={'cap-popover-' + c} className='cap-popover'>
                                        <Popover.Header as="h3" className='text-truncate'>{capabilityMap.get(c) ? capabilityMap.get(c).capabilityCommercialName : capabilityMap.get(c).capabilityName}</Popover.Header>
                                        <Popover.Body>{capabilityMap.get(c) && capabilityMap.get(c).capabilityDescription ? capabilityMap.get(c).capabilityDescription : 'NA'}</Popover.Body>
                                      </Popover>
                                    }>
                                      <div className="basic-chip background-green" >{capabilityMap.get(c) ? capabilityMap.get(c).capabilityCommercialName : capabilityMap.get(c).capabilityName}</div>
                                    </OverlayTrigger>
                                  )
                                }
                              </div>
                              <div
                                className='text-end link-secondary chip-container'
                                style={{ cursor: 'pointer', fontSize: '13px', padding: '0px 10px 10px 0px' }}
                                onClick={() => setModal({ show: true, type: 'app', id: a })}>
                                application details&raquo;
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      )
                    }
                  </Scrollbars>
                </Col>
              </Row>

            </Row>
          </Container>
        </Col>

        <Modal size='lg' show={modal['show']} onHide={() => setModal({ show: false, type: '', id: '' })} keyboard={true} backdrop className={modal['type']}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className='ps-3'>
                {
                  modal['type'] === 'offer' ? offerMap.get(modal['id']).offerCommercialName :
                    modal['type'] === 'bundle' ? bundleMap.get(modal['id']).bundleCommercialName :
                      modal['type'] === 'app' ? pcApplicationMap.get(modal['id']).applicationCommercialName :
                        ''
                }
              </div>
              <div className='text-muted ps-3 pt-1' style={{ fontSize: '14px', fontWeight: 'normal' }}>Additional information for the selected {modal['type'] === 'app' ? 'Application' : Utils.displayTitle(modal['type'])}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />} autoHeight autoHeightMin={100} autoHeightMax={650} >
              <Container fluid className='p-0'>
                {
                  modal['type'] === 'offer' ?
                    Object.keys(offerMap.get(modal['id'])).map(k =>
                      <Row className='mx-2 py-1 d-flex align-items-center justify-content-start' style={{ borderBottom: '1px dashed #f1f1f1' }}>
                        <Col md={3} className='px-1 py-1 fw-bold text-black-50'>{Utils.displayTitle(k)}</Col>
                        <Col md={9} className='px-1 py-1'>
                          <div className="text-break">
                            {
                              k === 'offerBundles' ?
                                offerMap.get(modal['id'])[k].map(b =>
                                  <div className="" ><small>{b.bundleName} <span className='ms-1'><small>[{b.bundleServiceType}]</small></span></small></div>
                                )
                                :
                                Utils.displayValue(offerMap.get(modal['id'])[k])
                            }
                          </div>
                        </Col>
                      </Row>
                    )
                    : modal['type'] === 'bundle' ?
                      Object.keys(bundleMap.get(modal['id']))
                        .filter(k => k !== 'bundleCapabilities')
                        .map(k =>
                          <Row className='mx-2 py-1 d-flex align-items-center justify-content-start' style={{ borderBottom: '1px dashed #f1f1f1' }}>
                            <Col md={4} className='px-1 py-1 fw-bold text-black-50'>{Utils.displayTitle(k)}</Col>
                            <Col md={8} className='px-1 py-1'>
                              <div className="text-break">
                                {
                                  ['bundleProperties', 'bundleOrderDetails', 'bundleCountryAvailability'].indexOf(k) !== -1 ?
                                    <ReactJson src={bundleMap.get(modal['id'])[k]} name={null} iconStyle="triangle" indentWidth={2} collapsed={k === 'bundleCountryAvailability' ? true : false} enableClipboard={false} displayObjectSize={false} displayDataTypes={false} quotesOnKeys={false} groupArraysAfterLength={1000} displayArrayKey={false} />
                                    :
                                    Utils.displayValue(bundleMap.get(modal['id'])[k])
                                }
                              </div>
                            </Col>
                          </Row>
                        )
                      : modal['type'] === 'app' ?
                        Object.keys(pcApplicationMap.get(modal['id']))
                          .map(k =>
                            <Row className='mx-2 py-1 d-flex align-items-center justify-content-start' style={{ borderBottom: '1px dashed #f1f1f1' }}>
                              <Col md={5} className='px-1 py-1 fw-bold text-black-50'>{Utils.displayTitle(k)}</Col>
                              <Col md={7} className='px-1 py-1 text-break'>{Utils.displayValue(pcApplicationMap.get(modal['id'])[k])}</Col>
                            </Row>
                          ) : ''
                }
              </Container>
            </Scrollbars>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" size='sm' onClick={() => setModal({ show: false, type: '', id: '' })}>Close</Button>
          </Modal.Footer>
        </Modal>

      </Row>
    </Container>
  );

};

export default withAuthenticator(ProductCatalog, withAuthenticatorOptions);