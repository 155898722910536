import React from 'react';
import { MdKeyboardArrowLeft,  MdKeyboardDoubleArrowRight} from 'react-icons/md'; 
import { useNavigate } from 'react-router-dom';

const NavigateButton = ({ id, navigateTo }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    let path;
    if (navigateTo === 'subscription') {
      path = `/sub/list?id=${id}`;
    } else if (navigateTo === 'orders') {
      path = `/order/list?sub_id=${id}`;
    }

    navigate(path);
  };

  const Icon = navigateTo === 'subscription' ? MdKeyboardArrowLeft : MdKeyboardDoubleArrowRight;

  return (
    <button
      onClick={handleClick}
      className="icon-button"
      title={`View ${navigateTo === 'subscription' ? 'Subscription' : 'Order'}`}
      style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
    >
      <Icon />
    </button>
  );
};

export default NavigateButton;